import React from 'react';

export default class HubspotForm extends React.Component {
  componentDidMount = async () => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          portalId: '3001955',
          formId: '86741b9b-6596-461e-a73f-ab7d6d40ddb3',
          target: '#hubspotForm',
        });
      }
    });
  };

  render() {
    return (
      <div>
        <div id="hubspotForm" style={{ display: 'inline-flex', minHeight: '400px' }}></div>
      </div>
    );
  }
}
