import dotenv from "dotenv";
import keys from "../keys/keys";
dotenv.config();

const APP_ENV = process.env.APP_ENV || "local";

const getPrivateKey = () => keys[APP_ENV]["private"];
const getPublicKey = () => keys[APP_ENV]["public"];
const getKeyId = () => keys[APP_ENV]["key"];
const getJSEncryptPrivateKey = () => keys["jsencrypt"]["private"];
const getJSEncryptPublicKey = () => keys["jsencrypt"]["public"];

export {
  getKeyId,
  getPrivateKey,
  getPublicKey,
  getJSEncryptPrivateKey,
  getJSEncryptPublicKey,
};
