import React from 'react';
import { TextLink, Menu } from '@contentful/f36-components';
import { MoreHorizontalIcon, DownloadIcon } from '@contentful/f36-icons';
import { STATUS_READY_PUBLISH, STATUS_READY_REVIEW } from '../../../config/translationModel';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import { downloadEntry, splitEntryId } from '../../../utils/helpers';
import { EntryContentStore } from '../../../../../lambda/src/utils/data-manager/data.manager';

interface Props {
  moreButtonPressedOn: string;
  entryId: string;
  requestChanges: Function;
  updateEntry: Function;
  publishEntry: Function;
  entryContentStore: EntryContentStore;
  updateMoreButton: Function;
  status: string;
  sdk: EditorExtensionSDK;
  entrySlug: string;
}

export default class MoreButton extends React.Component<Props> {
  canShowRequest = () => {
    return this.props.status == STATUS_READY_REVIEW || this.props.status == STATUS_READY_PUBLISH;
  };
  canShowPublish = () => {
    return this.props.status == STATUS_READY_REVIEW || this.props.status == STATUS_READY_PUBLISH;
  };
  canShowApprove = () => {
    return this.props.status == STATUS_READY_REVIEW;
  };

  render() {
    return (
      <>
        {
          <Menu
            isOpen={this.props.entryId == this.props.moreButtonPressedOn}
            onClose={() => {
              this.props.updateMoreButton();
            }}
          >
            <Menu.Trigger>
              <TextLink
                onClick={() => {
                  this.props.updateMoreButton(
                    this.props.entryId != this.props.moreButtonPressedOn ? this.props.entryId : '',
                  );
                }}
              >
                <MoreHorizontalIcon variant={'muted'}></MoreHorizontalIcon>
              </TextLink>
            </Menu.Trigger>
            <Menu.List>
              <Menu.Item
                className={'download-button'}
                onClick={async () => {
                  const [contentfulId, targetLanguage] = splitEntryId(this.props.entryId);
                  const fileData = this.props.entryContentStore.fileData || [];
                  const fileEntry = fileData.find((storedEntry: any) => {
                    return (
                      storedEntry.entryId == contentfulId && storedEntry.target == targetLanguage
                    );
                  });
                  await downloadEntry(
                    fileEntry,
                    this.props.entrySlug,
                    this.props.sdk,
                    this.props.entryContentStore,
                  );
                  this.props.updateMoreButton();
                }}
              >
                <DownloadIcon variant={'muted'} />
                Download source file
              </Menu.Item>
              {this.canShowRequest() && (
                <Menu.Item
                  onClick={() => {
                    this.props.requestChanges(this.props.entryId);
                  }}
                >
                  Request changes
                </Menu.Item>
              )}
              {this.canShowApprove() && (
                <Menu.Item
                  onClick={() => {
                    this.props.updateEntry([this.props.entryId]);
                    this.props.updateMoreButton();
                  }}
                >
                  Approve changes
                </Menu.Item>
              )}
              {this.canShowPublish() && (
                <Menu.Item
                  onClick={() => {
                    this.props.publishEntry([this.props.entryId]);
                    this.props.updateMoreButton();
                  }}
                >
                  Publish changes
                </Menu.Item>
              )}
            </Menu.List>
          </Menu>
        }
      </>
    );
  }
}
