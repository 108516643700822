import React from 'react';
import { EditorExtensionSDK } from '../extensions-sdk';
import TranslationEditor from '../components/translation/TranslationEditor';
import TranslatorEditor from '../components/translator/TranslatorEditor';

interface Props {
  sdk: EditorExtensionSDK;
}

interface State {
  isTranslationContentEntry: boolean | null;
}

export default class Sidebar extends React.Component<Props, State> {
  state: State = {
    isTranslationContentEntry: null,
  };

  async componentDidMount() {
    await this.handleMount();
  }

  handleMount = async () => {
    const isTranslationContentEntry =
      this.props.sdk.entry.getSys().contentType.sys.id ===
      //@ts-ignore
      this.props.sdk.parameters.installation.translationsModelId;
    this.setState({
      isTranslationContentEntry,
    });
  };

  render() {
    const isTranslationContentEntry = this.state.isTranslationContentEntry;
    return (
      <>
        {isTranslationContentEntry && <TranslationEditor sdk={this.props.sdk} />}
        {isTranslationContentEntry === false && <TranslatorEditor sdk={this.props.sdk} />}
      </>
    );
  }
}
