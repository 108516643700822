import React from 'react';
import { EditorExtensionSDK } from '../../extensions-sdk';
import Tabs from './parts/Tabs'; // Using custom tabs as above one is not working
import { PageIcon, SettingsIcon } from '@contentful/f36-icons';
import Details from './Details';
import Editor from './Editor';
import Notice from './parts/Notice';

interface Props {
  sdk: EditorExtensionSDK;
}

interface State {
  detailsView: boolean | null;
  trackChangeEnabled: boolean;
  missingEntries: object;
}

export default class TranslationEditor extends React.Component<Props, State> {
  state: State = {
    detailsView: null,
    trackChangeEnabled: false,
    missingEntries: {},
  };

  constructor(props: Readonly<Props>) {
    super(props);
	}

  componentDidMount() {
    this.props.sdk.entry.fields['translationInfo'].onValueChanged(this.handleTranslationInfoChange);
    this.handleTranslationInfoChange(this.props.sdk.entry.fields['translationInfo'].getValue());
  }

  handleTranslationInfoChange = async (translationInfo: any = null) => {
    let detailsView = false;
    if (translationInfo) {
      detailsView = translationInfo.showDetailsTab === true;
    }
    this.setState({
      detailsView,
    });
  };

  showNotice = (data: any) => {
    this.setState({
      missingEntries: data ?? {},
    });
  };

  render() {
    if (this.state.detailsView === null) {
      return '';
    }
    const hasFileData = (this.props.sdk.entry.fields['translationInfo'].getValue() || {})[
      'hasFileData'
    ];

    const tabs = [
      {
        id: 'translation-editor',
        label: 'Project Settings',
        isDisabled: false,
        icon: <SettingsIcon variant="muted" />,
        class: 'no-padding',
        content: (
          <Editor
            sdk={this.props.sdk}
            trackChangeEnabled={this.state.trackChangeEnabled}
            showNotice={this.showNotice}
          />
        ),
      },
      {
        id: 'details',
        label: 'Project details',
        isDisabled: !hasFileData,
        icon: <PageIcon variant="muted" />,
        class: 'no-padding',
        content: <Details sdk={this.props.sdk} />,
      },
    ];
    return (
      <div>
        <Notice sdk={this.props.sdk} missingEntries={this.state.missingEntries}></Notice>
        <Tabs activeTab={hasFileData ? 'details' : 'translation-editor'} tabs={tabs}></Tabs>
      </div>
    );
  }
}
