import React from 'react';
// @ts-ignore
import {
  FormControl,
  Heading,
  Paragraph,
  TextInput,
  Accordion,
  AccordionItem,
} from '@contentful/f36-components';

interface Props {
  handleInputChange: Function;
  translationTypeId: string;
  translationTypeName: string;
  translatorTypeId: string;
  translatorTypeName: string;
}

export default class InstallationScreen extends React.Component<Props> {
  render() {
    const {
      translationTypeId,
      translationTypeName,
      translatorTypeId,
      translatorTypeName,
      handleInputChange,
    } = this.props;
    return (
      <div className="app">
        <div className="background">
          <div className="body">
            <div className="config setup">
              <div className="section" data-test-id="getting-started">
                <Heading>Configuration</Heading>
                <Paragraph className="about-p">
                  To get started, the App will create two content types—one for managing Translation
                  projects and the other for Translators.
                </Paragraph>
                <Paragraph className="about-p">
                  A <i>Translation project</i> is a collection of Entries and target languages that
                  allow you to group content based on your localization needs while a{' '}
                  <i>Translator</i> determines where to send and process Translations. There are
                  currently two options to choose from; Acclaro and Export/Import.{' '}
                  {/* <a href="#">Learn more about localization workflows in Contentful</a>. */}{' '}
                  {/* Removed until we have an actual link */}
                </Paragraph>
                <Paragraph className="about-p">
                  If you would like to customize the names and IDs of these content types, you can
                  do so in "Advanced settings."
                </Paragraph>
                <Accordion align="end" testId="cf-ui-accordion">
                  <AccordionItem
                    testId="cf-ui-accordion-item"
                    title="Advanced settings"
                    titleElement="h3"
                  >
                    <FormControl isRequired>
                      <FormControl.Label>Content Type Name - Translation</FormControl.Label>
                      <FormControl.HelpText>
                        Set the name for the content model for creating translations
                      </FormControl.HelpText>
                      <TextInput
                        testId="translationTypeName"
                        name="translationTypeName"
                        id="translationTypeName"
                        className="ts-text-field translations-type-name"
                        value={translationTypeName}
                        // @ts-ignore 2339
                        onChange={(e) => handleInputChange(e)}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormControl.Label>Content Type ID - Translation</FormControl.Label>
                      <FormControl.HelpText>
                        Set the ID for the content model for creating translations
                      </FormControl.HelpText>
                      <TextInput
                        testId="translationTypeId"
                        name="translationTypeId"
                        id="translationTypeId"
                        className="ts-text-field translations-type-id"
                        value={translationTypeId}
                        // @ts-ignore 2339
                        onChange={(e) => handleInputChange(e)}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormControl.Label>Content Type Name - Translator</FormControl.Label>
                      <FormControl.HelpText>
                        Set the name for the content model for creating translators
                      </FormControl.HelpText>
                      <TextInput
                        testId="translatorTypeName"
                        name="translatorTypeName"
                        id="translatorTypeName"
                        className="ts-text-field translators-type-name"
                        value={translatorTypeName}
                        // @ts-ignore 2339
                        onChange={(e) => handleInputChange(e)}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormControl.Label>Content Type ID - Translator</FormControl.Label>
                      <FormControl.HelpText>
                        Set the ID for the content model for creating translators
                      </FormControl.HelpText>
                      <TextInput
                        testId="translatorTypeId"
                        name="translatorTypeId"
                        id="translatorTypeId"
                        className="ts-text-field translators-type-id"
                        value={translatorTypeId}
                        // @ts-ignore 2339
                        onChange={(e) => handleInputChange(e)}
                      />
                    </FormControl>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
