import { EditorExtensionSDK, SidebarExtensionSDK } from '../extensions-sdk';
import { uniqueArray, getContentTypes, getBaseEntry, REQUEST_LIMIT, ExtensionSDK } from './helpers';
import loGet from 'lodash.get';

export const MEDIA_ASSETS_ID = 'mediaAssets';

export const MEDIA_LINK_TYPE = 'Asset';

export const MEDIA_ASSET_FIELDS = ['title', 'description'];

const currentDate = new Date().toISOString();

export const mediaAsset: any = {
  name: 'Media',
  description: null,
  displayField: 'title',
  fields: [
    {
      id: 'title',
      name: 'Title',
      type: 'Symbol',
      localized: true,
      required: true,
      validations: [],
      disabled: false,
      omitted: false,
    },
    {
      id: 'description',
      name: 'Description',
      type: 'Text',
      localized: true,
      required: false,
      validations: [],
      disabled: false,
      omitted: false,
    },
  ],
  sys: {
    createdAt: currentDate,
    createdBy: { sys: {} },
    environment: { sys: {} },
    firstPublishedAt: currentDate,
    id: MEDIA_ASSETS_ID,
    publishedAt: currentDate,
    publishedBy: { sys: {} },
    publishedCounter: 0,
    publishedVersion: 0,
    space: { sys: {} },
    type: 'ContentType',
    updatedAt: currentDate,
    updatedBy: { sys: {} },
    version: 1,
  },
};

export const isMediaAsset = (string: string) => {
  return string === MEDIA_ASSETS_ID;
};

export const isMediaLinkType = (string: any) => {
  return string && string === MEDIA_LINK_TYPE;
};

/**
 * Return full assets data from contentful api
 * @param sdk
 * @param assetIds
 */
export const getFullAssets = async (sdk: ExtensionSDK, assetIds: string[]) => {
  let fullAssets: any[] = [];
  assetIds = uniqueArray(assetIds);
  const chunk = (array: any[], size: number) => {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
      chunked_arr.push(array.slice(index, size + index));
      index += size;
    }
    return chunked_arr;
  };
  let chunkedIds = chunk(assetIds, 10);
  for (let chunkIds of chunkedIds) {
    const assetData: any = await sdk.cma.asset.getMany({
      query: {
        'sys.id[in]': chunkIds,
      },
    });
    fullAssets = [...fullAssets, ...assetData.items];
  }
  return fullAssets;
};

/**
 * This will be used to fetch assets for mutiple puposes like getting all assets or 
 * all assets of a specific content type or using specific query.
 * 
 * @param sdk KnownSDK
 * @param total Number of assets to fetch in case we want to get only total number of assets
 * @param subQuery the query filters to override existing ones or add new
 * @returns 
 */
export const getAllAssets = async (sdk: ExtensionSDK, total: number = 0, subQuery = {}) => {
	const allAssets: any[] = [];
	const baseQuery = {
		limit: total > 0 ? total : REQUEST_LIMIT,
		skip: 0,
	};
	const finalQuery = { ...baseQuery, ...subQuery };
	const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
	let waitTime = 0;
	let retry = false;

	do {
		try {
			await delay(waitTime);
			const response = await sdk.cma.asset.getMany({
				query: finalQuery,
			});
			allAssets.push(...response.items);
	
			total = total === 0 ? response.total : total;
			finalQuery.skip += finalQuery.limit;
			if (finalQuery.limit < 500) {
				finalQuery.limit = Math.min(REQUEST_LIMIT, finalQuery.limit*2);
			}
			retry = false;
		} catch (error) {
			error = JSON.parse(error.message);
			retry = true;
			if (error.status === 400 && error.message.includes("Response size too big.")) {
				finalQuery.limit = Math.max(1, Math.floor(finalQuery.limit / 2));
			} else if (error.status === 429) {
				waitTime+= 500;
			} else {
				console.error("Error: ", error);
				retry = false; // Do not retry for unknown errors
			}
		}
	} while (retry || finalQuery.skip < total);

	return allAssets;
}

/**
 * get all the media source content with the child entries
 */
export const getMediaSourceContent = async (sdk: EditorExtensionSDK | SidebarExtensionSDK) => {
  // @ts-ignore
  const locale = sdk.locales.default;

  let { sourceContent } = sdk.entry.fields;
  let allAssets: any[] = [];
  let assetsToExplore = [];
  let referenceFieldMap: any = {};
  let sourceContentValue: any[] = sourceContent.getValue() || [];
  const allContentTypes = await getContentTypes(sdk);
  let assetIds: any = [];
  for (const asset of sourceContentValue) {
    assetsToExplore.push({ topLevel: true, asset });
    assetIds.push(asset.sys.id);
  }

  if (assetsToExplore.length > 0) {
    allAssets = await getFullAssets(sdk, assetIds);
  }

  let sourceEntries: any = [];
  if (allAssets && allAssets.length) {
    for (const asset of allAssets) {
      const sourceAsset: any = getBaseEntry(asset, allContentTypes, locale);
      const contentType: any = allContentTypes.find((aContentType: any) => {
        return isMediaAsset(aContentType.sys.id);
      });
      for (const field in asset.fields) {
        if (MEDIA_ASSET_FIELDS.includes(field)) {
          sourceAsset['fields'][field] = loGet(asset, `fields.${field}.${locale}`);
        }
      }
      if (Object.keys(sourceAsset.fields).length > 0) {
        sourceEntries.push(sourceAsset);
      }
    }
  }
  // Having this return type as it is used in other places aswell. Verification needed
  return { sourceEntries, referenceFieldMap };
};

export const getAssetById = async (entries: any, entryId: string, sdk: EditorExtensionSDK) => {
  let entry = entries.find((fullEntry: any) => fullEntry.sys.id == entryId);
  if (!entry) {
    entry = await sdk.cma.asset.get({ assetId: entryId });
  }
  return entry;
};
