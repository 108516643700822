import React from 'react';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  SkeletonRow,
  TextLink,
  Modal,
  Card,
  EntityList,
  EntityListItem,
  Paragraph,
} from '@contentful/f36-components';
import { ExternalLinkIcon } from '@contentful/f36-icons';
import { PageExtensionSDK } from '../../extensions-sdk';
import loGet from 'lodash.get';
import { getDisplayField, getViewAllLink } from '../../utils/contentful';
import { dateTimeFormat } from '../../../../lambda/src/utils/helpers';
import { getFullEntries, getContentTypes, REQUEST_LIMIT } from '../../utils/helpers';

interface Props {
  sdk: PageExtensionSDK;
}

interface State {
  loading: boolean;
  visibleProjects: any[];
  itemsVisible: number;
  allProjects: number;
  selectedEntries: any[];
  contentTypes: any[];
}

export default class ProjectsWithUpdate extends React.Component<Props, State> {
  state: State = {
    loading: true,
    visibleProjects: [],
    itemsVisible: 10,
    allProjects: 0,
    selectedEntries: [],
    contentTypes: [],
  };
  async componentDidMount() {
    await this.updateVisibleProjects();
    this.setState({
      loading: false,
    });
  }

  loadMore = () => {
    this.setState(
      {
        itemsVisible: this.state.itemsVisible + 5,
      },
      () => {
        this.updateVisibleProjects();
      },
    );
  };

  async updateVisibleProjects() {
    this.setState({
      loading: true,
    });
    const { sdk } = this.props;
    const defaultLocale = sdk.locales.default;
    const contentTypes: any[] = await getContentTypes(sdk);
    this.setState({
      contentTypes,
    });
    //@ts-ignore
    const translationProjectContentType = sdk.parameters.installation.translationsModelId;
    const entriesForUpdatePath = `fields.translationInfo.${defaultLocale}.entriesForUpdate`;
    let skip = 0;
    let total = 0;
    let visibleProjects = [];
    let count = 0;
    let limit = REQUEST_LIMIT;
    do {
      const projectsResponse: any = await sdk.cma.entry.getMany({
        query: {
          content_type: translationProjectContentType,
          'sys.publishedAt[exists]': true,
          'fields.translationInfo[exists]': true,
          skip: skip,
          limit: limit,
        },
      });
      let projects: any[] = projectsResponse.items;
      for (let project of projects) {
        const entriesForUpdate = loGet(project, entriesForUpdatePath, []);
        if (entriesForUpdate.length < 1) {
          continue;
        }
        count++;
        if (count >= this.state.itemsVisible) {
          continue;
        }
        const contentType = (
          this.state.contentTypes.find((contentType: any) => {
            return contentType.sys.id === loGet(project, `fields.contentType.${defaultLocale}`);
          }) || {}
        ).name;
        visibleProjects.push({
          name: getDisplayField(project, contentTypes, sdk.locales.default),
          contentType: contentType,
          updateCount: entriesForUpdate.length,
          entriesForUpdate: entriesForUpdate,
          lastUpdated: loGet(project, `fields.translationInfo.${defaultLocale}.activities`, [
            { time: new Date().getTime() },
          ]).pop(),
          id: project.sys.id,
        });
      }
      total = projectsResponse.total;
      skip += REQUEST_LIMIT;
    } while (skip < total);
    this.setState({
      visibleProjects,
      loading: false,
      allProjects: count,
    });
  }

  setSelectedEntries = async (entries: any) => {
    const fullEntries = await getFullEntries(this.props.sdk, entries);
    this.setState({
      selectedEntries: fullEntries,
    });
  };

  render() {
    const visibleProjects = this.state.visibleProjects;
    const sdk = this.props.sdk;
    //@ts-ignore
    const link = getViewAllLink(sdk.ids, sdk.parameters.installation.translationsModelId);
    return (
      <>
        <Card>
          <h3 className={'flex-container space-between'}>
            <div>Recently Modified Projects</div>
            <div>
              <TextLink
                href={link}
                icon={<ExternalLinkIcon />}
                rel="noopener noreferrer"
                target={'_blank'}
              >
                View all translation projects
              </TextLink>
            </div>
          </h3>
          <div className={'load-more-container'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Content type</TableCell>
                  <TableCell>Modified Source Entries</TableCell>
                  <TableCell>Last updated at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.loading ? (
                  <SkeletonRow rowCount={4} columnCount={4} />
                ) : !this.state.loading && !visibleProjects.length ? (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>No source Entries have changed since submission</TableCell>
                  </TableRow>
                ) : (
                  <>
                    {visibleProjects.map((project) => {
                      return (
                        <TableRow key={project.name}>
                          <TableCell>
                            <TextLink
                              onClick={() => {
                                sdk.navigator.openEntry(project.id, { slideIn: true });
                              }}
                            >
                              {project.name}
                            </TextLink>
                          </TableCell>
                          <TableCell>{project.contentType}</TableCell>
                          <TableCell>
                            <TextLink
                              onClick={async () => {
                                await this.setSelectedEntries(project.entriesForUpdate);
                              }}
                            >
                              ({project.updateCount}){' '}
                              {project.updateCount > 1 ? 'Entries' : 'Entry'}
                            </TextLink>
                          </TableCell>
                          <TableCell>{dateTimeFormat(project.lastUpdated.time)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            </Table>
            {!this.state.loading && this.state.itemsVisible < this.state.allProjects && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                <TextLink className={'load-more'} variant={'muted'} onClick={this.loadMore}>
                  Load More
                </TextLink>
              </div>
            )}
          </div>
        </Card>
        <Modal
          isShown={this.state.selectedEntries.length > 0}
          title={'Modified Source Entries'}
          onClose={() => {
            this.setState({
              selectedEntries: [],
            });
          }}
        >
          <Paragraph style={{ marginBottom: '1em' }}>
            The following Entries have been updated since submission:
          </Paragraph>

          <EntityList>
            {this.state.selectedEntries.map((entry: any) => {
              return (
                <EntityListItem
                  title={getDisplayField(
                    entry,
                    this.state.contentTypes,
                    this.props.sdk.locales.default,
                  )}
                  onClick={() => {
                    sdk.navigator.openEntry(entry.sys.id, { slideIn: true });
                  }}
                ></EntityListItem>
              );
            })}
          </EntityList>
        </Modal>
      </>
    );
  }
}
