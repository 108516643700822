//https://github.com/btmills/react-datamaps#readme - modified
import React from 'react';
const Datamap = require('../../../utils/datamaps.world.min');

const MAP_CLEARING_PROPS = ['height', 'scope', 'setProjection', 'width'];

const propChangeRequiresMapClear = (
  oldProps: Readonly<Props> & Readonly<{ children?: React.ReactNode }>,
  newProps: { [x: string]: any },
) => {
  // @ts-ignore
  return MAP_CLEARING_PROPS.some((key) => oldProps[key] !== newProps[key]);
};

interface Props {
  arc?: any;
  arcOptions?: any;
  bubbleOptions?: any;
  bubbles?: any;
  data?: any;
  graticule?: any;
  height?: any;
  labels?: any;
  responsive?: any;
  style?: any;
  updateChoroplethOptions?: any;
  width?: any;
  [key: string]: any;
}

export default class DatamapComponent extends React.Component<Props> {
  map: any;
  container: any;
  constructor(props: Props) {
    super(props);
    this.resizeMap = this.resizeMap.bind(this);
    this.container = React.createRef();
  }

  componentDidMount() {
    if (this.props.responsive) {
      window.addEventListener('resize', this.resizeMap);
    }
    this.drawMap();
  }

  componentWillReceiveProps(newProps: any) {
    if (propChangeRequiresMapClear(this.props, newProps)) {
      this.clear();
    }
  }

  componentDidUpdate() {
    this.drawMap();
  }

  componentWillUnmount() {
    this.clear();
    if (this.props.responsive) {
      window.removeEventListener('resize', this.resizeMap);
    }
  }

  clear() {
    for (const child of Array.from(this.container.current.childNodes)) {
      this.container.current.removeChild(child);
    }

    delete this.map;
  }

  drawMap() {
    const {
      arc,
      arcOptions,
      bubbles,
      bubbleOptions,
      data,
      graticule,
      labels,
      updateChoroplethOptions,
      ...props
    } = this.props;

    let map = this.map;

    if (!map) {
      // @ts-ignore
      map = this.map = new Datamap({
        ...props,
        data,
        element: this.container.current,
      });
    } else {
      map.updateChoropleth(data, updateChoroplethOptions);
    }

    if (arc) {
      map.arc(arc, arcOptions);
    }

    if (bubbles) {
      map.bubbles(bubbles, bubbleOptions);
    }

    if (graticule) {
      map.graticule();
    }

    if (labels) {
      map.labels();
    }
  }

  resizeMap() {
    this.map.resize();
  }

  render() {
    const style = {
      height: '100%',
      position: 'relative',
      width: '100%',
      ...this.props.style,
    };

    return <div ref={this.container} style={style} />;
  }
}
