import React from 'react';
import { locations } from 'contentful-ui-extensions-sdk';
import {
  AppExtensionSDK,
  SidebarExtensionSDK,
  FieldExtensionSDK,
  EditorExtensionSDK,
  PageExtensionSDK,
	KnownSDK,
} from '../extensions-sdk';
import AppConfig from '../location-components/AppConfig';
import Sidebar from '../location-components/Sidebar';
import Editor from '../location-components/Editor';
import Field from '../location-components/Field';
import Page from '../location-components/Page';

interface Props {
  sdk: KnownSDK
}

export default class App extends React.Component<Props> {
  render() {
    const { sdk } = this.props;

    if (sdk.location.is(locations.LOCATION_APP_CONFIG)) {
      return <AppConfig sdk={sdk as AppExtensionSDK} />;
    } else if (sdk.location.is(locations.LOCATION_ENTRY_SIDEBAR)) {
      return <Sidebar sdk={sdk as SidebarExtensionSDK} />;
    } else if (sdk.location.is(locations.LOCATION_ENTRY_FIELD)) {
      return <Field sdk={sdk as FieldExtensionSDK} />;
    } else if (sdk.location.is(locations.LOCATION_ENTRY_EDITOR)) {
      return <Editor sdk={sdk as EditorExtensionSDK} />;
    } else if (sdk.location.is(locations.LOCATION_PAGE)) {
      return <Page sdk={sdk as PageExtensionSDK} />;
    }

    return null;
  }
}
