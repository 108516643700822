import { EditorExtensionSDK } from '../extensions-sdk';

export default abstract class migration {
  sdk: EditorExtensionSDK;
  updateUser: Function;
  public errorMessage =
    'There was an error updating the app to the latest version. Please reload the page and try again.';

  constructor(sdk: EditorExtensionSDK, updateLoadingText: Function) {
    this.sdk = sdk;
    this.updateUser = updateLoadingText;
  }

  abstract runMigration(): Promise<void>;

  // Alerts
  startUpdateAlert = async () => {
    return this.sdk.dialogs.openAlert({
      title: 'Update App to the latest version',
      message:
        'An update is needed which may take a few minutes, Please do not refresh or close the tab. ' +
        'Click proceed to start the process.',
      confirmLabel: 'Proceed',
    });
  };

  finishAlert = async () => {
    return this.sdk.dialogs.openAlert({
      title: 'Translations app updated',
      message:
        'The app has been updated to the latest version. Please refresh the page to proceed.',
    });
  };

  finishWithErrorAlert = async () => {
    this.sdk.dialogs.openAlert({
      title: 'Translations app failed to update',
      message: this.errorMessage,
    });
  };

  getContentType = async (contentTypeId: string) => {
    return await this.sdk.cma.contentType.get({ contentTypeId: contentTypeId });
  };
}
