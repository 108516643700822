import React from 'react';
import { Spinner } from '@contentful/f36-components';

interface Props {
  stat: string;
  text: string;
  divider: boolean;
  loading?: boolean;
}

export default class InsightBox extends React.Component<Props> {
  render() {
    return (
      <>
        <div className={'insight-box'}>
          {this.props.loading && <Spinner />}
          {!this.props.loading && <div className={'insight-stat'}>{this.props.stat}</div>}
          <div className={'insight-text'}>{this.props.text}</div>
        </div>
        {this.props.divider && <div className={'insight-divider'} />}
      </>
    );
  }
}
