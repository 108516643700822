import React, { ChangeEvent } from 'react';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import { MEDIA_LINK_TYPE } from '../../../utils/assetHelpers';
import { getSelections } from '../../../utils/helpers';
import { getMediaSourceContent } from '../../../utils/assetHelpers';
import {
  List,
  Flex,
  Spinner,
  Button,
  Checkbox,
  FormLabel,
  SkeletonContainer,
  SkeletonBodyText,
  EntityList,
  EntityListItem,
  Note,
  FormControl,
} from '@contentful/f36-components';
import { PlusIcon, AssetIcon } from '@contentful/f36-icons';
import TrackChanges from '../parts/TrackChanges';

interface Props {
  value: object[];
  trackChanges: boolean;
  handleFieldChange: Function;
  showTrackChanges: boolean;
  sdk: EditorExtensionSDK;
}

interface State {
  selectedMediaAssets: object[];
  loading: boolean;
  checkedAssets: string[];
  totalAssets: number;
}

export default class MediAssetsField extends React.Component<Props, State> {
  state: State = {
    selectedMediaAssets: [],
    loading: false,
    checkedAssets: [],
    totalAssets: -1,
  };
  timeout: any = null;

  constructor(props: Readonly<Props>) {
    super(props);
    //initially set the value once
    this.props.handleFieldChange('trackChanges', this.props.trackChanges);
  }

  async componentDidMount() {
    await this.handleMount();
    this.props.sdk.entry.fields.sourceContent.onValueChanged(this.updateSourceContent);
    this.props.sdk.entry.fields.sourceContent.onValueChanged((sourceContentValue: any) => {
      if (!sourceContentValue || sourceContentValue.length == 0) {
        this.setState({
          checkedAssets: [],
        });
      }
    });
  }

  handleMount = async () => {
    this.updateSourceContent();
  };

  updateSourceContent = () => {
    this.setState({
      loading: true,
    });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      getMediaSourceContent(this.props.sdk).then((response) => {
        clearTimeout(this.timeout);
        this.setState({
          selectedMediaAssets: response.sourceEntries,
          loading: false,
          totalAssets: -1,
        });
      });
    }, 10);
  };

  updateSelectedAssets = async (selectedAssets: any) => {
    await this.props.handleFieldChange(
      'sourceContent',
      selectedAssets.map((selectedAsset: any) => {
        return {
          sys: {
            id: selectedAsset.sys.id,
            linkType: MEDIA_LINK_TYPE,
            type: 'Link',
          },
        };
      }),
    );
  };

  updateCheckedAssets = async (value: string, all = false) => {
    this.setState((state) => {
      const assets = state.selectedMediaAssets;
      return {
        checkedAssets: getSelections(
          state.checkedAssets,
          assets,
          value,
          all,
          (e: any) => e.entryId,
        ),
      };
    });
  };

  removeAssets = async () => {
    let sourceContent = this.props.sdk.entry.fields.sourceContent.getValue();
    const selectedAssets = sourceContent.filter((filterSelectAsset: { sys: { id: any } }) => {
      return !this.state.checkedAssets.includes(filterSelectAsset.sys.id);
    });
    this.setState({
      checkedAssets: [],
    });
    await this.updateSelectedAssets(selectedAssets);
  };

  openAssetEditor = (assetId: string) => {
    this.props.sdk.navigator.openAsset(assetId, { slideIn: true });
  };

  openMediaSelector = async () => {
    this.props.sdk.dialogs.selectMultipleAssets().then((selectedMediaAssets: any) => {
      if (selectedMediaAssets && selectedMediaAssets.length) {
        // eslint-disable-next-line react/no-access-state-in-setstate
        let sourceContent = this.props.sdk.entry.fields.sourceContent.getValue() || [];
        for (const selectedAsset of selectedMediaAssets) {
          const existingAsset = sourceContent.find((stateAsset: { sys: { id: any } }) => {
            return stateAsset.sys.id == selectedAsset.sys.id;
          });
          if (!existingAsset) {
            sourceContent.push(selectedAsset);
          }
        }
        this.updateSelectedAssets(sourceContent);
      }
    });
  };

  selectAllAssets = async () => {
    this.setState({ loading: true });
    let limit = 1000;
    let skip = 0;
    let selectedAssets: any[] = [];

    while (skip < this.state.totalAssets) {
      let resources = await this.getAssets(limit, skip);
      selectedAssets.push(...resources.items);
      skip += resources.items.length;
    }

    let sourceContent = this.props.sdk.entry.fields.sourceContent.getValue() || [];
    for (const selectedAsset of selectedAssets) {
      const existingAsset = sourceContent.find((stateAsset: { sys: { id: any } }) => {
        return stateAsset.sys.id == selectedAsset.sys.id;
      });
      if (!existingAsset) {
        sourceContent.push(selectedAsset);
      }
    }
    this.updateSelectedAssets(sourceContent);
  };

  setAssetsCount = async (contentTypeValue: string) => {
    let assetsCount = contentTypeValue ? (await this.getAssets(1, 0)).total : -1;
    if (this.state.totalAssets == -1) {
      this.setState({ totalAssets: assetsCount });
    } else {
      this.state.totalAssets = assetsCount;
    }
  };

  getAssets = async (limit: number, skip: number) => {
    return await this.props.sdk.cma.asset.getMany({
      query: {
        limit: limit,
        skip: skip,
      },
    });
  };

  render() {
    const { selectedMediaAssets, checkedAssets } = this.state;
    const selectedMediaAssetsIds = selectedMediaAssets.map((e: any) => e.entryId);
    let hasContentTypeValue = this.props.sdk.entry.fields['contentType'].getValue();
    this.setAssetsCount(hasContentTypeValue);
    return (
      <FormControl isRequired>
        <FormLabel htmlFor="mediaSourceContent">
          {'Media source content'} -{' '}
          {this.state.loading ? 'Loading..' : selectedMediaAssets.length + ' selected'}
        </FormLabel>
        {this.props.showTrackChanges && (
          <TrackChanges
            trackChanges={this.props.trackChanges}
            handleFieldChange={this.props.handleFieldChange}
          ></TrackChanges>
        )}
        {this.state.loading && (
          <>
            <Flex marginBottom="spacingL">
              <Note>
                Please be patient your data is being prepared. This may take some time depending on
                number of assets selected.
              </Note>
            </Flex>
            <SkeletonContainer>
              <SkeletonBodyText numberOfLines={4} />
            </SkeletonContainer>
          </>
        )}
        {!this.state.loading && selectedMediaAssets.length > 0 && (
          <>
            {selectedMediaAssetsIds.length > 0 && (
              <div className={'source-content-button-container'}>
                <div style={{ paddingLeft: '10px' }}>
                  <Checkbox
                    isChecked={checkedAssets.length == selectedMediaAssetsIds.length}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      this.updateCheckedAssets(evt.target.value, true);
                    }}
                    id={'select-all'}
                  >
                    Select all
                  </Checkbox>
                </div>
                {checkedAssets.length > 0 && (
                  <Button
                    type="button"
                    variant={'negative'}
                    size={'small'}
                    isDisabled={this.state.checkedAssets.length < 1}
                    onClick={async () => {
                      const response = await this.props.sdk.dialogs.openConfirm({
                        title: 'Remove selected media assets?',
                        message: 'Are you sure you want to remove selected media assets?',
                        intent: 'positive',
                        confirmLabel: 'Yes!',
                        cancelLabel: 'No',
                      });
                      if (response) {
                        this.removeAssets();
                      }
                    }}
                  >
                    Remove
                  </Button>
                )}
              </div>
            )}
            <List className="listOnScroll">
              <EntityList className={'source-content-list'}>
                {selectedMediaAssets.map((selectedAsset: any) => {
                  return (
                    <div className={'checkbox-entity-list'} key={selectedAsset.entryId + 'parent'}>
                      {selectedMediaAssetsIds.includes(selectedAsset.entryId) && (
                        <Checkbox
                          key={selectedAsset.entryId + 'check'}
                          value={selectedAsset.entryId}
                          isChecked={checkedAssets.includes(selectedAsset.entryId)}
                          onChange={() => {
                            this.updateCheckedAssets(selectedAsset.entryId);
                          }}
                        />
                      )}
                      <EntityListItem
                        className={'source-content-item'}
                        // @ts-ignore
                        key={selectedAsset.entryId}
                        title={MEDIA_LINK_TYPE}
                        description={selectedAsset.entryName || 'Entry'}
                        entityType="entry"
                        onClick={this.openAssetEditor.bind(this, selectedAsset.entryId)}
                        status={selectedAsset.entryStatus}
                      />
                    </div>
                  );
                })}
              </EntityList>
            </List>
          </>
        )}
        <Flex flexDirection="row" marginTop="spacingM">
          <Button startIcon={<PlusIcon />} variant={'secondary'} onClick={this.openMediaSelector}>
            Add Media Assets
          </Button>
          <Flex marginLeft="spacingM">
            <Button
              isDisabled={!hasContentTypeValue || selectedMediaAssets.length > 0}
              startIcon={<AssetIcon />}
              variant={'secondary'}
              onClick={this.selectAllAssets}
              size="medium"
            >
              {this.state.loading ? (
                <Spinner />
              ) : this.state.totalAssets >= 0 ? (
                `Add all ${this.state.totalAssets} assets`
              ) : (
                'No assets'
              )}
            </Button>
          </Flex>
        </Flex>
      </FormControl>
    );
  }
}
