import React from 'react';
import { TextLink } from '@contentful/f36-components';
import { CopyIcon } from '@contentful/f36-icons';
import { EditorExtensionSDK } from '../../../extensions-sdk';

interface Props {
  contentId: string;
  sdk: EditorExtensionSDK;
}

export default class CopyButton extends React.Component<Props> {
  copyText() {
    // @ts-ignore
    window.getSelection().removeAllRanges();
    let range = document.createRange();
    // @ts-ignore
    range.selectNode(document.getElementById(this.props.contentId));
    // @ts-ignore
    window.getSelection().addRange(range);
    document.execCommand('copy');
    // @ts-ignore
    window.getSelection().removeAllRanges();
    this.props.sdk.notifier.success('Copied to clipboard');
  }
  render() {
    return (
      <TextLink
        onClick={async () => {
          this.copyText();
        }}
      >
        <CopyIcon variant="muted"></CopyIcon>
      </TextLink>
    );
  }
}
