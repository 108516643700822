import React from 'react';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  SkeletonRow,
  Button,
  TextLink,
} from '@contentful/f36-components';
import { PageExtensionSDK } from '../../../extensions-sdk';
import { getServiceImage } from '../icons';
import { sessionStore } from '../../../utils/SessionStore';
import localClient from '../../../utils/localClient';
import { REQUEST_LIMIT, getSessionStoreKey } from '../../../utils/helpers';

interface Props {
  sdk: PageExtensionSDK;
}

interface State {
  loading: boolean;
  visibleTranslators: any[];
  itemsVisible: number;
  allTranslators: number;
}

export default class TranslatorCoverage extends React.Component<Props, State> {
  state: State = {
    loading: true,
    visibleTranslators: [],
    itemsVisible: 10,
    allTranslators: 0,
  };
  translatorContentType = '';

  constructor(props: Readonly<Props>) {
    super(props);
    //@ts-ignore
    this.translatorContentType = props.sdk.parameters.installation.translatorModelId;
  }

  async componentDidMount() {
    await this.updateVisibleTranslators();
    this.setState({
      loading: false,
    });
  }

  loadMore = () => {
    this.setState(
      {
        itemsVisible: this.state.itemsVisible + 5,
      },
      () => {
        this.updateVisibleTranslators();
      },
    );
  };

  async updateVisibleTranslators() {
    this.setState({
      loading: true,
    });

    const { sdk } = this.props;
    const storeKey = getSessionStoreKey('visible_translators', sdk, this.state.itemsVisible);
    const storeKeyTotal = getSessionStoreKey(
      'visible_translators_total',
      sdk,
      this.state.itemsVisible,
    );
    let visibleTranslators: any[] = [];
    let total = 0;
    if (!sessionStore.get(storeKey)) {
      const defaultLocale = sdk.locales.default;
      //@ts-ignore
      const translationProjectContentType = sdk.parameters.installation.translationsModelId;

      let skip = 0;
      let count = 0;
      let limit = REQUEST_LIMIT;

      do {
        const translatorResponse: any = await sdk.cma.entry.getMany({
          query: {
            content_type: this.translatorContentType,
            'sys.publishedAt[exists]': true,
            skip: skip,
            limit: limit,
          },
        });
        let translators: any[] = translatorResponse.items;
        for (let translator of translators) {
          let translationProjectIds: string[] = [];
          let skipItems = 0;
          let totalItems = 0;
          do {
            const projectResponse = await sdk.cma.entry.getMany({
              query: {
                links_to_entry: translator.sys.id,
                content_type: translationProjectContentType,
                'sys.publishedAt[exists]': true,
                limit: limit,
              },
            });
            let translationProjects = projectResponse.items;
            translationProjectIds = [
              ...translationProjectIds,
              ...translationProjects.map((e: any) => e.sys.id),
            ];
            totalItems = projectResponse.total;
            skipItems += REQUEST_LIMIT;
          } while (skipItems < totalItems);

          let translatedEntryInfo = await localClient.getTranslatedEntriesInfo({
            translationProjectIds,
          });
          if (count >= this.state.itemsVisible) {
            break;
          }
          visibleTranslators.push({
            name: translator.fields.name[defaultLocale],
            service: translator.fields.translationService[defaultLocale],
            wordCount: translatedEntryInfo.wordCount,
            projectCount: totalItems,
          });
          count++;
        }
        total = translatorResponse.total;
        skip += REQUEST_LIMIT;

        if (count >= this.state.itemsVisible) {
          break;
        }
      } while (skip < total);
      sessionStore.set(storeKey, visibleTranslators);
      sessionStore.set(storeKeyTotal, total);
    } else {
      visibleTranslators = sessionStore.get(storeKey);
      total = sessionStore.get(storeKeyTotal);
    }
    this.setState({
      visibleTranslators,
      loading: false,
      allTranslators: total,
    });
  }

  render() {
    const visibleTranslators = this.state.visibleTranslators;
    return (
      <>
        <h4 className={'flex-container space-between'}>
          <div>Translators</div>
          <div>
            <Button
              variant="primary"
              onClick={() => {
                this.props.sdk.navigator.openNewEntry(this.translatorContentType);
              }}
            >
              Create translator
            </Button>
          </div>
        </h4>
        <div className={'load-more-container'}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Translator</TableCell>
                <TableCell>Translation Projects</TableCell>
                <TableCell>Words Translated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.loading ? (
                <SkeletonRow rowCount={4} columnCount={3} />
              ) : !this.state.loading && !visibleTranslators.length ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Nothing here</TableCell>
                </TableRow>
              ) : (
                <>
                  {visibleTranslators.map((translator) => {
                    return (
                      <TableRow key={translator.name}>
                        <TableCell className={'flex-container'}>
                          <img
                            className={'coverage-icon'}
                            src={getServiceImage(translator.service)}
                          />
                          {translator.name}
                        </TableCell>
                        <TableCell>{translator.projectCount}</TableCell>
                        <TableCell>{translator.wordCount}</TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
          {!this.state.loading && this.state.itemsVisible < this.state.allTranslators && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
              <TextLink className={'load-more'} variant={'muted'} onClick={this.loadMore}>
                Load More
              </TextLink>
            </div>
          )}
        </div>
      </>
    );
  }
}
