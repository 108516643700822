export const FIELD_SYMBOL = "Symbol";
export const FIELD_TEXT = "Text";
export const FIELD_RICHTEXT = "RichText";
export const FIELD_INTEGER = "Integer";
export const FIELD_NUMBER = "Number";
export const FIELD_DATE = "Date";
export const FIELD_BOOLEAN = "Boolean";
export const FIELD_LOCATION = "Location";
export const FIELD_OBJECT = "Object";
export const FIELD_LINK = "Link";
export const FIELD_ARRAY = "Array";

export interface FieldConfig {
  source?: any;
  target?: any;
  ids?: any; // Adding this as we need to create complete contentful url for assets which needs space and env ids.
}

export default class Field {
  config: FieldConfig;

  constructor(config: FieldConfig) {
    this.config = config;
  }

  /**
   * Extract field values from standard Contentful JSON and format it for exporting
   * Out of Contentful
   */
  out(): any {
    return this.config.source;
  }

  /**
   * Extract field values from translated JSON and format it for use in POST request
   * Into contentful
   */
  async in(): Promise<any> {
    return this.config.target;
  }

  /**
   * Get word count of a field
   */
  getWordCount(): Number {
    return 0;
  }
}
