import Field from "./field";

export default class IntegerField extends Field {
  /**
   * Assuming number can be treated as a single word
   */
  getWordCount(): Number {
    return 1;
  }
}
