import React from 'react';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import { HelpCircleIcon } from '@contentful/f36-icons';
import { Accordion } from '@contentful/f36-accordion';
import { Datepicker, FormControl, Switch, Textarea, Tooltip } from '@contentful/f36-components';
import ProgramSelector from '../fields/ProgramSelectorField';
import { OPTION_ACCLARO } from '../../../config/translatorModel';
import localClient from '../../../utils/localClient';
import loGet from 'lodash.get';

export interface Programs {
  id: number,
  name: string,
  description: string
}

interface Props {
  sdk: EditorExtensionSDK;
  dueDate: Date;
  handleFieldChange: Function;
  requestedDueDate: string;
  translationNotes: string;
  translateSlug: boolean;
  translator: any;
  selectedProgram: number | null;
}

interface State {
  requestedDueDate: string;
  translationNotes: string;
  translateSlug: boolean;
  [key: string]: unknown;
  translatorName: string;
  programSelectionEnabled: boolean;
  allPrograms: Programs[];
  selectedProgram: number | null
}

export default class AdvanceOptions extends React.Component<Props, State> {
  state: State = {
    requestedDueDate: '',
    translationNotes: '',
    translateSlug: false,
    translatorName: '',
    programSelectionEnabled: false,
    allPrograms: [],
    selectedProgram: null
  };

  async componentDidMount() {
    const translatorId = loGet(this.props.translator, 'sys.id');
    if (translatorId) {
      const locale = this.props.sdk.locales.default;
      const translatorEntry = await this.props.sdk.cma.entry.get({ entryId: translatorId });
      const translatorName = translatorEntry.fields.translationService[locale] ?? "";

      this.setState({
        translatorName
      });

      this.setState({
        requestedDueDate: this.props.requestedDueDate,
        translationNotes: this.props.translationNotes,
        translateSlug: this.props.translateSlug,
      });
    } else {
      this.setState({
        translatorName: '',
      });
    }
  }

  handleFieldChange = (fieldName: string, value: any) => {
    this.setState({
      [fieldName]: value
    });
    this.props.handleFieldChange(fieldName, value);
  };

  async componentDidUpdate(prevProps: Props) {
    // Check if the translator prop has changed
    if (prevProps.translator !== this.props.translator) {
      this.componentDidMount()
    }
  }

  render(): React.ReactNode {
    return (
      <>
        <FormControl className="advance-options">
          {this.state.translatorName === OPTION_ACCLARO && (
            <>
              <FormControl className="advance-options">
                {/* Can be replaced by native date picker offered in v4 */}
                <FormControl.Label>Requested due date</FormControl.Label>
                <Datepicker
                  id={'requested-date'}
                  {...(this.state.requestedDueDate !== '' && { selected: this.props.dueDate })}
                  onSelect={(selectedDate: Date | undefined) => {
                    //@ts-ignore
                    this.handleFieldChange('requestedDueDate', selectedDate);
                  }}
                />
              </FormControl>
            </>
          )}
    
          <FormControl style={{ display: 'inline-flex' }} className="no-margin">
            <Switch
              id={'slug-translate-switch'}
              isChecked={this.state.translateSlug}
              onChange={async () => {
                this.handleFieldChange('translateSlug', !this.state.translateSlug);
              }}
            />{' '}
            <FormControl.Label style={{ paddingRight: '5px', paddingBottom: '15px', paddingLeft: '10px' }}>
              Include slugs in translation workflow
            </FormControl.Label>
            <Tooltip
              content={
                'Enabling this will include slug of content/media. By default slugs are not included in translation workflow.'
              }
            >
              <HelpCircleIcon className={'tooltip-icon'} variant={'muted'} />
            </Tooltip>
          </FormControl>
          <FormControl isRequired className="advance-options">
            <FormControl.Label>Instructions</FormControl.Label>
            <Textarea
              id={'additional-notes'}
              name={'additional-notes'}
              value={this.state.translationNotes}
              placeholder="Enter N/A, if none"
              onChange={(e) => {
                //@ts-ignore
                this.handleFieldChange('translationNotes', e.target.value);
              }}
            />
          </FormControl>
        </FormControl>
      </>
    );
    
  }
}
