import React from 'react';
import { PageExtensionSDK } from '../../../extensions-sdk';
import { Card } from '@contentful/f36-components';
import Coverage from './Coverage';
import TotalWords from './TotalWords';
import TotalLanguages from './TotalLanguages';
import EntriesTranslated from './EntriesTranslated';

interface Props {
  sdk: PageExtensionSDK;
}

interface State {}

export default class InsightsWidget extends React.Component<Props, State> {
  state: State = {};

  render() {
    return (
      <Card padding="default" testId="cf-ui-card" className={'insights-widget'}>
        <Coverage sdk={this.props.sdk} divider={true} />
        <TotalWords sdk={this.props.sdk} divider={true} />
        <TotalLanguages sdk={this.props.sdk} divider={true} />
        <EntriesTranslated sdk={this.props.sdk} divider={false} />
      </Card>
    );
  }
}
