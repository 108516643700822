import React from 'react';
import { SidebarExtensionSDK } from '../../extensions-sdk';
import { Button, Flex, Menu, SectionHeading, IconButton } from '@contentful/f36-components';
import { ChevronDownIcon } from '@contentful/f36-icons';
import localClient from '../../utils/localClient';
import { isArchived, isChanged, isDraft, isPublished } from '../../utils/contentful';
import { OPTION_LOCAL } from '../../config/translatorModel';
import LinkedTranslationProjects from './LinkedTranslationProjects';
import { encryptApiKey, decryptApiKey } from '../../utils/helpers';

interface Props {
  sdk: SidebarExtensionSDK;
}

export default class TranslatorSidebar extends React.Component<Props> {
  state: any = {
    isOpen: false,
    isAuthenticated: false,
    loading: false,
    isArchived: false,
    isChanged: false,
    isDraft: false,
    isPublished: false,
  };

  async componentDidMount() {
    this.props.sdk.window.startAutoResizer();
    this.props.sdk.entry.fields['translatorInfo'].onValueChanged(this.handleTranslatorInfoChange);
    this.props.sdk.entry.onSysChanged(this.handleEntry);
    this.handleEntry();
  }

  handleEntry = async () => {
    const { entry } = this.props.sdk;
    this.setState({
      isArchived: isArchived(entry.getSys()),
      isChanged: isChanged(entry.getSys()),
      isDraft: isDraft(entry.getSys()),
      isPublished: isPublished(entry.getSys()),
    });
  };

  handleTranslatorInfoChange = async (value: any) => {
    this.setState({
      isAuthenticated: value ? value.isAuthenticated : false,
    });
  };

  isValid = () => {
    const { sdk } = this.props;
    let valid = true;

    if (
      sdk.entry.fields.name.getValue() === undefined ||
      /^\s*$/.test(sdk.entry.fields.name.getValue())
    ) {
      valid = false;
      sdk.notifier.error('Name is required');
    }
    if (sdk.entry.fields.translationService.getValue() === undefined) {
      valid = false;
      sdk.notifier.error('Translation service is required');
    }
    if (
      sdk.entry.fields.translationService.getValue() &&
      sdk.entry.fields.translationService.getValue() != OPTION_LOCAL &&
      (sdk.entry.fields.apiKey.getValue() === undefined ||
        /^\s*$/.test(sdk.entry.fields.apiKey.getValue()))
    ) {
      valid = false;
      sdk.notifier.error('API key is required');
    }
    if (!valid) {
      this.setState({
        loading: false,
      });
    }
    return valid;
  };

  updateTranslatorInfo = async (authStatus: boolean) => {
    await this.props.sdk.entry.fields.translatorInfo.setValue({
      ...this.props.sdk.entry.fields.translatorInfo.getValue(),
      isAuthenticated: authStatus,
    });
  };

  authenticateService = async () => {
    const { sdk } = this.props;
    this.setState(
      {
        loading: true,
      },
      async () => {
        if (!this.isValid()) {
          return;
        }
        const response: any = await localClient.authenticate({
          // @ts-ignore
          service: sdk.entry.fields.translationService.getValue(),
          apiKey: decryptApiKey(sdk.entry.fields.apiKey.getValue()),
          sandbox: sdk.entry.fields.sandbox ? sdk.entry.fields.sandbox.getValue() : null,
        });
        if (!response.success) {
          sdk.notifier.error(`Failed to activate. Please check the configuration again`);
          this.setState({
            loading: false,
          });
          return false;
        } else {
          await this.updateTranslatorInfo(true);
          await this.props.sdk.cma.entry
            .get({ entryId: this.props.sdk.entry.getSys().id })
            .then((entry) => {
              this.props.sdk.cma.entry.publish({ entryId: entry.sys.id }, entry);
            });
          this.props.sdk.notifier.success('Translator successfully saved');
          this.setState({
            loading: false,
          });
        }
      },
    );
  };

  isActive = () => {
    return this.state.isAuthenticated && (this.state.isPublished || this.state.isChanged);
  };

  getStatus = (capFirst: boolean = false) => {
    let status: string;
    if (this.isActive()) {
      status = 'active';
    } else if (this.state.isArchived) {
      status = 'archived';
    } else {
      status = 'inactive';
    }
    if (capFirst) {
      status = status.charAt(0).toUpperCase() + status.slice(1);
    }
    return status;
  };

  getButtonType = (): string => {
    if (this.state.isDraft || !this.state.isAuthenticated) {
      return 'primary';
    }
    return 'positive';
  };

  getButtonClass = (): string => {
    if (this.isDropDownVisible()) {
      return 'translator-button-80';
    }
    return 'translator-button';
  };

  getButtonLabel = (): string => {
    if (this.needsActivation()) {
      return 'Activate';
    } else if (this.state.isPublished) {
      return 'Change status';
    } else if (this.state.isChanged) {
      return 'Update translator';
    } else if (this.state.isArchived) {
      return 'Unarchive';
    }
    return 'Activate';
  };

  isDropDownVisible = () => {
    return this.state.isAuthenticated && (this.state.isArchived || !this.state.isDraft);
  };

  needsActivation = () => {
    return !this.state.isArchived && (this.state.isDraft || !this.state.isAuthenticated);
  };

  mainButtonAction = async () => {
    if (this.needsActivation()) {
      await this.authenticateService();
    } else if (this.state.isChanged && this.isValid()) {
      await this.props.sdk.cma.entry
        .get({ entryId: this.props.sdk.entry.getSys().id })
        .then((entry) => {
          this.props.sdk.cma.entry.publish({ entryId: entry.sys.id }, entry);
        });
    } else if (this.state.isArchived) {
      await this.props.sdk.cma.entry.unarchive({ entryId: this.props.sdk.entry.getSys().id });
    }
  };

  getEntryData = () => {
    return {
      sys: {
        id: this.props.sdk.entry.getSys().id,
        version: this.props.sdk.entry.getSys().version,
      },
    };
  };

  closeDropDown = () => {
    this.setState(() => {
      return { isOpen: false };
    });
  };

  loading = (loading: boolean) => {
    this.setState({ loading });
  };

  render() {
    return (
      <div className="sidebar-container">
        <SectionHeading>
          Status
          <span className={'status ' + this.getStatus()}>{this.getStatus(true)}</span>
        </SectionHeading>
        <hr className="faded" />
        {
          <Flex style={{ marginBottom: '20px' }}>
            {(!this.state.isPublished || !this.state.isAuthenticated) && (
              <Button
                // @ts-ignore
                variant={this.getButtonType()}
                className={this.getButtonClass()}
                isDisabled={this.state.isPublished && this.state.isAuthenticated}
                href=""
                isLoading={this.state.loading}
                testId="cf-ui-button"
                onClick={() => {
                  this.mainButtonAction();
                }}
              >
                {this.getButtonLabel()}
              </Button>
            )}
            {this.isDropDownVisible() && (
              <Menu
                isAutoalignmentEnabled={true}
                isOpen={this.state.isOpen}
                onClose={() => this.closeDropDown()}
                key={Date.now()} // Force Reinit
              >
                <Menu.Trigger>
                  <IconButton
                    // @ts-ignore
                    variant={this.getButtonType()}
                    className={
                      this.state.isPublished ? 'translator-button' : 'translator-button-20'
                    }
                    isDisabled={false}
                    icon={<ChevronDownIcon />}
                    indicateDropdown
                    isLoading={this.state.loading}
                    testId="cf-ui-button"
                    onClick={() =>
                      this.setState((state: any) => {
                        return { isOpen: !state.isOpen };
                      })
                    }
                  >
                    {this.state.isPublished ? this.getButtonLabel() : ' '}
                  </IconButton>
                </Menu.Trigger>
                <Menu.List>
                  {this.isActive() && (
                    <Menu.Item
                      onClick={async () => {
                        this.setState(
                          {
                            loading: true,
                          },
                          async () => {
                            this.closeDropDown();
                            await this.updateTranslatorInfo(false);
                            await this.props.sdk.cma.entry.unpublish({
                              entryId: this.props.sdk.entry.getSys().id,
                            });
                            this.loading(false);
                          },
                        );
                      }}
                    >
                      Inactive
                    </Menu.Item>
                  )}
                  {this.isActive() && (
                    <Menu.Item
                      onClick={async () => {
                        this.setState(
                          {
                            loading: true,
                          },
                          async () => {
                            this.closeDropDown();
                            let entry = await this.props.sdk.cma.entry.unpublish({
                              entryId: this.props.sdk.entry.getSys().id,
                            });
                            await this.updateTranslatorInfo(false);
                            await this.props.sdk.cma.entry.archive({ entryId: entry.sys.id });
                            this.loading(false);
                          },
                        );
                      }}
                    >
                      Archive
                    </Menu.Item>
                  )}
                  {this.state.isArchived && (
                    <Menu.Item
                      onClick={async () => {
                        this.setState(
                          {
                            loading: true,
                          },
                          async () => {
                            this.closeDropDown();
                            let entry = await this.props.sdk.cma.entry.unarchive({
                              entryId: this.getEntryData().sys.id,
                            });
                            await this.props.sdk.cma.entry.publish(
                              { entryId: entry.sys.id },
                              entry,
                            );
                            await this.updateTranslatorInfo(true);
                            this.loading(false);
                          },
                        );
                      }}
                    >
                      Active
                    </Menu.Item>
                  )}
                </Menu.List>
              </Menu>
            )}
          </Flex>
        }
        <LinkedTranslationProjects sdk={this.props.sdk} />
      </div>
    );
  }
}
