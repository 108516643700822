import React from 'react';
import { Box, Flex } from '@contentful/f36-components';

interface Tab {
  id: string;
  label: string;
  isDisabled: boolean;
  icon?: JSX.Element;
  content: JSX.Element;
  class?: string;
}

interface Props {
  tabs: Tab[];
  activeTab: string;
}

interface State {
  activeTab: string;
}

export default class Tabs extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = { activeTab: props.activeTab };
  }

  onTabChange(tab: string): void {
    this.setState({ activeTab: tab });
  }

  render() {
    return (
      <div className="tab-container">
        <div className="editor-tabs">
          <Flex flexDirection="row" className="tab-box">
            <Box padding="spacingS" display="inline-flex">
              {this.props.tabs.map((tab: Tab) => (
                <Flex
                  onClick={tab.isDisabled ? () => {} : () => this.onTabChange(tab.id)}
                  className={`align-center-tab ${this.state.activeTab === tab.id ? 'active' : ''} ${tab.isDisabled ? 'disabled' : ''}`}
                >
                  {tab.icon && (
                    <>
                      {tab.icon} {'  '}
                    </>
                  )}{' '}
                  {tab.label}
                </Flex>
              ))}
            </Box>
          </Flex>
        </div>
        {this.props.tabs.map(
          (tab: Tab) =>
            !tab.isDisabled &&
            tab.id === this.state.activeTab && (
              <div className={`tab-content ${tab.class ? tab.class : ''}`}>{tab.content}</div>
            ),
        )}
      </div>
    );
  }
}
