import React from 'react';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import { Paragraph } from '@contentful/f36-components';
import { LinkIcon as Icon } from '@contentful/f36-icons';
interface Props {
  translationInfo: any;
  entryId: any;
  sdk: EditorExtensionSDK;
}
import loGet from 'lodash.get';

export default class LinkIcon extends React.Component<Props> {
  render() {
    const { sdk, translationInfo, entryId } = this.props;
    if (sdk.entry.fields.localizedReferences.getValue() === true) {
      const referenceFieldMap = loGet(translationInfo, 'entry.referenceFieldMap', []);
      if (referenceFieldMap[entryId]) {
        return (
          <div className={'link-holder'}>
            <Icon variant={'white'} />
            <Paragraph>{referenceFieldMap[entryId].length}</Paragraph>
          </div>
        );
      }
    }
    return '';
  }
}
