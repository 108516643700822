import React from 'react';
import { PageExtensionSDK } from '../../../extensions-sdk';
import InsightBox from './InsightBox';

interface Props {
  sdk: PageExtensionSDK;
  divider: boolean;
}

export default class TotalLanguages extends React.Component<Props> {
  render() {
    const { sdk } = this.props;
    const languages = sdk.locales.available.length + '';
    return <InsightBox stat={languages} text={'Languages'} divider={this.props.divider} />;
  }
}
