const requiredKeys = [
  "source",
  "target",
  "entryId",
  "contentType",
  "nameForFile",
  "content",
  "type",
];

interface ValidationResponse {
  isValid: boolean;
  message: string;
}

let allowedTypes = ["Asset", "Entry"];

const isValidEntry = (obj: { [any: string]: any }): ValidationResponse => {
  for (let key of requiredKeys) {
    if (obj[key] == undefined) {
      return { message: `Required key ${key} is missing`, isValid: false };
    }
    if (!allowedTypes.includes(obj["type"])) {
      return {
        message: `"type" can only have 1 of following values: ${allowedTypes.join(
          ", ",
        )}`,
        isValid: false,
      };
    }
  }
  return { message: `Valid`, isValid: true };
};

export default isValidEntry;
