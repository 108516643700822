import React from 'react';
import { SectionHeading, Card, Flex, Text } from '@contentful/f36-components';
import {
  getPerStatusProjectInfo,
  getStatusColor,
  STATUS_FAILED,
  STATUS_IN_PROGRESS,
  STATUS_NEW,
  STATUS_OUT_OF_DATE,
  STATUS_PUBLISHED,
  STATUS_READY_PUBLISH,
  STATUS_READY_REVIEW,
} from '../../../config/translationModel';

interface Props {
  translationInfo: any;
  entryContentStore: any;
}

export default class SidebarEntryStatuses extends React.Component<Props> {
  getCount = (projectStatus: any, status: string) => {
    return projectStatus[status]?.count || 0;
  };

  render() {
    const projectStatus = getPerStatusProjectInfo(
      this.props.translationInfo,
      this.props.entryContentStore,
    );

    const statuses = [
      { label: STATUS_NEW, color: getStatusColor(STATUS_NEW), count: this.getCount(projectStatus, STATUS_NEW) },
      { label: STATUS_IN_PROGRESS, color: getStatusColor(STATUS_IN_PROGRESS), count: this.getCount(projectStatus, STATUS_IN_PROGRESS) },
      { label: STATUS_READY_REVIEW, color: getStatusColor(STATUS_READY_REVIEW), count: this.getCount(projectStatus, STATUS_READY_REVIEW) },
      { label: STATUS_READY_PUBLISH, color: getStatusColor(STATUS_READY_PUBLISH), count: this.getCount(projectStatus, STATUS_READY_PUBLISH) },
      { label: STATUS_PUBLISHED, color: getStatusColor(STATUS_PUBLISHED), count: this.getCount(projectStatus, STATUS_PUBLISHED) },
      { label: STATUS_FAILED, color: getStatusColor(STATUS_FAILED), count: this.getCount(projectStatus, STATUS_FAILED) },
    ];

    return (
      <>
        <SectionHeading className="mt-2">File statuses</SectionHeading>
        <hr className="faded" />
        <Card style={{ width: 'auto', padding: '1rem' }}>
          {statuses.map((status, index) => (
            <Flex
              key={index}
              alignItems="center"
            >
              <Flex marginRight="spacingXs">
                <span className="dot" style={{ backgroundColor: status.color, borderRadius: '50%', display: 'block', width: '10px', height: '10px' }} />
              </Flex>
              <Flex flexGrow={1} marginRight="spacingXs">
                <Text>{status.label}</Text>
              </Flex>
              <Flex>
                <Text>{status.count}</Text>
              </Flex>
            </Flex>
          ))}
        </Card>
      </>
    );
  }
}
