import React from 'react';
import { Tooltip } from '@contentful/f36-components';
import { WarningIcon, ChatBubbleIcon } from '@contentful/f36-icons';
import loGet from 'lodash.get';
import { OPTION_ACCLARO } from '../../../config/translatorModel';
import { getEntryId } from '../../../utils/helpers';

interface Props {
  entry: any;
  translationInfo: any;
  entryContentStore: any;
}

export default class StatusIcons extends React.Component<Props> {
  outDatedEntry() {
    const outdatedEntries = this.props.translationInfo.outdatedEntries || {};
    const type = loGet(this.props, 'translationInfo.entry.type');
    let message = '';
    if (outdatedEntries[getEntryId(this.props.entry)] === 1) {
      if (type === OPTION_ACCLARO) {
        message =
          'The source content for this file has changed, once the project is updated, source content changes will be sent to Acclaro.';
      } else {
        message =
          'The source content for this file has changed, you can re-download the file after updating the project and send it to your translation service provider.';
      }
      return (
        <Tooltip content={message}>
          <WarningIcon variant={'muted'} className={'tooltip-icon flex-cell'}></WarningIcon>
        </Tooltip>
      );
    }
    return '';
  }
  entryWithFeedback() {
    const translatedEntries = loGet(this.props, 'entryContentStore.translatedEntries', []);
    const type = loGet(this.props, 'translationInfo.entry.type');
    const translatedEntry = translatedEntries.find((translatedEntry: any) => {
      return (
        translatedEntry.entryId == this.props.entry.entryId &&
        translatedEntry.target == this.props.entry.target
      );
    });
    let message = '';
    if (translatedEntry && translatedEntry.hasFeedback) {
      if (type === OPTION_ACCLARO) {
        message =
          'Feedback Requested. To review your feedback, open the Acclaro order and navigate to the "Comments" tab.';
      } else {
        message = 'A feedback was added to this entry.';
      }
      return (
        <Tooltip content={message}>
          <ChatBubbleIcon variant={'muted'} className={'tooltip-icon'}></ChatBubbleIcon>
        </Tooltip>
      );
    }
    return '';
  }

  render() {
    return (
      <>
        {this.outDatedEntry()}
        {this.entryWithFeedback()}
      </>
    );
  }
}
