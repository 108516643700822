import React from 'react';
import { FieldExtensionSDK } from '../extensions-sdk';
import ContentTypeField from '../components/translation/fields/ContentTypeField';
import TargetLanguageField from '../components/translation/fields/TargetLanguageField';

interface Props {
  sdk: FieldExtensionSDK;
}

let fieldComponents: any = {
  contentType: ContentTypeField,
  targetLanguages: TargetLanguageField,
};

export default class Sidebar extends React.Component<Props> {
  async componentDidMount() {
    await this.handleMount();
  }

  handleMount = async () => {};

  render() {
    let FieldComponent = fieldComponents[this.props.sdk.field.id];
    return (
      <>
        <FieldComponent sdk={this.props.sdk} />
      </>
    );
  }
}
