import React, { ChangeEvent } from 'react';
import {
  Accordion,
  AccordionItem,
  Select,
  Option,
  Switch,
  Heading,
  Paragraph,
  TextInput,
  List,
  ListItem,
  FormControl,
} from '@contentful/f36-components';
import { AppExtensionSDK } from '../extensions-sdk';
import { Settings } from './AppConfig';
import HubspotForm from '../components/common/HubspotForm';
import loGet from 'lodash.get';
import { OPTION_ACCLARO, OPTION_LOCAL } from '../config/translatorModel';
import { decryptApiKey } from '../utils/helpers';
import Version from '../components/common/Version';
import { localizationMethods } from '../config/translationModel';

let showCommitID = true;
if (window.location.hostname == 'ctf-translations.acclaro-apps.com') {
  showCommitID = false;
}
interface Props {
  parameters: Settings;
  handleParameterChange: Function;
  sdk: AppExtensionSDK;
}
interface State {
  translators: any;
}
export default class ConfigurationScreen extends React.Component<Props, State> {
  state: State = {
    translators: [],
  };

  async componentDidMount() {
    this.handleMount();
  }

  handleMount = async () => {
    let entries = await this.props.sdk.cma.entry.getMany({
      query: {
        content_type: this.props.parameters.translatorModelId,
      },
    });
    let items = entries.items;
    let translators: object[] = [];

    let defaultLocale = this.props.sdk.locales.default;
    items.forEach((item: any) => {
      translators.push({
        id: item.sys.id,
        name: loGet(item, 'fields.name.' + defaultLocale),
        service: loGet(item, 'fields.translationService.' + defaultLocale),
        apiKey: decryptApiKey(loGet(item, 'fields.apiKey.' + defaultLocale)),
        sandbox: loGet(item, 'fields.sandbox.' + defaultLocale),
      });
    });

    this.setState(
      {
        translators,
      },
      () => {
        if (this.props.parameters.defaultTranslator != '') {
          this.handleTranslatorChange(this.props.parameters.defaultTranslator);
        }
      },
    );
  };

  handleTranslatorChange = (defaultTranslator: string) => {
    let selectedTranslator: any =
      this.state.translators.find((translator: any) => translator.id == defaultTranslator) || {};
    let apiKey = selectedTranslator.apiKey;
    let sandbox = selectedTranslator.sandbox;
    this.props.handleParameterChange({
      defaultTranslator,
      apiKey,
      sandbox,
    });
  };

  render() {
    const { parameters, handleParameterChange } = this.props;

    const translatorOptions = this.state.translators.map((translator: any) => (
      <Option value={translator.id} key={translator.id}>
        {translator.name}
      </Option>
    ));
    const localizationOptions = localizationMethods.map((option: any) => (
      <Option value={option.id} key={option.id}>
        {option.name}
      </Option>
    ));
    let selectedTranslator = this.state.translators.find(
      (translator: any) => translator.id == this.props.parameters.defaultTranslator,
    ) || { service: '' };
    return (
      <div className="app">
        <div className="background">
          <div className="body">
            <div className="config setup" style={{ marginBottom: '5%' }}>
              <div className="section">
                <Heading>Configuration settings</Heading>
                <Paragraph className="about-p">
                  Set a default translator for your Translation projects.
                </Paragraph>
                <div className="getting-started">
                  <FormControl isRequired>
                    <FormControl.Label>Default translator</FormControl.Label>
                    <Select
                      name="default-translator"
                      id="default-translator"
                      value={parameters.defaultTranslator}
                      //@ts-ignore
                      onChange={(e) =>
                        //@ts-ignore
                        this.handleTranslatorChange(e.target.value)
                      }
                    >
                      <Select.Option value="">Select an option</Select.Option>
                      {translatorOptions}
                    </Select>
                  </FormControl>
                </div>
                {selectedTranslator.service != OPTION_LOCAL && selectedTranslator.service != '' ? (
                  <div>
                    <FormControl>
                      <FormControl.Label>API key</FormControl.Label>
                      <TextInput
                        className="ts-text-field"
                        name="api-key"
                        id="api-key"
                        value={parameters.apiKey || ''}
                        //@ts-ignore
                        onChange={(e) =>
                          handleParameterChange({ apiKey: e.target.value as string })
                        }
                      />
                      <FormControl.HelpText>
                        Use the API key for the selected provider
                      </FormControl.HelpText>
                    </FormControl>
                    {selectedTranslator.service == OPTION_ACCLARO ? (
                      <FormControl style={{ display: 'flex' }}>
                        <Switch
                          id="sandbox"
                          className="sandbox"
                          isChecked={parameters.sandbox}
                          onChange={() => handleParameterChange({ sandbox: !parameters.sandbox })}
                        />
                        <FormControl.Label style={{ paddingLeft: "10px" }}>Sandbox?</FormControl.Label>
                      </FormControl>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
                <FormControl isRequired>
                  <FormControl.Label>Default Localization Method</FormControl.Label>
                  <Select
                    name="default-localization-method"
                    id="default-localization-method"
                    value={parameters.defaultLocalizationMethod}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                      handleParameterChange({ defaultLocalizationMethod: e.target.value })
                    }
                  >
                    <Select.Option value="">Select an option</Select.Option>
                    {localizationOptions}
                  </Select>
                </FormControl>
                <hr />
              </div>
              <div className="section">
                <Heading>Uninstallation and cleanup</Heading>
                <Paragraph>
                  If you uninstall the Translations app you will have to manually clean up the
                  content type we created for you during the installation. To do that follow the
                  next steps:
                </Paragraph>
                <List>
                  <ListItem>Go to the &quot;content&quot; page</ListItem>
                  <ListItem>
                    Delete any entries of type &quot;Translation project&quot; and
                    &quot;Translators&quot; (or the names you chose during installation)
                  </ListItem>
                  <ListItem>Go to the &quot;content model&quot; page</ListItem>
                  <ListItem>
                    Edit the content types the app created, select &quot;actions&quot; from the top
                    menu and &quot;delete&quot;
                  </ListItem>
                </List>
                <hr />
              </div>
              <div className="section">
                <Heading>About Translations </Heading>
                <Version />
                <Paragraph className="about-p">
                  This plugin is made available by <a href="https://www.acclaro.com/">Acclaro</a>.
                  Please fill out the "Request Translation support" form below if you need help
                  getting set up or are interested in Acclaro support services.
                </Paragraph>
                <Paragraph className="about-p">
                  Translations for Contentful enables you to use your preferred translation provider
                  to manage your organization's global content and shorten your time to market
                  through streamlined workflows and key localization insights.
                </Paragraph>
                <Paragraph className="about-p">
                  No provider? No problem. Acclaro offers world-class professional translation and
                  localization services to help unify your brand message across cultures.{' '}
                  <a href="https://www.acclaro.com/translation-services-cost/">Request a quote</a>.
                </Paragraph>
              </div>
              <div className="section">
                <Accordion align="end" testId="cf-ui-accordion">
                  <AccordionItem
                    testId="cf-ui-accordion-item"
                    title="Request Translation support"
                    titleElement="h3"
                  >
                    <HubspotForm />
                  </AccordionItem>
                </Accordion>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
