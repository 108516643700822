import React from 'react';
import { Card } from '@contentful/f36-components';
import Tabs from '../../translation/parts/Tabs';
import { PageExtensionSDK } from '../../../extensions-sdk';
import LanguageCoverage from './LanguageCoverage';
import TranslatorCoverage from './TranslatorCoverage';

interface Props {
  sdk: PageExtensionSDK;
}

export default class Coverage extends React.Component<Props> {
  render() {
    const tabs = [
      {
        id: 'lang',
        label: 'By Language',
        isDisabled: false,
        content: <LanguageCoverage sdk={this.props.sdk} />,
      },
      {
        id: 'translator',
        label: 'By Translator',
        isDisabled: false,
        content: <TranslatorCoverage sdk={this.props.sdk} />,
      },
    ];
    return (
      <Card style={{ marginTop: '2em' }}>
        <h3>Translation Coverage</h3>
        <Tabs activeTab={'lang'} tabs={tabs}></Tabs>
      </Card>
    );
  }
}
