import Field, {
  FIELD_ARRAY,
  FIELD_BOOLEAN,
  FIELD_DATE,
  FIELD_INTEGER,
  FIELD_LINK,
  FIELD_LOCATION,
  FIELD_NUMBER,
  FIELD_OBJECT,
  FIELD_RICHTEXT,
  FIELD_SYMBOL,
  FIELD_TEXT,
  FieldConfig,
} from "./field";
import ArrayField from "./array.field";
import BooleanField from "./boolean.field";
import DateField from "./date.field";
import RichTextField from "./richtext.field";
import LocationField from "./location.field";
import ObjectField from "./object.field";
import TextField from "./text.field";
import IntegerField from "./integer.field";
import LinkField from "./link.field";
import NumberField from "./number.field";
import SymbolField from "./symbol.field";

export default class FieldFactory {
  public static makeField(fieldType: string, config: FieldConfig): Field {
    switch (fieldType) {
      case FIELD_ARRAY:
        return new ArrayField(config);
      case FIELD_BOOLEAN:
        return new BooleanField(config);
      case FIELD_DATE:
        return new DateField(config);
      case FIELD_INTEGER:
        return new IntegerField(config);
      case FIELD_LINK:
        return new LinkField(config);
      case FIELD_LOCATION:
        return new LocationField(config);
      case FIELD_NUMBER:
        return new NumberField(config);
      case FIELD_OBJECT:
        return new ObjectField(config);
      case FIELD_RICHTEXT:
        return new RichTextField(config);
      case FIELD_SYMBOL:
        return new SymbolField(config);
      case FIELD_TEXT:
        return new TextField(config);
      default:
        console.log("Unknown Field - " + fieldType);
        return new Field(config);
    }
  }
}
