import React from 'react';
import { Tooltip, Switch, FormControl, Flex } from '@contentful/f36-components';
import { HelpCircleIcon } from '@contentful/f36-icons';

interface Props {
  trackChanges: boolean;
  handleFieldChange: Function;
}

export default class TrackChanges extends React.Component<Props> {
  render() {
    return (
      <Flex>
        <FormControl style={{ display: 'inline-flex' }} className="no-margin">
          <Switch
            id={'track-changes-switch'}
            isChecked={this.props.trackChanges}
            onChange={async () => {
              this.props.handleFieldChange('trackChanges', !this.props.trackChanges);
            }}
          />{' '}
          <FormControl.Label style={{ paddingRight: '5px', paddingLeft: '10px' }}>Track Changes</FormControl.Label>
          <Tooltip
            content="Enable to track content updates for selected media asset source. (Recommended for
                continuous translation workflows)"
          >
            <HelpCircleIcon className={'tooltip-icon'} variant={'muted'} />
          </Tooltip>
        </FormControl>
      </Flex>
    );
  }
}
