import localClient from './localClient';
import { EntryContentStore } from '../../../lambda/src/utils/data-manager/data.manager';

const LZUTF8 = require('lzutf8-light');

export default class DataManager {
  /**
   * check if the data should be fetched back from the server
   */
  protected static fetchRequired: { [key: string]: boolean } = {};

  static cachedData: { [key: string]: EntryContentStore } = {};

  /**
   * Get Entry data from the server.
   * Only fetches fresh data if required.
   */
  public static async get(entryId: string) {
    if (DataManager.fetchRequired[entryId] === undefined || DataManager.fetchRequired[entryId]) {
      let response = await localClient.getData(entryId);
      DataManager.cachedData[entryId] = response.data;
      DataManager.fetchRequired[entryId] = false;
    }
    if (DataManager.cachedData[entryId]) {
      let decompresesed = LZUTF8.decompress(DataManager.cachedData[entryId], {
        inputEncoding: 'StorageBinaryString',
      });
      return JSON.parse(decompresesed);
    }
    return {};
  }

  public static async store(entryId: string, data: any) {
    data = LZUTF8.compress(JSON.stringify(data), { outputEncoding: 'StorageBinaryString' });
    await localClient.storeData({ entryId, data });
    DataManager.markUpdated(entryId);
  }

  public static markUpdated(entryId: string) {
    DataManager.fetchRequired[entryId] = true;
  }

  public static async storeAndRefreshTranslationInfo(sdk: any, data: any) {
    let entryId: string | null = null;
    if (sdk.entry && (entryId = sdk.entry.getSys().id)) {
      await DataManager.store(entryId, data);
      await sdk.entry.fields.translationInfo.setValue(sdk.entry.fields.translationInfo.getValue());
    }
  }
}
