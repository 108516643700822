import React from 'react';
import { EditorExtensionSDK } from '../../extensions-sdk';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  TextLink,
  Badge,
  SkeletonRow,
} from '@contentful/f36-components';
import { ArrowDownIcon, ArrowUpIcon } from '@contentful/f36-icons';
import loGet from 'lodash.get';
import loOrderBy from 'lodash.orderby';
import DetailsToolbar from './parts/DetailsToolbar';
import {
  getEntryId,
  getSelections,
  slugify,
  getContentTypes,
  getFullEntities,
  isTypeEntry,
} from '../../utils/helpers';
import {
  getStatusColor,
  STATUS_READY_REVIEW,
  STATUS_PUBLISHED,
  STATUS_NEW,
  STATUS_READY_PUBLISH,
  getStatusBackgroundColor,
} from '../../config/translationModel';
import { getDisplayField, requestChanges, updateEntry } from '../../utils/contentful';
import MoreButton from './parts/MoreButton';
import LinkIcon from './parts/LinkIcon';
import { dateTimeFormat } from '../../../../lambda/src/utils/helpers';
import StatusIcons from './parts/StatusIcons';
import BodyMessage from './parts/BodyMessage';
import DataManager from '../../utils/data.manager';
import { EntryContentStore } from '../../../../lambda/src/utils/data-manager/data.manager';
import ReactPaginate from 'react-paginate';
import { isMediaAsset, isMediaLinkType } from '../../utils/assetHelpers';

interface Props {
  sdk: EditorExtensionSDK;
}

interface ModalProperties {
  isShown: boolean;
  [key: string]: any;
}

interface State {
  translationInfo: any;
  selectedEntries: string[];
  modalData: ModalProperties;
  /**
   * entries on current page(pagination)
   */
  entries: any[];
  selectedLanguage: string;
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  /**
   *  entries with information about fields
   */
  fullEntries: any;
  /**
   * Total entries that this project contains filtered by language
   */
  filteredEntries: any;
  allContentTypes: any;
  moreButtonPressedOn: string;
  loading: boolean;
  entryContentStore: EntryContentStore;
  currentPage: number;
  pageCount: number;
}

export default class Details extends React.Component<Props, State> {
  state: State = {
    translationInfo: {},
    selectedEntries: [],
    modalData: { isShown: false },
    entries: [],
    selectedLanguage: '',
    sortBy: 'status',
    sortDirection: 'asc',
    fullEntries: [],
    allContentTypes: [],
    moreButtonPressedOn: '',
    loading: false,
    entryContentStore: {},
    currentPage: 0,
    pageCount: 0,
    filteredEntries: [],
  };
  timeout: any = null;
  perPage = 100;

  constructor(props: Readonly<Props>) {
    super(props);
  }

  async componentDidMount() {
    await this.handleMount();
  }

  handleMount = async () => {
    this.props.sdk.entry.fields.translationInfo.onValueChanged(this.handleTranslationInfoChange);
    this.updateEntryContentStore();
  };

  handleTranslationInfoChange = async (translationInfo: any) => {
    if (translationInfo) {
      this.setState(
        {
          translationInfo,
        },
        () => {
          this.updateEntryContentStore();
        },
      );
    }
  };

  updateEntryTitles = async () => {
    const getIds = (callback: Function) => {
      return this.state.entries
        .filter((sEntry) => {
          const { type } = sEntry;
          return callback(type);
        })
        .map((sEntry: { [k: string]: any }) => {
          return sEntry.entryId;
        });
    };
    const entryIds = getIds(isTypeEntry);
    const assetIds = getIds(isMediaLinkType);
    const entityIdsList = { entryIds: entryIds, assetIds: assetIds };

    const fullEntries = await getFullEntities(this.props.sdk, entityIdsList);

    const allContentTypes = await getContentTypes(this.props.sdk);
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      fullEntries,
      allContentTypes,
    });
  };

  updateSelectedEntries = (value: string, all = false) => {
    this.setState((state) => {
      const entries = state.entries.filter((entry: any) => {
        return this.entrySelectable(entry);
      });
      return {
        selectedEntries: getSelections(state.selectedEntries, entries, value, all),
      };
    });
  };

  handlePageClick = (data: any) => {
    this.setState({ currentPage: data.selected }, () => {
      this.updateEntries();
    });
  };

  /**
   * Fetch the entries from store(backend)
   */
  updateEntryContentStore = async () => {
    this.startLoading();
    let entry = this.props.sdk.entry.getSys().id;
    DataManager.markUpdated(entry);
    let entryContentStore = await DataManager.get(entry);
    this.setState(
      {
        entryContentStore,
      },
      () => {
        this.updateFilteredEntries();
        this.endLoading();
      },
    );
  };

  /**
   * Set the entries based on the selected language and sorting
   */
  updateFilteredEntries = async () => {
    const translatedEntries = loGet(this.state.entryContentStore, 'translatedEntries', []);
    const fileData = loGet(this.state.entryContentStore, 'fileData', []);
    let filteredEntries = [];
    for (const file of fileData) {
      if (!this.state.selectedLanguage || this.state.selectedLanguage == file.target) {
        const translatedEntry = translatedEntries.find((transEntry: any) => {
          return transEntry.entryId == file.entryId && transEntry.target == file.target;
        });
        if (translatedEntry) {
          filteredEntries.push({
            ...translatedEntry,
            status: translatedEntry.status || STATUS_READY_REVIEW,
          });
        } else {
          filteredEntries.push({
            ...file,
            status: file.status || STATUS_NEW,
          });
        }
      }
    }
    if (this.state.sortBy) {
      filteredEntries = loOrderBy(filteredEntries, this.state.sortBy, this.state.sortDirection);
    }
    this.setState(
      {
        filteredEntries,
        pageCount: Math.ceil(filteredEntries.length / this.perPage),
      },
      async () => {
        await this.updateEntries();
      },
    );
  };

  /**
   * Check if any entries that were in ready to publish status were updated outside of translation project and published
   * If so update the status of the entry on the translation project
   */
  checkStatusUpdates = () => {
    let { entryContentStore } = this.state;
    let entriesWithStatusReadyPublish = (entryContentStore?.translatedEntries || []).filter(
      (entry: { [k: string]: any }) => {
        return entry.status == STATUS_READY_PUBLISH;
      },
    );
    let updateEntryContentStore: boolean = false;
    entriesWithStatusReadyPublish.forEach((entry: any) => {
      let fullEntry: any = this.state.fullEntries.find((fullEntry: any) => {
        return fullEntry.sys.id === entry.entryId;
      });
      if (fullEntry?.sys?.publishedAt && entry?.status_updated_at) {
        let entryPublishedAt = new Date(fullEntry.sys.publishedAt).getTime();
        let entryStatusUpdatedAt = new Date(entry.status_updated_at).getTime();
        let indexes: number[] = [];
        if (entryPublishedAt > entryStatusUpdatedAt) {
          updateEntryContentStore = true;
          // @ts-ignore Get all indexes from entryContentStore.translatedEntries with id as fullEntry.sys.id
          indexes = entryContentStore.translatedEntries.reduce(
            (array: any, entry: any, index: number) => {
              if (entry.entryId === fullEntry.sys.id) array.push(index);
              return array;
            },
            [],
          );
        }
        // Update translatedEntries in entryContentStore with status as Published
        if (indexes.length > 0) {
          indexes.forEach((index: any) => {
            // @ts-ignore
            entryContentStore.translatedEntries[index].status = STATUS_PUBLISHED;
          });
        }
      }
    });
    if (updateEntryContentStore) {
      DataManager.store(this.props.sdk.entry.getSys().id, entryContentStore);
      this.updateEntryContentStore();
    }
  };

  /**
   * Keep the entries based on the page number user is currently on.
   */
  updateEntries = async () => {
    let start = this.state.currentPage * this.perPage;
    let end = start + this.perPage;
    let entries = this.state.filteredEntries.filter((value: any, index: any) => {
      return index >= start && index < end;
    });
    this.setState(
      {
        entries,
      },
      async () => {
        await this.updateEntryTitles();
        await this.checkStatusUpdates();
      },
    );
  };

  getContentType = () => {
    return loGet(this.state, 'translationInfo.entry.contentType', {});
  };

  handleSelectedLanguage = (language: string) => {
    this.setState(
      {
        selectedLanguage: language,
        currentPage: 0,
      },
      async () => {
        this.updateFilteredEntries();
      },
    );
  };

  sortByColumn = (column: string) => {
    this.setState(
      (state) => {
        return {
          sortBy: column,
          sortDirection:
            state.sortBy == column ? (state.sortDirection == 'asc' ? 'desc' : 'asc') : 'asc',
        };
      },
      async () => {
        // eslint-disable-next-line react/no-access-state-in-setstate
        this.updateFilteredEntries();
      },
    );
  };

  getSortIcon = () => {
    return this.state.sortDirection == 'asc' ? (
      <ArrowDownIcon variant={'muted'} />
    ) : (
      <ArrowUpIcon variant={'muted'} />
    );
  };

  entrySelectable = (entry: any) => {
    return [STATUS_READY_REVIEW, STATUS_READY_PUBLISH].includes(entry.status);
  };

  startLoading = () => {
    this.setState({
      loading: true,
    });
  };

  endLoading = () => {
    this.setState({
      loading: false,
    });
  };

  updateEntry = async (localSelectedEntries: any[], publish = false) => {
    const { sdk } = this.props;
    const { allContentTypes, entries } = this.state;
    this.startLoading();
    await updateEntry(localSelectedEntries, entries, sdk, allContentTypes, publish);
    this.endLoading();
  };

  publishEntry = async (localSelectedEntries: any[]) => {
    await this.updateEntry(localSelectedEntries, true);
  };

  requestChanges = async (entryId: string) => {
    const { sdk } = this.props;
    await requestChanges(entryId, sdk);
    this.updateMoreButton();
  };

  updateMoreButton = (id = '') => {
    this.setState({ moreButtonPressedOn: id });
  };

  render() {
    const { entries, selectedEntries } = this.state;

    const selectableEntryCount = entries.reduce((acc: number, entry: any) => {
      return this.entrySelectable(entry) ? acc + 1 : acc;
    }, 0);

    const submittingToAcclaro = loGet(
      this.state,
      'translationInfo.acclaroStatus.submitting',
      false,
    );

    const languageNames = this.props.sdk.locales.names;
    return (
      <div className="app">
        <div className="body no-shadow editor">
          <div className="config no-padding">
            <div className="section">
              {submittingToAcclaro && (
                <BodyMessage
                  showNice={true}
                  title={'Your project has been submitted to Acclaro!'}
                  message={
                    "We're still processing your project files. You can navigate away from this page or try refreshing for the latest updates"
                  }
                />
              )}
              {!submittingToAcclaro && (
                <>
                  {entries.length > 0 && (
                    <DetailsToolbar
                      sdk={this.props.sdk}
                      handleSelectedLanguage={this.handleSelectedLanguage}
                      entries={this.state.filteredEntries}
                      translationInfo={this.state.translationInfo}
                      entryContentStore={this.state.entryContentStore}
                      selectedEntries={this.state.selectedEntries}
                    />
                  )}
                  {/*
                  // @ts-ignore */}
                  <Table
                    style={{
                      border: '1px solid #c3cfd5',
                      borderRadius: '0px 0px 6px 6px',
                      borderTop: 'none',
                      boxShadow: 'none',
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {selectableEntryCount > 0 && (
                            <Checkbox
                              isChecked={selectedEntries.length == selectableEntryCount}
                              onChange={(evt) => {
                                this.updateSelectedEntries(evt.target.value, true);
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Source</TableCell>
                        {/*@ts-ignore*/}
                        <TableCell>
                          <div
                            className={'flex-cell'}
                            onClick={() => {
                              this.sortByColumn('target');
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <span className={'table-cell-text'}> Target</span>
                            {this.state.sortBy == 'target' ? this.getSortIcon() : undefined}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            className={'flex-cell'}
                            onClick={() => {
                              this.sortByColumn('status');
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <span className={'table-cell-text'}> Status</span>
                            {this.state.sortBy == 'status' ? this.getSortIcon() : undefined}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            className={'flex-cell'}
                            onClick={() => {
                              this.sortByColumn('timeDelivered');
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <span className={'table-cell-text'}>Last Delivered</span>
                            {this.state.sortBy == 'timeDelivered' ? this.getSortIcon() : undefined}
                          </div>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.loading && (
                        <SkeletonRow rowCount={entries.length || 3} columnCount={7} />
                      )}
                      {!this.state.loading && !entries.length && (
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>No data yet</TableCell>
                        </TableRow>
                      )}
                      {!this.state.loading &&
                        entries.length > 0 &&
                        entries.map((entry) => {
                          const fullEntry = this.state.fullEntries.find(
                            (fullEntry: any) => fullEntry.sys.id == entry.entryId,
                          );
                          const entryName = getDisplayField(
                            fullEntry,
                            this.state.allContentTypes,
                            this.props.sdk.locales.default,
                          );
                          const entrySlug = slugify(`${entryName}-${entry.target}`) + '.json';
                          const entryStatus = entry.status || STATUS_NEW;
                          return (
                            <TableRow
                              key={getEntryId(entry)}
                              className={
                                selectedEntries.includes(entry.entryId) ? 'table-row-selected' : ''
                              }
                            >
                              <TableCell>
                                {this.entrySelectable(entry) && (
                                  <Checkbox
                                    value={entry.entryId}
                                    isChecked={selectedEntries.includes(getEntryId(entry))}
                                    onChange={() => {
                                      this.updateSelectedEntries(getEntryId(entry));
                                    }}
                                  />
                                )}
                              </TableCell>

                              <TableCell>
                                <TextLink
                                  className={'detail-entry-name'}
                                  onClick={() => {
                                    isMediaAsset(entry.contentType)
                                      ? this.props.sdk.navigator.openAsset(entry.entryId, {
                                          slideIn: true,
                                        })
                                      : this.props.sdk.navigator.openEntry(entry.entryId, {
                                          slideIn: true,
                                        });
                                  }}
                                >
                                  {entryName}
                                  <LinkIcon
                                    translationInfo={this.state.translationInfo}
                                    entryId={entry.entryId}
                                    sdk={this.props.sdk}
                                  />
                                </TextLink>
                              </TableCell>
                              <TableCell>{languageNames[entry.source]}</TableCell>
                              <TableCell>{languageNames[entry.target]}</TableCell>
                              <TableCell>
                                <div className={'flex-cell'}>
                                  <Badge
                                    style={{
                                      color: getStatusColor(entryStatus),
                                      fontSize: '.65rem',
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                      maxHeight: 'inherit',
                                      backgroundColor: getStatusBackgroundColor(entryStatus),
                                    }}
                                  >
                                    {entryStatus}
                                  </Badge>
                                  <StatusIcons
                                    entry={entry}
                                    translationInfo={this.state.translationInfo}
                                    entryContentStore={this.state.entryContentStore}
                                  />
                                </div>
                              </TableCell>
                              <TableCell>
                                {entry.timeDelivered
                                  ? dateTimeFormat(
                                      entry.timeDelivered,
                                      this.props.sdk.locales.default,
                                    )
                                  : ''}
                              </TableCell>
                              <TableCell>
                                <MoreButton
                                  moreButtonPressedOn={this.state.moreButtonPressedOn}
                                  entryId={getEntryId(entry)}
                                  requestChanges={this.requestChanges}
                                  updateEntry={this.updateEntry}
                                  entryContentStore={this.state.entryContentStore}
                                  publishEntry={this.publishEntry}
                                  updateMoreButton={this.updateMoreButton}
                                  status={entryStatus}
                                  sdk={this.props.sdk}
                                  entrySlug={entrySlug}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                  <ReactPaginate
                    containerClassName={'pagination'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    activeClassName={'active'}
                    forcePage={this.state.currentPage}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
