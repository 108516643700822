import migration from './migration';
import { bugsnag  as Bugsnag } from '../utils/bugsnag';
import { SELECTED_PROGRAM, SELECTED_PROGRAM_OBJECT } from '../config/translationModel';
import { PROGRAM_SELECTION_OBJECT } from '../config/translatorModel';

export default class upgrade1_7_3 extends migration {
  public runMigration = async (): Promise<void> => {
    try {
      this.startUpdateAlert().then(async () => {
        this.updateUser('Checking installation');

        const translationProjectContentTypeId =
          this.sdk?.parameters?.installation?.translationsModelId;
        const translatorContentTypeId = this.sdk?.parameters?.installation?.translatorModelId;

        let translationsProjectContentType = await this.getContentType(
          translationProjectContentTypeId,
        );
        let translatorContentType = await this.sdk.cma.contentType.get({
          contentTypeId: translatorContentTypeId,
        });
        // Adding field to translator content type
        this.updateUser('Adding new field to translator.');
        translatorContentType.fields.push(PROGRAM_SELECTION_OBJECT);
        // Adding field to translation project content type

        // Updating and publishing translator content type
        this.updateUser('Updating and publishing translator content type.');
        await this.sdk.cma.contentType
          .update({ contentTypeId: translatorContentTypeId }, translatorContentType)
          .then(async () => {
            const updatedTranslatorContentModal = await this.sdk.cma.contentType.get({
              contentTypeId: translatorContentTypeId,
            });
            await this.sdk.cma.contentType.publish(
              { contentTypeId: updatedTranslatorContentModal.sys.id },
              updatedTranslatorContentModal,
            );
          })
          .catch((error) => {
            console.error(error);
          });

        // Updating and publishing translator content type
        if (!translationsProjectContentType.fields.find((f: any) => f.id === SELECTED_PROGRAM)) {
          this.updateUser('Adding new field to translation project.');
          translationsProjectContentType.fields.push(SELECTED_PROGRAM_OBJECT);
          this.updateUser('Updating and publishing translation project content type.');
          await this.sdk.cma.contentType
            .update(
              { contentTypeId: translationProjectContentTypeId },
              translationsProjectContentType,
            )
            .then(async () => {
              const updatedTranslationProjectContentModal = await this.sdk.cma.contentType.get({
                contentTypeId: translationProjectContentTypeId,
              });
              await this.sdk.cma.contentType
                .publish(
                  { contentTypeId: updatedTranslationProjectContentModal.sys.id },
                  updatedTranslationProjectContentModal,
                )
            })
            .catch((error) => {
              console.error(error);
            });
        }
        this.updateUser('Update complete. Please refresh the page to continue.');
      });
    } catch (error: any) {
      console.error(error);
      Bugsnag.notify(new Error(error.message));
      this.finishWithErrorAlert();
    }
  };
}
