import * as React from 'react';
import bugsnag from '@bugsnag/browser';
import BugsnagPluginReact from '@bugsnag/plugin-react';

let stage = 'development';

  switch (window.location.hostname) {
    case 'ctf-translations-stage.acclaro-apps.com':
      stage = 'staging';
      break;
    case 'ctf-translations.acclaro-apps.com':
      stage = 'production';
      break;
  }

bugsnag.start({
  apiKey: 'c600f7789a7ce0cfa9eea8eb3d4c7c2a',
  plugins: [new BugsnagPluginReact()],
  releaseStage: stage,
});

// @ts-ignore
export const ErrorBoundary = bugsnag.getPlugin('react').createErrorBoundary(React);

export { bugsnag };
