import React from 'react';
import { ContentType } from 'contentful-ui-extensions-sdk';
import { AppExtensionSDK } from '../extensions-sdk';
import translationModel from '../config/translationModel';
import translatorModel, { translatorOptions } from '../config/translatorModel';
import InstallationScreen from './InstallationScreen';
import ConfigurationScreen from './ConfigurationScreen';
import localClient from '../utils/localClient';
import getAppConfig from '../config/appConfig';
// @ts-ignore 2307
import logo from './logo.svg';
import { encryptApiKey } from '../utils/helpers';

interface Props {
  sdk: AppExtensionSDK;
}
export interface Settings {
  apiKey: string;
  defaultTranslator: string;
  defaultLocalizationMethod: 'field-level' | 'entry-level';
  sandbox: boolean;
  translationsModelId: string;
  translatorModelId: string;
  appLocale: string; // this will keep the locale consistent through the app even if later changed
}
interface State {
  isInstalled: boolean;
  translationTypeId: string;
  translationTypeName: string;
  translatorTypeId: string;
  translatorTypeName: string;
  parameters: Settings;
}

export default class AppConfig extends React.Component<Props, State> {
  state: State = {
    translationTypeId: 'translationProject',
    translationTypeName: 'Translation project',
    translatorTypeId: 'translator',
    translatorTypeName: 'Translator',
    isInstalled: false,
    parameters: {
      apiKey: '',
      defaultTranslator: '',
      defaultLocalizationMethod: 'field-level',
      sandbox: false,
      translationsModelId: '',
      translatorModelId: '',
      appLocale: 'en-US',
    },
  };

  async componentDidMount() {
    const { app } = this.props.sdk;

    await this.handleMount();
    app.onConfigure(this.configure);

    app.onConfigurationCompleted(async (err: any) => {
      if (!err && !this.state.isInstalled) {
        const response = await localClient.setupEditor({
          spaceId: this.props.sdk.ids.space,
          environmentId: this.props.sdk.ids.environment,
          translationId: this.state.parameters.translationsModelId,
          translatorId: this.state.parameters.translatorModelId,
          user: this.props.sdk.user,
        });
        if (!response.success) {
          this.props.sdk.notifier.error(`Failed to setup app. Please try again`);
          return false;
        } else {
          this.setState({ isInstalled: true });
        }
      }
    });
  }

  handleInputChange = (evt: Event) => {
    // @ts-ignore
    evt.persist();
    this.setState((state) => {
      return {
        ...state,
        // @ts-ignore
        ...{ [evt.target.name]: evt.target.value },
      };
    });
  };

  handleParameterChange = (parameter: Record<string, any>) => {
    this.setState((state) => {
      return { parameters: { ...state.parameters, ...parameter } };
    });
  };

  handleMount = async () => {
    const { app } = this.props.sdk;
    const [isInstalled, parameters] = await Promise.all([app.isInstalled(), app.getParameters()]);

    this.setState(
      (state) => {
        return {
          ...state,
          ...{ isInstalled, parameters: { ...state.parameters, ...parameters } },
        };
      },
      () => app.setReady(),
    );
  };

  configure = async () => {
    const { sdk } = this.props;
    const {
      isInstalled,
      translationTypeId,
      translationTypeName,
      translatorTypeId,
      translatorTypeName,
      parameters,
    } = this.state;

    parameters.appLocale = this.props.sdk.locales.default;
    //All of this is part of the installation screen
    if (!isInstalled) {
      let translatorType: ContentType;
      try {
        const data = translatorModel(translatorTypeName, translatorTypeId);

        translatorType = await sdk.cma.contentType.create({ contentTypeId: data.sys.id }, data);
        await sdk.cma.contentType.publish({ contentTypeId: translatorType.sys.id }, translatorType);
        for (const option of translatorOptions) {
          let newEntry = await sdk.cma.entry.create(
            { contentTypeId: translatorType.sys.id },
            {
              fields: {
                name: {
                  [parameters.appLocale]: option,
                },
                translationService: {
                  [parameters.appLocale]: option,
                },
              },
            },
          );
          await sdk.cma.entry.publish({ entryId: newEntry.sys.id }, newEntry);
        }
      } catch (error) {
        sdk.notifier.error(`Failed to create content type translators`);
        return false;
      }

      let translationType: ContentType;
      try {
        const translationData = translationModel(
          translationTypeName,
          translationTypeId,
          translatorType.sys.id,
        );
        translationType = await sdk.cma.contentType.create(
          { contentTypeId: translationData.sys.id },
          translationData,
        );
      } catch (error) {
        sdk.notifier.error(`Failed to create content type translations`);
        return false;
      }

      // Set the newly created content type's state to "Published"
      try {
        await sdk.cma.contentType.publish(
          { contentTypeId: translationType.sys.id },
          translationType,
        );
      } catch (error) {
        sdk.notifier.error(`Failed to publish content type translations`);
        return false;
      }
      parameters.translationsModelId = translationType.sys.id;
      parameters.translatorModelId = translatorType.sys.id;

      return getAppConfig(parameters, parameters.translationsModelId, parameters.translatorModelId);
    }

    /******** Part controlled by configuration screen **/

    let entry: any = await sdk.cma.entry.get({ entryId: parameters.defaultTranslator });
    const response = await localClient.authenticate({
      // @ts-ignore
      service: entry.fields.translationService[parameters.appLocale],
      apiKey: parameters.apiKey,
      sandbox: parameters.sandbox,
    });

    // @ts-ignore
    if (!response.success) {
      sdk.notifier.error(
        `Failed to authenticate. Invalid API key, please contact your service provider`,
      );
      return false;
    }

    const translatorInfo = entry.fields.translatorInfo
      ? entry.fields.translatorInfo[parameters.appLocale]
      : {};
    entry = await sdk.cma.entry.update(
      { entryId: parameters.defaultTranslator },
      {
        sys: entry.sys,
        fields: {
          ...entry.fields,
          ...{
            apiKey: {
              [parameters.appLocale]: encryptApiKey(parameters.apiKey),
            },
            sandbox: {
              [parameters.appLocale]: parameters.sandbox,
            },
            translatorInfo: {
              [parameters.appLocale]: {
                ...translatorInfo,
                isAuthenticated: true,
              },
            },
          },
        },
      },
    );

    await sdk.cma.entry.publish(
      { entryId: parameters.defaultTranslator },
      {
        sys: entry.sys,
        fields: entry.fields,
      },
    );

    return getAppConfig(parameters, parameters.translationsModelId, parameters.translatorModelId);
  };

  render() {
    const {
      isInstalled,
      translationTypeId,
      translationTypeName,
      translatorTypeId,
      translatorTypeName,
      parameters,
    } = this.state;
    return (
      <>
        {!isInstalled && (
          <InstallationScreen
            handleInputChange={this.handleInputChange}
            translationTypeId={translationTypeId}
            translationTypeName={translationTypeName}
            translatorTypeId={translatorTypeId}
            translatorTypeName={translatorTypeName}
          />
        )}
        {isInstalled && (
          <ConfigurationScreen
            parameters={parameters}
            handleParameterChange={this.handleParameterChange}
            sdk={this.props.sdk}
          />
        )}
        <div className="logo">
          <img src={logo} alt="" />
        </div>
      </>
    );
  }
}
