import React from 'react';
import { SectionHeading, TextLink, Badge } from '@contentful/f36-components';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import loGet from 'lodash.get';

interface File {
  wordCount: number;
}

interface Props {
  sdk: EditorExtensionSDK;
  isAcclaro: boolean;
}

interface State {
  translationInfo: any;
  sandbox: boolean;
}

export default class OrderStatus extends React.Component<Props, State> {
  eventHandler: Function = () => {};
  state: State = {
    translationInfo: {},
    sandbox: false,
  };

  componentDidMount() {
    this.eventHandler = this.props.sdk.entry?.fields['translationInfo'].onValueChanged(
      this.updateTranslationInfo,
    );
    this.updateTranslationInfo();
  }

  componentWillUnmount() {
    if (typeof this.eventHandler === 'function') {
      this.eventHandler();
    }
  }

  updateTranslationInfo = async () => {
    const translationInfo = this.props.sdk.entry?.fields.translationInfo.getValue();
    if (translationInfo) {
      const sandbox = loGet(translationInfo, 'entry.sandbox') || false;
      this.setState({
        translationInfo,
        sandbox,
      });
    }
  };

  render() {
    if (!this.props.isAcclaro || !this.state.translationInfo.acclaroStatus) {
      return null;
    }
    const subdomain = this.state.sandbox ? 'apisandbox' : 'my';
    const orderId = loGet(this.state.translationInfo, 'acclaroStatus.orderId');
    const url = `https://${subdomain}.acclaro.com/portal/vieworder.php?id=${orderId}`;
    const submittingToAcclaro = loGet(
      this.state,
      'translationInfo.acclaroStatus.submitting',
      false,
    );
    return (
      <>
        <SectionHeading className="mt-2">
          Acclaro Orders
          <Badge className="entry-status" testId="cf-ui-tag">
            {this.state.translationInfo.acclaroStatus.status}
          </Badge>
        </SectionHeading>
        <hr className="faded" />
        <ul>
          {submittingToAcclaro && (
            <li>
              {orderId}: {this.state.translationInfo.acclaroStatus.name}
            </li>
          )}
          {!submittingToAcclaro && (
            <li>
              <TextLink href={url} target={'_blank'}>
                {orderId}: {this.state.translationInfo.acclaroStatus.name}
              </TextLink>
            </li>
          )}
        </ul>
      </>
    );
  }
}
