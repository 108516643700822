import React, { ChangeEvent } from 'react';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import { Checkbox, FormControl, FormLabel, Note } from '@contentful/f36-components';
import localClient from '../../../utils/localClient';
import loGet from 'lodash.get';

interface Props {
  sdk: EditorExtensionSDK;
  value: string[] | null;
  handleFieldChange: Function;
}

interface State {
  targetLanguages: string[];
  availableLanguages: string[];
  availableLanguagesError: string;
  translator: string;
}

export default class TargetLanguageField extends React.Component<Props, State> {
  detachValueChangeHandler = () => {};
  state: State = {
    targetLanguages: [],
    availableLanguages: [],
    availableLanguagesError: '',
    translator: '',
  };

  constructor(props: Readonly<Props>) {
    super(props);
    this.state.targetLanguages = props.value || [];
    const translator: any = this.props.sdk.entry.fields.translator.getValue();
    this.state.translator = loGet(translator, 'sys.id', '');
    this.state.availableLanguages = this.getAvailableLocales();
  }

  componentDidMount() {
    this.setTranslator();
    this.detachValueChangeHandler = this.props.sdk.entry.fields['translator'].onValueChanged(
      this.handleTranslatorChange,
    );
  }

  componentWillUnmount() {
    this.detachValueChangeHandler();
  }

  handleTranslatorChange = async () => {
    let translator = this.props.sdk.entry.fields.translator.getValue();
    let newId = loGet(translator, 'sys.id');
    if (newId != this.state.translator) {
      this.setTranslator();
    }
  };

  setTranslator = async () => {
    const locale = this.props.sdk.locales.default;
    let translator = this.props.sdk.entry.fields.translator.getValue();
    let translatorId: null;
    if ((translatorId = loGet(translator, 'sys.id'))) {
      translator = await this.props.sdk.cma.entry.get({ entryId: translatorId });
    }
    if (!translatorId) {
      this.setState({
        translator: '',
        availableLanguages: this.getAvailableLocales(),
      });
      return;
    } else {
      this.setState({ translator: translatorId });
    }
    const response: { [k: string]: any } = await localClient.getSupportedLanguages({
      translator: {
        service: loGet(translator, `fields.translationService.${locale}`),
        apiKey: loGet(translator, `fields.apiKey.${locale}`),
        sandbox: loGet(translator, `fields.sandbox.${locale}`),
      },
      locales: this.getAvailableLocales(),
    });
    this.setState({
      availableLanguages: response.success ? response.data.locales : [],
      availableLanguagesError:
        !response.success || this.state.availableLanguages.length === 0
          ? response?.data?.error || 'No languages available'
          : '',
    });
  };

  getAvailableLocales = () => {
    return this.props.sdk.locales.available.filter((language: string) => {
      return language != this.props.sdk.locales.default;
    });
  };

  updateValue = async (value: string, add: boolean) => {
    let languages: any[];
    if (add) {
      languages = [...this.state.targetLanguages, value];
    } else {
      languages = this.state.targetLanguages.filter((lang) => {
        return lang != value;
      });
    }
    this.updateLanguages(languages);
  };

  updateLanguages = async (languages: string[]) => {
    this.setState({ targetLanguages: languages });
    this.props.handleFieldChange('targetLanguages', languages);
  };

  render() {
    const languageNames = this.props.sdk.locales.names;
    return (
      <div>
        <FormControl isRequired>
          <FormLabel htmlFor="targetLanguages">{'Target languages'}</FormLabel>
          {this.state.availableLanguages.length > 0 && (
            <Checkbox
              key={'all'}
              isDisabled={this.state.translator == ''}
              name={'all-langs'}
              value={'all'}
              isChecked={this.state.targetLanguages.length === this.state.availableLanguages.length}
              onChange={() =>
                this.setState(() => {
                  this.updateLanguages(
                    this.state.targetLanguages.length === this.state.availableLanguages.length
                      ? []
                      : this.state.availableLanguages,
                  );
                })
              }
              id={'all-langs'}
            >
              All
            </Checkbox>
          )}
          {this.state.availableLanguages.length > 0 &&
            this.state.availableLanguages.map((lang: string) => {
              return (
                <Checkbox
                  key={lang}
                  isDisabled={this.state.translator == ''}
                  name={languageNames[lang]}
                  value={lang}
                  isChecked={this.state.targetLanguages.includes(lang)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    this.updateValue(e.target.value, e.target.checked)
                  }
                  id={lang}
                >
                  {languageNames[lang]}
                </Checkbox>
              );
            })}
          {this.state.availableLanguagesError.length > 0 && (
            <Note variant={'warning'}> {this.state.availableLanguagesError}</Note>
          )}
        </FormControl>
      </div>
    );
  }
}
