import React from 'react';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import { Autocomplete, FormControl } from '@contentful/f36-components';
import { FIELD_LEVEL } from '../../../config/translationModel';
import { isMediaAsset } from '../../../utils/assetHelpers';
import { getContentTypes } from '../../../../src/utils/helpers';
interface Props {
  sdk: EditorExtensionSDK;
  value: string;
  handleFieldChange: Function;
}

interface State {
  options: object[];
  filteredOptions: object[];
  validationMessage: string;
}

export default class ContentTypeField extends React.Component<Props, State> {
  state: State = {
    options: [],
    filteredOptions: [],
    validationMessage: '',
  };

  async componentDidMount() {
    await this.handleMount();
    this.props.sdk.entry.fields.contentType.onValueChanged(this.handleContentTypeChange);
  }

  handleContentTypeChange = async () => {
    const translationInfo = this.props.sdk.entry.fields.translationInfo.getValue();
    const savedContentType = translationInfo?.entry?.contentType?.sys?.id ?? ""
    const contentType = this.props.sdk.entry.fields.contentType.getValue();
    if (savedContentType !== contentType) {
      this.props.handleFieldChange("selectedReferenceFields", []);
      this.props.handleFieldChange("selectedEmbeddedReferences", []);
      this.props.handleFieldChange("localizedReferences", false);
      this.props.handleFieldChange("showLinkedReference", false)
      this.props.handleFieldChange("embeddedReference", false)
    }
  }

  handleMount = async () => {
    const contentTypes = await getContentTypes(this.props.sdk);
    const options = contentTypes
      .filter((item: any) => {
        return item.fields.some((field: any) => {
          return field.localized;
        });
      })
      .filter((option: any) => {
        const { installation }: { installation: any } = this.props.sdk.parameters;
        return ![installation.translatorModelId, installation.translationsModelId].includes(
          option.sys.id,
        );
      })
      .sort((a: any, b: any) => a.name.localeCompare(b.name));

    this.setState({
      options,
      filteredOptions: options,
    });
  };

  render() {
    let selectedItem = this.state.options.filter(
      (option: any) => option.sys.id === this.props.value,
    );
    const handleInputValueChange = (value: string) => {
      const newFilteredItems = this.state.options.filter((option: any) => {
        if (option.sys.id === this.props.value) {
          selectedItem = option;
        }
        return option.name.toLowerCase().includes(value.toLowerCase());
      });
      this.setState({ filteredOptions: newFilteredItems });
    };

    return (
      <FormControl isRequired>
        <FormControl.Label>Content type</FormControl.Label>
        <Autocomplete
          items={this.state.filteredOptions}
          onInputValueChange={handleInputValueChange}
          selectedItem={selectedItem?.[0]}
          placeholder=""
          listWidth="full"
          renderItem={(item: any) => item.name}
          itemToString={(item: any) => item.name}
          onSelectItem={async (value: any) => {
            let selected = value;
            let response = true;
            let sourceContentFieldValue = this.props.sdk.entry.fields.sourceContent.getValue();
            if (
              sourceContentFieldValue != null &&
              this.props.sdk.entry.fields.sourceContent.getValue() != undefined
            )
              response = await this.props.sdk.dialogs.openConfirm({
                title: 'Change content type?',
                message:
                  'You have some source content already selected, changing content type will remove the selection. Do you wish to proceed?',
                intent: 'positive',
                confirmLabel: 'Yes!',
                cancelLabel: 'No',
              });
            // TODO: Find a reliable way to revert the select back to the original value, right now it doesn't
            if (response) {
              this.props.sdk.entry.fields.sourceContent.setValue([]);
              // @ts-ignore
              this.props.handleFieldChange('contentType', selected.sys.id);
            }
            if (isMediaAsset(selected.sys.id)) {
              this.props.handleFieldChange('localizationMethod', FIELD_LEVEL);
            }
          }}
        />
        {this.state.validationMessage && (
          <FormControl.ValidationMessage>
            {this.state.validationMessage}
          </FormControl.ValidationMessage>
        )}
      </FormControl>
    );
  }
}
