import FieldFactory from "../fields/field.factory";

export default class TargetEntry {
  fields: any = {};
  targetEntry: any = {};

  constructor(contentType: any, targetEntry: any, sourceEntry: any) {
    this.targetEntry = targetEntry;
    for (const fieldName in targetEntry.content) {
      const fieldTypeInfo = contentType.fields.find((field: any) => {
        return field.id == fieldName;
      });
      const fieldType = fieldTypeInfo ? fieldTypeInfo.type : null;
      if (fieldType) {
        this.fields[fieldName] = FieldFactory.makeField(fieldType, {
          source: sourceEntry.fields[fieldName],
          target: targetEntry.content[fieldName],
          ids: targetEntry.ids,
        });
      }
    }
  }

  /**
   * Get content into contentful
   */
  async in() {
    const input: any = {};

    for (const field in this.fields) {
      input[field] = await this.fields[field].in();
    }
    this.targetEntry.content = input;
    this.targetEntry.timeDelivered = new Date().getTime();
    return this.targetEntry;
  }
}
