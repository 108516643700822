import {
  EMBEDDED_REFERENCE_OBJECT,
  SELECTED_EMBEDDED_REFERENCE_OBJECT,
} from '../config/translationModel';
import migration from './migration';
import { bugsnag as Bugsnag } from '../utils/bugsnag';

export default class upgrade1_7_0 extends migration {
  public runMigration = async (): Promise<void> => {
    try {
      this.startUpdateAlert().then(async () => {
        this.updateUser('Checking installation');
        const contentTypeId = this.sdk?.parameters?.installation?.translationsModelId;
        let contentType = await this.getContentType(contentTypeId);

        this.updateUser('Adding new fields.');
        contentType.fields.push(EMBEDDED_REFERENCE_OBJECT);
        contentType.fields.push(SELECTED_EMBEDDED_REFERENCE_OBJECT);

        this.updateUser('Updating translation project');
        await this.sdk.cma.contentType
          .update({ contentTypeId: contentType.sys.id }, contentType)
          .then(async (contentType: any) => {
            this.updateUser('Publishing translation project');
            await this.sdk.cma.contentType
              .publish({ contentTypeId: contentType.sys.id }, contentType)
              .then(() => {
                this.updateUser('Update complete, Please refresh the page to continue.');
              });
          });
      });
    } catch (error: any) {
      console.log(error);
      Bugsnag.notify(new Error(error.message));
      this.finishWithErrorAlert();
    }
  };
}
