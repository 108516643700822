export const ACTIVITY_PENDING = '"Pending" Submission';
export const ACTIVITY_CREATED = "Project created for translation";
export const ACTIVITY_SUBMITTED = "Project submitted for translation";
export const ACTIVITY_IN_PROGRESS = "Status changed to “In progress”";
export const ACTIVITY_DOWNLOADED = "Source files downloaded";

export interface Activity {
  activity: string;
  time?: number;
}
export default class ActivityManager {
  activities: Activity[];
  constructor(activities: Activity[]) {
    this.activities = activities;
  }

  push(activity: string, expiryTime = 1) {
    // contentful firing extra onchanged event reject action which are same and fraction of seconds apart
    let lastItem: any = null;
    let canPush = false;
    const currentTime = new Date().getTime();
    if (this.activities.length) {
      lastItem = this.activities[this.activities.length - 1];
      if (
        lastItem.activity != activity ||
        currentTime - lastItem.time > expiryTime * 1000
      ) {
        canPush = true;
      }
    }
    if (!lastItem || canPush) {
      this.activities.push({
        activity,
        time: currentTime,
      });
    }

    return this.activities;
  }

  get() {
    return this.activities.length
      ? this.activities
      : [
          {
            activity: ACTIVITY_PENDING,
          },
        ];
  }

  downloaded() {
    return this.push(ACTIVITY_DOWNLOADED);
  }

  submitted() {
    return this.push(ACTIVITY_SUBMITTED);
  }

  created() {
    return this.push(ACTIVITY_CREATED);
  }

  uploaded(file: string) {
    return this.push(`File ${file} uploaded`);
  }

  statusChanged(status: string) {
    return this.push(`Status changed to “${status}”`);
  }

  retrySubmission() {
    return this.push(
      `Project failed to submit to Acclaro. Please try again.`,
      999999999,
    );
  }

  fileUploadSubmissionFailed() {
    return this.push(`Error uploading file.`, 999999999);
  }
}
