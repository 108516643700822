import React from 'react';
import { EditorExtensionSDK, PageExtensionSDK } from '../extensions-sdk';
import { Card, Button } from '@contentful/f36-components';
import { Workbench } from '@contentful/f36-workbench';
// @ts-ignore 2307
import logo from '../config/logo.svg';
import InsightsWidget from '../components/page/insight/InsightsWidget';
import { Row, Col } from 'react-grid-system';
import Ad from '../components/common/Ad';
import NewEntries from '../components/page/NewEntries';
import AcclaroNews from '../components/page/AcclaroNews';
import Coverage from '../components/page/coverage/Coverage';
import ProjectsWithUpdate from '../components/page/ProjectsWithUpdate';
import HubspotForm from '../components/common/HubspotForm';
import { hasAcclaroTranslator } from '../utils/contentful';
import Version from '../components/common/Version';

interface Props {
  sdk: PageExtensionSDK;
}

interface State {
  hasActiveAcclaroTranslator: boolean;
  trackChangeEnabled: boolean;
}

export default class Page extends React.Component<Props, State> {
  state: State = {
    hasActiveAcclaroTranslator: false,
    trackChangeEnabled: false,
  };

  constructor(props: Readonly<Props>) {
    super(props);
  }

  async componentDidMount() {
    const hasActiveAcclaroTranslator = await hasAcclaroTranslator(this.props.sdk);
    this.setState({
      hasActiveAcclaroTranslator,
    });
  }

  render() {
    const sdk = this.props.sdk;
    return (
      <Workbench className={'translations-dashboard'}>
        <Workbench.Header
          icon={<img src={logo} alt="" />}
          actions={<Version />}
          title={'Translations Dashboard'}
        ></Workbench.Header>
        <Workbench.Content type={'full'}>
          <div className={'translations-dashboard-content'}>
            <InsightsWidget sdk={sdk}></InsightsWidget>
            <Row style={{ marginTop: '2em' }}>
              <Col xs={7}>
                {this.state.trackChangeEnabled && <ProjectsWithUpdate sdk={sdk} />}
                <NewEntries sdk={sdk} />
                <Coverage sdk={sdk} />
              </Col>
              <Col xs={4} style={{ marginLeft: '8%' }}>
                <Button
                  isFullWidth={true}
                  size={'large'}
                  className={'create-project-dashboard-btn'}
                  variant="primary"
                  onClick={() => {
                    //@ts-ignore
                    sdk.navigator.openNewEntry(sdk.parameters.installation.translationsModelId);
                  }}
                >
                  Create translation project
                </Button>
                <Ad sdk={sdk as unknown as EditorExtensionSDK} />
                <AcclaroNews />
                {this.state.hasActiveAcclaroTranslator && (
                  <Card style={{ marginTop: '1em' }}>
                    <h3>Request Translation support</h3>
                    <HubspotForm />
                  </Card>
                )}
              </Col>
            </Row>
          </div>
        </Workbench.Content>
      </Workbench>
    );
  }
}
