import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { init, locations } from 'contentful-ui-extensions-sdk';
import { KnownSDK } from './extensions-sdk';
import './index.scss';
import App from './components/App';
import { ErrorBoundary } from './utils/bugsnag';
import standalone from './utils/standalone';
import { createClient } from 'contentful-management';

if (window.location.search) {
  standalone(window);
} else {
  init((sdk: KnownSDK) => {
    //@ts-ignore
    window.sdk = sdk;
    if (
      sdk.location.is(locations.LOCATION_APP_CONFIG) ||
      sdk.location.is(locations.LOCATION_ENTRY_SIDEBAR) ||
      sdk.location.is(locations.LOCATION_ENTRY_FIELD) ||
      sdk.location.is(locations.LOCATION_ENTRY_EDITOR) ||
      sdk.location.is(locations.LOCATION_PAGE)
    ) {
      sdk.cma = createClient(
        {
          apiAdapter: sdk.cmaAdapter,
        },
        {
          type: 'plain',
          defaults: {
            environmentId: sdk.ids.environment,
            spaceId: sdk.ids.space,
          },
        },
			);
			const container = document.getElementById('root');
			const root = createRoot(container!);
      root.render(
        <ErrorBoundary>
          <App
            sdk={ sdk as KnownSDK }
          />
        </ErrorBoundary>
      );
    }
  });
}
