import axios, { AxiosInstance } from "axios";
import loGet from "lodash.get";
import loSet from "lodash.set";
import { TranslationStore } from "../services/translator/translator.service";
import TargetEntry from "../entries/target.entry";
import FormData from "form-data";
import NodeRSA from "node-rsa";
import { getJSEncryptPrivateKey, getJSEncryptPublicKey } from "./keys";
import rateLimit from "axios-rate-limit";
import { ENTRY_ID_SEPARATOR } from "../services/translation/translation.service";
import { EntryContentStore } from "./data-manager/data.manager";
import isValidEntry from "./is-valid-entry";
import AdmZip from "adm-zip";

const PRODUCTION_URL = "https://my.acclaro.com/api2/";
const SANDBOX_URL = process.env.SANDBOX_URL
  ? process.env.SANDBOX_URL + "/api2/"
  : "https://apisandbox.acclaro.com/api2/";
const STATUS_IN_PROGRESS = "In progress";
const ACCLARO_STATUS_PREP = "in preparation";
const ACCLARO_STATUS_PROGRESS = "in progress";

export interface SupportedLanguagesResponse {
  success: boolean;
  data: { [k: string]: any };
}

interface TargetData {
  targetEntryReceived: any;
  targetFileId: string;
  file: string;
  translationInfo: TranslationStore;
  entryContentStore: EntryContentStore;
}

export const getAcclaroClient = (apiKey: string, sandbox: boolean) => {
  let axiosInstance = axios.create({
    baseURL:
      sandbox && sandbox.toString() === "true" ? SANDBOX_URL : PRODUCTION_URL,
    headers: {
      Authorization: `Bearer ${apiKey}`,
      Accept: "application/json",
      "User-Agent": "Contentful",
    },
  });
  return rateLimit(axiosInstance, { maxRequests: 1, perMilliseconds: 1200 });
};

export const getAcclaroClientDecryptKey = (
  apiKey: string,
  sandbox: boolean,
) => {
  apiKey = decryptString(apiKey);
  return getAcclaroClient(apiKey, sandbox);
};

export const acclaroComment = async (
  orderId: string | Number,
  fileId: string,
  comment: string,
  apiKey: string,
  sandbox: boolean,
) => {
  const client = getAcclaroClientDecryptKey(apiKey, sandbox);

  const formData = new FormData();
  formData.append("orderid", orderId);
  formData.append("fileid", fileId);
  formData.append("comment", comment);
  return client.post("AddFileComment", formData, {
    headers: formData.getHeaders(),
  });
};

export const getEntry = async (entryURL: string, appAccessToken: string) => {
  let config = {
    headers: {
      Authorization: `Bearer ${appAccessToken}`,
    },
  };
  const response = await axios.get(entryURL, config);
  if (response.status !== 200) {
    throw new Error(response.data);
  }
  return response;
};

export const getDefaultSpaceLocale = async (
  space: string,
  env: string,
  appAccessToken: string,
) => {
  let config = {
    headers: {
      Authorization: `Bearer ${appAccessToken}`,
    },
  };
  let localesURL = `${process.env.BASE_URL}/spaces/${space}/environments/${env}/locales`;

  const res = await axios.get(localesURL, config);
  if (res.status !== 200) {
    throw new Error(res.data);
  }

  const response = await res.data;

  return response.items.filter((locale: any) => {
    if (locale.default == true) {
      return true;
    }
  })[0].code;
};

export const slugify = (text: string) => {
  text = text || "";
  return text
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .substr(0, 75);
};

export const updateEntry = async (
  entryURL: string,
  appAccessToken: string,
  entry: any,
  translationInfo: any,
  locale: string,
) => {
  const updatedEntry = await getEntry(entryURL, appAccessToken);
  let config = {
    headers: {
      Authorization: `Bearer ${appAccessToken}`,
      "X-Contentful-Version": loGet(updatedEntry.data, "sys.version"),
      "Content-Type": "application/vnd.contentful.management.v1+json",
    },
  };
  return await axios.put(
    entryURL,
    {
      fields: {
        ...loGet(entry, "fields", {}),
        translationInfo: {
          [`${locale}`]: translationInfo,
        },
      },
    },
    config,
  );
};

export const getEntryURL = (space: string, env: string, entry: string) => {
  return `${process.env.BASE_URL}/spaces/${space}/environments/${env}/entries/${entry}`;
};

export const getAssetURL = (space: string, env: string) => {
  return `https://app.contentful.com/spaces/${space}/environments/${env}/assets/`;
};

export const getEntryWebUrl = (space: string, env: string) => {
  return `https://app.contentful.com/spaces/${space}/environments/${env}/entries/`;
}

export const getContentTypeModelById = (
  contentTypes: any[],
  contentTypeID: string,
) => {
  return contentTypes.find((cType: any) => {
    return cType.sys.id == contentTypeID;
  });
};

/**
 * Checks if received target data from Acclaro is valid else send message to acclaro and throw an error.
 */
export const checkIfEntryIsValid = async (
  targetEntryReceived: any,
  targetFileId: string,
  translationInfo: TranslationStore,
) => {
  let validation = isValidEntry(targetEntryReceived);
  if (!validation.isValid) {
    await acclaroComment(
      loGet(translationInfo, "acclaroStatus.orderId"),
      targetFileId,
      `Invalid file: ${validation.message}`,
      loGet(translationInfo, "entry.apiKey"),
      loGet(translationInfo, "entry.sandbox"),
    );
    throw new Error("File Validation failed");
  }
  return true;
};

export const updateTranslationInfoForFile = async (
  translationInfo: TranslationStore,
  entryContentStore: EntryContentStore,
  file: string,
  targetFileId: string | undefined,
  client: null | AxiosInstance = null,
) => {
  try {
    const acclaroClient =
      client ||
      getAcclaroClientDecryptKey(
        loGet(translationInfo, "entry.apiKey"),
        loGet(translationInfo, "entry.sandbox"),
      );

    if (targetFileId) {
      const fileResponse = await acclaroClient.get("GetFile", {
        params: {
          orderid: loGet(translationInfo, "acclaroStatus.orderId"),
          fileid: targetFileId,
        },
        responseType: "arraybuffer",
      });

      const targetEntryReceived = loGet(fileResponse, "data");
      let regex = "(filename=.+.zip)"; // To check if returned file is zip or not
      let disposition: string = fileResponse.headers["content-disposition"];
      let isZip = disposition.match(regex);

      let updatedTranslationInfo: any;
      let updatedEntryContentStore: any;

      if (isZip) {
        let zip = new AdmZip(targetEntryReceived);
        for (let entry of zip.getEntries()) {
          let targetEntry = JSON.parse(entry.getData().toString());
          let targetData = {
            targetEntryReceived: targetEntry,
            targetFileId: targetFileId,
            translationInfo: translationInfo,
            entryContentStore: entryContentStore,
            file: file,
          };
          [updatedTranslationInfo, updatedEntryContentStore] =
            await processDeliveredFile(targetData);

          translationInfo = updatedTranslationInfo || translationInfo;
          entryContentStore = updatedEntryContentStore || entryContentStore;
        }
      } else {
        let targetData = {
          targetEntryReceived: JSON.parse(
            Buffer.from(targetEntryReceived).toString("utf-8"),
          ),
          targetFileId: targetFileId,
          translationInfo: translationInfo,
          entryContentStore: entryContentStore,
          file: file,
        };
        return await processDeliveredFile(targetData);
      }

      return [
        { ...translationInfo, lastSyncedAt: dateTimeFormat() },
        entryContentStore,
      ];
    }
  } catch (Error) {
    //TODO: There should be some logging here why did the request fail
    console.log(Error);
    return [false, false];
  }
  return [false, false];
};

const updateToInProgress = (order: any, entryContentStore: any) => {
  if (order.status === ACCLARO_STATUS_PROGRESS) {
    let fileData = entryContentStore.fileData;
    for (let file of fileData) {
      file.status = STATUS_IN_PROGRESS; // references at work - updated original object
    }
  }
};

/**
 *
 * Updates the content store and the translation info object with updates from
 * Acclaro
 * @param translationInfo
 * @param entryContentStore
 */
export const updatedOrderInfoForProject = async (
  translationInfo: TranslationStore,
  entryContentStore: EntryContentStore,
) => {
  const client = getAcclaroClientDecryptKey(
    loGet(translationInfo, "entry.apiKey"),
    loGet(translationInfo, "entry.sandbox"),
  );

  const responseAcclaro = await client.get("GetOrder", {
    params: {
      orderid: loGet(translationInfo, "acclaroStatus.orderId"),
    },
  });
  const order = loGet(responseAcclaro, "data.data");
  if(translationInfo && translationInfo?.programSelectionPreferences && translationInfo?.entry) {
    translationInfo.programSelectionPreferences.selectedProgram = order?.program?.id || null;
    translationInfo.entry.selectedProgram = order?.program?.id || null;
  }
  // status has changed
  if (order && order.status != loGet(translationInfo, "acclaroStatus.status")) {
    loSet(translationInfo, "acclaroStatus.status", order.status);
    updateToInProgress(order, entryContentStore);
  }
  return [translationInfo, entryContentStore];
};

export const dateTimeFormat = (
  date: Date | number = new Date(),
  locale = "en",
) => {
  const dtf = new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
  return dtf.format(date);
};

export const getOutdatedEntries = (
  translationInfo: any,
  targetLanguages: any,
) => {
  const entriesForUpdate = translationInfo.entriesForUpdate || [];
  const outdatedEntries = translationInfo.outdatedEntries || {};
  for (let entry of entriesForUpdate) {
    for (let targetLanguage of targetLanguages) {
      outdatedEntries[`${entry}${ENTRY_ID_SEPARATOR}${targetLanguage}`] = 1;
    }
  }
  return outdatedEntries;
};

/**
 * Encrypts the Translator API key
 */
export const encryptString = (content: string) => {
  let encrypted = "";
  if (content) {
    try {
      const encrypt = new NodeRSA(getJSEncryptPublicKey(), "pkcs1", {
        encryptionScheme: "pkcs1",
      });
      encrypted = encrypt.encrypt(content, "utf8");
    } catch (Error) {
      console.log("Error in Encryption");
    }
  }
  return encrypted;
};

/**
 * Decrypts the Translator API content
 */
export const decryptString = (content: string) => {
  let decrypted = "";
  if (content) {
    try {
      const decrypt = new NodeRSA(getJSEncryptPrivateKey(), "pkcs1", {
        encryptionScheme: "pkcs1",
      });
      decrypt.setOptions({ environment: "browser" });
      decrypted = decrypt.decrypt(content, "utf8");
    } catch (Error: any) {
      console.log("Error in Decryption");
      console.error(Error)
    }
  }
  return decrypted;
};

/**
 * Updates the delivered file data based on file type (zip/json)
 */
export const processDeliveredFile = async (targetData: TargetData) => {
  let targetEntryReceived = targetData.targetEntryReceived;
  let targetFileId = targetData.targetFileId;
  let translationInfo = targetData.translationInfo;
  let entryContentStore = targetData.entryContentStore;
  let file = targetData.file;

  await checkIfEntryIsValid(targetEntryReceived, targetFileId, translationInfo);

  const fileData = loGet(entryContentStore, "fileData") || [];
  const sourceSubmittedEntries = loGet(entryContentStore, "sourceEntries", []);

  const sourceEntry = sourceSubmittedEntries.find(
    (sEntry: { entryId: any }) => {
      return sEntry.entryId == targetEntryReceived.entryId;
    },
  );

  const targetEntry = new TargetEntry(
    getContentTypeModelById(
      loGet(translationInfo, "entry.allContentTypes"),
      sourceEntry.contentType,
    ),
    targetEntryReceived,
    sourceEntry,
  );
  const targetEntryToSubmit = await targetEntry.in();

  // adding acclaro information to the file
  targetEntryToSubmit.acclaroFileId = targetFileId;

  const translatedEntries = loGet(entryContentStore, "translatedEntries") || [];
  const fileEntry = fileData.find((fileEntry: any) => {
    // Use entry and target check as multiple files can have same id in case of zip flow
    return (
      fileEntry.fileId == file &&
      targetEntryReceived.entryId == fileEntry.entryId &&
      targetEntryReceived.target == fileEntry.target
    );
  });

  const translatedEntryIndex = translatedEntries.findIndex(
    (translatedEntry: any) => {
      return (
        translatedEntry &&
        translatedEntry.target == fileEntry.target &&
        translatedEntry.entryId == fileEntry.entryId
      );
    },
  );
  if (translatedEntryIndex >= 0) {
    const translatedEntry = translatedEntries[translatedEntryIndex];
    // if there was any status already set it means the file was approved or published ignore this
    if (translatedEntry.status === undefined) {
      loSet(
        entryContentStore,
        `translatedEntries.${translatedEntryIndex}`,
        targetEntryToSubmit,
      );
    }
  } else {
    if (Array.isArray(entryContentStore["translatedEntries"])) {
      entryContentStore["translatedEntries"].push(targetEntryToSubmit);
    } else {
      entryContentStore["translatedEntries"] = [targetEntryToSubmit];
    }
  }
  /** remove outdated entries **/
  let outdatedEntries = translationInfo.outdatedEntries || {};
  delete outdatedEntries[
    `${targetEntryToSubmit.entryId}${ENTRY_ID_SEPARATOR}${targetEntryToSubmit.target}`
  ];
  translationInfo.outdatedEntries = outdatedEntries;
  return [
    { ...translationInfo, lastSyncedAt: dateTimeFormat() },
    entryContentStore,
  ];
};
