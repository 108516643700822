import map from 'lodash.map';
import {
  TranslationResponse,
  TranslatorConfig,
} from '../../../lambda/src/services/translator/translator.service';
const LZUTF8 = require('lzutf8-light');

export default {
  toQueryString(obj: object) {
    return map(obj, function (v, k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(v);
    }).join('&');
  },
  async authenticate(params: object): Promise<string> {
    const res = await fetch(`/translator/authenticate?${this.toQueryString(params)}`);
    return await res.json();
  },
  async createTranslation(data: object): Promise<TranslationResponse> {
    let body = JSON.stringify(data);
    const res = await fetch(`/translation/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body, // body data type must match "Content-Type" header
    });
    return await res.json();
  },
  async pushToAcclaro(data: object): Promise<any> {
    const res = await fetch(`/acclaro/push`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return await res.json();
  },
  async syncAcclaro(data: object): Promise<TranslationResponse> {
    const res = await fetch(`/acclaro/sync`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return await res.json();
  },
  async sendFeedbackAcclaro(data: object): Promise<TranslationResponse> {
    const res = await fetch(`/acclaro/feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return await res.json();
  },
  async setupEditor(data: object) {
    const res = await fetch(`/setup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return await res.json();
  },
  async getSupportedLanguages(data: {
    translator: TranslatorConfig;
    locales: string[];
  }): Promise<any> {
    const res = await fetch(`/translator/supported-languages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return await res.json();
  },

  async getTranslatedEntriesInfo(data: { translationProjectIds: string[] }): Promise<any> {
    const res = await fetch(`/setup/translated-entries-info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return await res.json();
  },

  async uploadFile(data: any): Promise<TranslationResponse> {
    data = LZUTF8.compress(JSON.stringify(data), { outputEncoding: 'StorageBinaryString' });
    const res = await fetch(`/translation/upload`, {
      method: 'POST',
      body: data,
    });
    return await res.json();
  },

  async storeData(data: any): Promise<any> {
    const res = await fetch(`/data-store`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return await res.json();
  },

  async getData(entry: string): Promise<any> {
    const res = await fetch(`/data-store?entryId=` + entry, {
      method: 'GET',
    });
    return await res.json();
  },

  async getUploadURL(key: string): Promise<string> {
    const res = await fetch(`/data-store/upload-url`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ key }),
    });
    return await res.text();
  },

  async getDownloadURL(key: string): Promise<string> {
    const res = await fetch(`/data-store/download-url`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ key }),
    });
    return await res.text();
  },

  async fetchAcclaroNews(): Promise<string> {
    const res = await fetch('/acclaro/feed');
    return await res.text();
  },

  async getProgramsList(apiKey: string, sandbox: boolean): Promise<any> {
    const res = await fetch('/acclaro/programs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ apiKey, sandbox })
    });
    return res.json();
  }
};
