import Field from "./field";
import loGet from "lodash.get";
import wordCount from "../utils/words-count";

export default class ArrayField extends Field {
  out() {
    /** Array contains objects - skip for now **/
    if (loGet(this.config, "source.0.sys.type")) {
      return "";
    }
    return this.config.source;
  }

  async in() {
    if (loGet(this.config, "source.0.sys.type")) {
      return this.config.source;
    }
    return this.config.target;
  }

  getWordCount(): Number {
    /** Array contains objects - skip for now **/
    if (loGet(this.config, "source.0.sys.type")) {
      return 0;
    }
    return wordCount(this.config.source.join(" "));
  }
}
