import React from 'react';
import { TextLink, HelpText } from '@contentful/f36-components';
import * as appData from '../../../package.json';
import commit from '../../../../lambda/src/commit';

let showCommitID = true;
if (window.location.hostname == 'ctf-translations.acclaro-apps.com') {
  showCommitID = false;
}

export default class Version extends React.Component {
  render() {
    return (
      <HelpText style={{ margin: 0 }}>
        v{appData.version}
        {showCommitID && (
          <span>
            {' '}
            :{' '}
            <TextLink
              target={'_blank'}
              href={`https://github.com/AcclaroInc/contentful-translations/commit/${commit}`}
            >
              {commit.substring(0, 7)}
            </TextLink>
          </span>
        )}
      </HelpText>
    );
  }
}
