import React from 'react';
import { SectionHeading, Card, Badge } from '@contentful/f36-components';
import { Row, Col } from 'react-grid-system';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import ProgressBar from '../ProgressBar/ProgressBar';
import { EntryContentStore } from '../../../../../lambda/src/utils/data-manager/data.manager';

interface File {
  wordCount: number;
}
interface Props {
  sdk: EditorExtensionSDK;
  entryContentStore: EntryContentStore;
  getStatus: Function;
}

export default class ProjectStatus extends React.Component<Props> {
  render() {
    const targetLanguages = this.props.sdk.entry?.fields.targetLanguages.getValue() || [];
    const languageNames = this.props.sdk.locales.names;

    return (
      <>
        <SectionHeading className="mt-2">
          Status
          <Badge
            className="entry-status"
            style={{
              color: this.props.getStatus().color,
              backgroundColor: this.props.getStatus().backgroundColor,
            }}
            testId="cf-ui-tag"
          >
            {this.props.getStatus().text}
          </Badge>
        </SectionHeading>
        <hr className="faded" />
        {targetLanguages.length > 0 && (
          <>
            {targetLanguages.map((language: string) => {
              return (
                <Card key={language} style={{ marginBottom: 'var(--spacing-xs)', width: 'auto' }}>
                  <Row className={'language-status'}>
                    <Col xs={3}>{languageNames[language]}</Col>
                    <Col xs={9}>
                      <Badge
                        className="entry-status"
                        style={{
                          color: this.props.getStatus(language).color,
                          backgroundColor: this.props.getStatus(language).backgroundColor,
                        }}
                        testId="cf-ui-tag"
                      >
                        {this.props.getStatus(language).text}
                      </Badge>
                    </Col>
                  </Row>
                  <ProgressBar
                    sdk={this.props.sdk}
                    language={language}
                    entryContentStore={this.props.entryContentStore}
                  />
                </Card>
              );
            })}
          </>
        )}
      </>
    );
  }
}
