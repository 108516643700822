import React from 'react';
// @ts-ignore
import nice from './nice.svg';

interface Props {
  showNice: boolean;
  title: string;
  message: string;
}

export default class BodyMessage extends React.Component<Props> {
  render() {
    return (
      <div className={'all-published'}>
        <div>{this.props.showNice && <img src={nice} />}</div>
        <h2>{this.props.title}</h2>
        <div> {this.props.message}</div>
      </div>
    );
  }
}
