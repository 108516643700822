/**
 Returns the standard configuration for the App
 **/

export default function (
  parameters: object,
  translationModelId: string,
  translatorModelId: string,
) {
  let editorInterface: any = {
    [translationModelId]: {
      sidebar: { position: 0 },
      editor: true,
    },
  };

  editorInterface[translatorModelId] = {
    sidebar: { position: 0 },
    editor: true,
  };

  return {
    parameters,
    targetState: {
      EditorInterface: editorInterface,
    },
  };
}
