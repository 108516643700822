import React from 'react';
import { Flex, Note, Button, List, ListItem, TextLink } from '@contentful/f36-components';
import { CloseIcon } from '@contentful/f36-icons';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import { getElementURL } from '../../../utils/helpers';

interface Props {
  sdk: EditorExtensionSDK;
  missingEntries: object;
}

interface State {
  missingEntries: object;
}

export default class Notice extends React.Component<Props, State> {
  state: State = {
    missingEntries: {},
  };

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ missingEntries: nextProps.missingEntries });
  }

  render(): React.ReactNode {
    return (
      <div>
        {this.state.missingEntries && Object.keys(this.state.missingEntries).length > 0 && (
          <Flex className="notice-wrapper">
            <Note className="notice" variant="negative">
              <Button
                className="notice-close"
                variant="secondary"
                size="small"
                startIcon={<CloseIcon />}
                onClick={() => this.setState({ missingEntries: {} })}
              />
              <strong>Warning:</strong> Entry Missing or Inaccessible
              <div style={{ maxHeight: '120px', overflowY: 'auto', marginTop: '4px' }}>
                <List>
                  {Object.entries(this.state.missingEntries).map(([key, value]) => (
                    <ListItem className="pb-4" key={key}>
                      <TextLink href={getElementURL(this.props.sdk, value)} target="_blank">
                        {value.fields.internalName[this.props.sdk.locales.default]}
                      </TextLink>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Note>
          </Flex>
        )}
      </div>
    );
  }
}
