import React from 'react';
import { EditorExtensionSDK } from '../../extensions-sdk';
import TranslationNameField from './fields/TranslationNameField';
import ContentTypeField from './fields/ContentTypeField';
import TranslatorField from './fields/TranslatorField';
import TargetLanguageField from './fields/TargetLanguageField';
import SourceContentField from './fields/SourceContentField';
import AdvanceOptions, { Programs } from './parts/AdvanceOptions';
import LocalizedReferenceField from './fields/LocalizedReferenceField';
import LoadingOverlay from 'react-loading-overlay-ts';
import LocalizationMethodField from './fields/LocalizationMethodField';
import { isMediaAsset } from '../../utils/assetHelpers';
import MediaSourceContentField from './fields/MediaSourceContentField';
import EmbeddedReference from './fields/EmbeddedReference';
import MigrationHelper from '../../utils/migrationHelper';
import ProgramSelector from './fields/ProgramSelectorField';
import localClient from '../../utils/localClient';

interface Props {
  sdk: EditorExtensionSDK;
  trackChangeEnabled: boolean;
  showNotice: Function;
}

interface TranslatorType {
  sys: {
    id: string,
    linkType: string,
    type: string;
  }
}

interface State {
  translationName: string;
  contentType: string;
  localizationMethod: string;
  translator: TranslatorType | null;
  targetLanguages: string[];
  sourceContent: object[];
  requestedDueDate: string;
  translationNotes: string;
  localizedReferences: boolean;
  embeddedReference: boolean;
  trackChanges: boolean;
  selectedReferenceFields: string[];
  selectedEmbeddedReferences: string[];
  [key: string]: unknown;
  loading: boolean;
  loadingText: string;
  showLinkedReference: boolean;
  selectedProgram: number | null;
  translateSlug: boolean;
  allPrograms: Programs[];
  programSelectionEnabled: boolean;
  selectedContent: string[];
  excludedContent: string[];
}

export default class Editor extends React.Component<Props, State> {
  state: State = {
    translationName: '',
    contentType: '',
    localizationMethod: '',
    translator: { sys: { id: "", linkType: "", type: "" } },
    targetLanguages: [],
    sourceContent: [],
    requestedDueDate: '',
    translationNotes: '',
    localizedReferences: false,
    embeddedReference: false,
    trackChanges: false,
    selectedReferenceFields: [],
    selectedEmbeddedReferences: [],
    loading: false,
    loadingText: 'Loading...',
    showLinkedReference: false,
    selectedProgram: null,
    translateSlug: false,
    allPrograms: [],
    programSelectionEnabled: false,
    selectedContent: [],
    excludedContent: []
  };

  constructor(props: Readonly<Props>) {
    super(props);
    const { sdk } = this.props;
    // Run migration if needed
    const migration = MigrationHelper.isMigrationNeeded(sdk);
    if (migration) {
      this.state.loading = true;
      MigrationHelper.runMigration(sdk, migration, this.updateLoadingText);
    } else {
      const translationInfo = sdk.entry.fields.translationInfo.getValue();
      for (const i in this.state) {
        // Reset original order value on page reload.
        if (
          translationInfo !== undefined &&
          Object.keys(translationInfo).length > 0 &&
          translationInfo.entry.hasOwnProperty(i)
        ) {
          if (i === 'contentType') {
            this.state[i] = translationInfo.entry[i].sys.id;
            this.props.sdk.entry.fields[i].setValue(translationInfo.entry[i].sys.id);
          } else {
            this.state[i] = translationInfo.entry[i];
            this.props.sdk.entry.fields[i].setValue(translationInfo.entry[i]);
          }
        } else {
          if (!sdk.entry.fields[i] || sdk.entry.fields[i].getValue() === undefined) continue;
          this.state[i] = sdk.entry.fields[i].getValue();
        }
      }
      if (this.state.translator != null) {
        this.updateProgramSelection(this.state.translator);
      }
      this.state.loading = false;
    };
  }

  updateProgramSelection = async (value: TranslatorType) => {
    const translatorObject = await this.props.sdk.cma.entry.get({ entryId: value.sys.id ?? "" })
    const programSelectionOption = translatorObject?.fields?.programSelection?.[this.props.sdk.locales.default] ?? false;
    if (programSelectionOption) {
      const apiKey = translatorObject.fields?.apiKey?.[this.props.sdk.locales.default] ?? "";
      const sandbox = translatorObject.fields?.sandbox?.[this.props.sdk.locales.default] ?? false;
      const response = await localClient.getProgramsList(apiKey, sandbox);
      this.state.allPrograms = response.data.data || [];
      this.setState({
        allPrograms: this.state.allPrograms,
        programSelectionEnabled: true
      })
    } else {
      this.state.allPrograms = [];
      this.setState({
        allPrograms: [],
        selectedProgram: null,
        programSelectionEnabled: false
      })
    }
  }

  updateLoadingText = (text: string) => {
    this.setState({
      loadingText: text,
    });
  };

  handleFieldChange = async (key: string, value: any) => {
    // Hide notice if user changes any field
    if (key === "translator") {
      await this.updateProgramSelection(value);
    }
    this.showNotice({});
    this.setState(
      {
        [key]: value,
      },
      async () => {
        await this.props.sdk.entry.fields[key].setValue(value);
      },
    );
  };

  showNotice = async (data: any) => {
    this.props.showNotice(data);
  };

  render() {
    const { sdk } = this.props;
    const dueDate = this.state.requestedDueDate
      ? new Date(this.state.requestedDueDate)
      : new Date();
    const dateNow = new Date();
    const minDate = dateNow.getTime() < dueDate.getTime() ? dateNow : dueDate;
    return (
      <LoadingOverlay
        active={this.state.loading}
        spinner
        text={this.state.loadingText}
      >
        <div className="app">
          <div className="body no-shadow">
            <div className="config no-padding">
              <div className="section">
                <TranslationNameField
                  value={this.state.translationName}
                  handleFieldChange={this.handleFieldChange}
                />
                <LocalizationMethodField
                  sdk={sdk}
                  value={this.state.localizationMethod}
                  handleFieldChange={this.handleFieldChange}
                />
                <ContentTypeField
                  sdk={sdk}
                  value={this.state.contentType}
                  handleFieldChange={this.handleFieldChange}
                />
                <LocalizedReferenceField
                  sdk={sdk}
                  localizedReferences={this.state.localizedReferences}
                  selectedReferenceFields={this.state.selectedReferenceFields}
                  handleFieldChange={this.handleFieldChange}
                  showLinkedReference={this.state.showLinkedReference}
                />
                <EmbeddedReference
                  sdk={sdk}
                  embeddedReference={this.state.embeddedReference}
                  selectedEmbeddedReferences={this.state.selectedEmbeddedReferences}
                  handleFieldChange={this.handleFieldChange}
                />
                {!isMediaAsset(this.state.contentType) ? (
                  <SourceContentField
                    value={this.state.sourceContent}
                    trackChanges={this.state.trackChanges}
                    showTrackChanges={this.props.trackChangeEnabled}
                    handleFieldChange={this.handleFieldChange}
                    showLinkedReference={this.state.showLinkedReference}
                    showNotice={this.showNotice}
                    selectedReferenceFields={this.state.selectedReferenceFields}
                    selectedEmbeddedReferences={this.state.selectedEmbeddedReferences}
                    contentType={this.state.contentType}
                    selectedContent ={this.state.selectedContent}
                    excludedContent={this.state.excludedContent}
                    sdk={sdk}
                  />
                ) : (
                  <MediaSourceContentField
                    value={this.state.sourceContent}
                    trackChanges={this.state.trackChanges}
                    showTrackChanges={this.props.trackChangeEnabled}
                    handleFieldChange={this.handleFieldChange}
                    sdk={sdk}
                  />
                )}
                <TranslatorField
                  value={this.state.translator}
                  handleFieldChange={this.handleFieldChange}
                  sdk={sdk}
                />
                {this.state.programSelectionEnabled && (
                  <ProgramSelector
                    sdk={this.props.sdk}
                    handleFieldChange={this.handleFieldChange}
                    allPrograms={this.state.allPrograms}
                    selectedProgram={this.state.selectedProgram}
                  />
                )}
                <TargetLanguageField
                  sdk={sdk}
                  value={this.state.targetLanguages}
                  handleFieldChange={this.handleFieldChange}
                />
                <AdvanceOptions
                  sdk={sdk}
                  dueDate={dueDate}
                  handleFieldChange={this.handleFieldChange}
                  requestedDueDate={this.state.requestedDueDate}
                  translationNotes={this.state.translationNotes}
                  translateSlug={this.state.translateSlug}
                  translator={this.state.translator}
                  selectedProgram={this.state.selectedProgram}
                />
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
