import React from 'react';
// @ts-ignore
import nice from './nice.svg';
import BodyMessage from './BodyMessage';

interface Props {
  allPublished: boolean;
}

export default class NothingToShow extends React.Component<Props> {
  render() {
    const { allPublished } = this.props;
    return (
      <BodyMessage
        showNice={allPublished}
        title={allPublished ? 'Nice job!' : 'Nothing to show here'}
        message={`Made a mistake? You can revert the Entry to a previous revision or request translation
          changes from the Project Details screen.`}
      />
    );
  }
}
