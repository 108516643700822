import migration from './migration';
import { bugsnag  as Bugsnag } from '../utils/bugsnag';
import { EXCLUDED_CONTENT, EXCLUDED_CONTENT_OBJECT, SELECTED_CONTENT, SELECTED_CONTENT_OBJECT } from '../config/translationModel';

export default class upgrade1_7_7 extends migration {
  public runMigration = async (): Promise<void> => {
    try {
      this.startUpdateAlert().then(async () => {
        this.updateUser('Checking installation');

        const translationProjectContentTypeId =
          this.sdk?.parameters?.installation?.translationsModelId;

        let translationsProjectContentType = await this.getContentType(
          translationProjectContentTypeId,
				);
				
				const hasSelectedContentType = translationsProjectContentType.fields.find((f: any) => f.id === SELECTED_CONTENT);

        // Updating and publishing translator content type
        if (!translationsProjectContentType.fields.find((f: any) => f.id === EXCLUDED_CONTENT)) {
					this.updateUser('Adding new field to translation project.');
					if (!hasSelectedContentType) {
						translationsProjectContentType.fields.push(SELECTED_CONTENT_OBJECT);
					}
          translationsProjectContentType.fields.push(EXCLUDED_CONTENT_OBJECT);
          this.updateUser('Updating and publishing translation project content type.');
          await this.sdk.cma.contentType
            .update(
              { contentTypeId: translationProjectContentTypeId },
              translationsProjectContentType,
            )
            .then(async () => {
              const updatedTranslationProjectContentModal = await this.sdk.cma.contentType.get({
                contentTypeId: translationProjectContentTypeId,
              });
              await this.sdk.cma.contentType
                .publish(
                  { contentTypeId: updatedTranslationProjectContentModal.sys.id },
                  updatedTranslationProjectContentModal,
                )
            })
            .catch((error) => {
              console.error(error);
            });
        }
        this.updateUser('Update complete. Please refresh the page to continue.');
      });
    } catch (error: any) {
      console.error(error);
      Bugsnag.notify(new Error(error.message));
      this.finishWithErrorAlert();
    }
  };
}
