import { TranslationStore } from "../translator/translator.service";

export const API_SERVICE = "api";
export const MACHINE_SERVICE = "machine";
export const MANUAL_SERVICE = "manual";
export const ENTRY_ID_SEPARATOR = "@";

export interface TranslationConfig {
  id?: string;
  ids?: { [any: string]: any };
  name?: string;
  status?: string;
  type?: string; // api, machine, manual
  apiKey?: string;
  sandbox?: boolean;
  entityID?: Number;
  contentType?: object;
  allContentTypes?: any[];
  localizedReferences?: boolean;
  localizationMethod?: string;
  trackChanges?: boolean;
  selectedReferenceFields?: string[];
  updateEntryLinks?: boolean;
  sourceLanguage?: string;
  targetLanguages?: string[];
  sourceContent?: any[];
  sourceEntries?: any[];
  referenceFieldMap?: any[];
  dueDate?: string;
  comments?: string;
  wordCount?: Number;
  translationInfo?: TranslationStore;
  submittedEntryIds?: string[];
  embeddedReference?:  boolean;
  selectedEmbeddedReferences?:  string[];
  showLinkedReference?: boolean;
	translateSlug?: boolean;
	spaceName?: string;
  selectedProgram?: number | null;
  selectedContent?: string[];
  excludedContent?: string[];
}

export default abstract class TranslationService {
  constructor(readonly config: TranslationConfig) {}
  abstract createTranslationEntry(): boolean;

  abstract getTranslationEntry(): object;

  abstract deleteTranslationEntry(): boolean;

  abstract updateTranslationEntry(): boolean;
}
