import { ContentEntitySys } from 'contentful-ui-extensions-sdk';
import { KnownSDK, EditorExtensionSDK } from '../extensions-sdk';
import loGet from 'lodash.get';
import loMerge from 'lodash.merge';
import { FIELD_LEVEL, STATUS_PUBLISHED, STATUS_READY_PUBLISH } from '../config/translationModel';
import { splitEntryId, uniqueArray } from './helpers';
import { FIELD_ARRAY } from '../../../lambda/src/fields/field';
import { OPTION_ACCLARO } from '../config/translatorModel';
import localClient from './localClient';
import { dateTimeFormat } from '../../../lambda/src/utils/helpers';
import DataManager from './data.manager';
import {
  MEDIA_ASSETS_ID,
  getAssetById,
  isMediaAsset,
  isMediaLinkType,
} from '../utils/assetHelpers';
import { getEntryAndAssetIds, getFullEntities, isTypeEntry, TYPE_ENTRY } from '../utils/helpers';
import { ENTRY_ID_SEPARATOR } from '../../../lambda/src/services/translation/translation.service';

/**
 * Get statuses of entities based on its sys
 * @param sys
 */

export function isDraft(sys: ContentEntitySys) {
  return !sys.publishedVersion;
}
export function isChanged(sys: ContentEntitySys) {
  return !!sys.publishedVersion && sys.version >= sys.publishedVersion + 2;
}
export function isPublished(sys: ContentEntitySys) {
  return !!sys.publishedVersion && sys.version == sys.publishedVersion + 1;
}
export function isArchived(sys: ContentEntitySys) {
  return !!sys.archivedVersion;
}

export const getEntryStatus = (sys: ContentEntitySys) => {
  if (isPublished(sys)) {
    return 'published';
  } else if (isChanged(sys)) {
    return 'changed';
  } else if (isDraft(sys)) {
    return 'draft';
  } else if (isArchived(sys)) {
    return 'archived';
  }
  return undefined;
};

/**
 * Get value for a particular field in the Entry
 * returns an empty string if null or undefined
 */
export const getFieldValue = (entry: unknown, field: string, locale: string) => {
  return loGet(entry, `fields.${field}.${locale}`, '');
};
/**
 * Get the Display field data for an entry based on its content type
 * @param selectedEntry this should be the full contentful entry data structure
 * @param contentTypes this would be list of contentTypes fetched from contentfule for current space
 * @param locale
 */
export const getDisplayField = (selectedEntry: any, contentTypes: any, locale: string) => {
  const contentType: any =
    contentTypes.find((contentType: any) => {
      return contentType.sys.id === getContentTypeFromEntry(selectedEntry);
    }) || {};
  const displayField = contentType.displayField;
  return getFieldValue(selectedEntry, displayField, locale);
};

/**
 * Creates a name using the display field and the target language
 * @param entry
 * @param targetLanguage
 */
export const getEntryName = (
  entry: any,
  targetLanguage: string,
  allContentTypes: any[],
  sdk: EditorExtensionSDK,
) => {
  return (
    getDisplayField(entry, allContentTypes, sdk.locales.default) +
    ' - ' +
    sdk.locales.names[targetLanguage]
  );
};

export const getContentTypeFromEntry = (entry: any) => {
  if (isMediaLinkType(entry?.sys?.type)) return MEDIA_ASSETS_ID;
  return loGet(entry, 'sys.contentType.sys.id');
};

/**
 * Aad data to localized fields of the actual entry from the translated entry
 * @param actualEntry
 * @param selectedEntry
 * @param target language to use
 */
const buildFields = (actualEntry: any, selectedEntry: any, target = '') => {
  const fields = Object.assign({}, actualEntry.fields);
  target = target || selectedEntry.target;
  for (const translatedEntryField in selectedEntry.content) {
    if (fields[translatedEntryField]) {
      // Ignore content from local entry in case of missmatch fixed for issue (https://github.com/AcclaroInc/contentful-translations/issues/432)
      if (
        typeof (fields[translatedEntryField][target] ?? '') != 'string' &&
        fields[translatedEntryField][target].length !=
          selectedEntry.content[translatedEntryField].length
      ) {
        console.log('Error: content missmatch please check entry for missing references');
        continue;
      }
      fields[translatedEntryField][target] = selectedEntry.content[translatedEntryField];
    }
  }
  return fields;
};

/** Builds the entry in a format that is suitable ui sdk **/
const getEntryData = (entry: any, fields: any = null) => {
  fields = fields || entry.fields;
  return {
    sys: {
      id: loGet(entry, 'sys.id'),
      version: loGet(entry, 'sys.version'),
      type: loGet(entry, 'sys.type'),
    },
    fields,
  };
};

/**
 * instead of calling publish/update on every localized field update,
 * Build and store data in map which can later be used for bulk update of the entry
 **/
const processEntries = (entriesForProcessing: any, entry: any, selectionId: string) => {
  const idWithType = getEntryIdWithType(entry);
  let entryData = entriesForProcessing[idWithType];
  if (entryData) {
    entriesForProcessing[idWithType] = {
      entry: loMerge(entryData[entry], entry),
      selected: [...entryData['selected'], selectionId],
    };
  } else {
    entriesForProcessing[idWithType] = {
      entry,
      selected: [selectionId],
    };
  }
  return entriesForProcessing;
};

/**
 * appends the type of the entity(Asset or Entry) to the id of the entity
 */
export const getEntryIdWithType = (entry: { [k: string]: any }) => {
  let type = loGet(entry, 'sys.linkType', loGet(entry, 'sys.type', ''));
  if (type.length) {
    type = ENTRY_ID_SEPARATOR + type;
  }
  return entry.sys.id + type;
};

const getEntryById = async (entries: any, entryId: string, sdk: EditorExtensionSDK) => {
  let entry = entries.find((fullEntry: any) => fullEntry.sys.id == entryId);
  if (!entry) {
    entry = await sdk.cma.entry.get({ entryId: entryId });
  }
  return entry;
};

/**
 * Updates contentful entry
 */
export const updateEntry = async (
  localSelectedEntries: string[],
  entries: any[],
  sdk: EditorExtensionSDK,
  allContentTypes: any[],
  publish = false,
) => {
  const entryId = sdk.entry.getSys().id;
  const translationInfo = sdk.entry.fields.translationInfo.getValue();
  const isFieldLevelLocalization = sdk.entry.fields.localizationMethod.getValue() === FIELD_LEVEL;
  let entryContentStore = await DataManager.get(entryId);
  const referenceMaps: any =
    loGet(translationInfo, 'entry.localizedReferences') === true
      ? loGet(translationInfo, 'entry.referenceFieldMap')
      : {};
  // store a map of new entry ids that are created for a particular reference field
  let referenceToTargetMaps = translationInfo.referenceToTargetMaps || {};
  const defaultLocale = sdk.locales.default;
  const existingTranslatedEntries = entryContentStore.translatedEntries || [];
  // each language is a new file, but still part of the same entry
  // store all entry data here and process at once, to save multiple requests to update the same entry
  let entriesForProcessing: any = {};
  // loop over selected entries, get actual entry and translated entry merge the fields
  let hasError = false;
  const actualEntries: any[] = await getFullEntities(
    sdk,
    getEntryAndAssetIds(localSelectedEntries),
  );
  for (const translatedEntryId of localSelectedEntries) {
    const [contentfulId, targetLanguage, type] = splitEntryId(translatedEntryId);
    const selectedEntry = entries.find((entry) => {
      return (
        entry.entryId == contentfulId &&
        entry.target == targetLanguage &&
        (!type || entry.type == type)
      );
    });
    let actualEntry: any;
    let referenceFieldInfo: any;
    if (isTypeEntry(type)) {
      actualEntry = await getEntryById(actualEntries, contentfulId, sdk);
    } else {
      actualEntry = await getAssetById(actualEntries, contentfulId, sdk);
    }

    const idWithType = getEntryIdWithType(actualEntry);

    // check if this a reference field
    referenceFieldInfo = referenceMaps[idWithType];
    /**
     * if reference field build entry data and call create entry
     */
    if (referenceFieldInfo) {
      // on entry level we put the data in the source language fields thus using default locale
      // otherwise just put in the target fields
      const fields = buildFields(
        actualEntry,
        selectedEntry,
        isFieldLevelLocalization ? '' : defaultLocale,
      );

      let referenceEntry: any;

      //  for field level fetch the actual entry, for entry level create a new entry or update previously
      // created entry(last time when publish was run)
      if (isFieldLevelLocalization) {
        referenceEntry = entriesForProcessing[idWithType]
          ? entriesForProcessing[idWithType]['entry']
          : actualEntry;
        referenceEntry.fields = fields;
      } else {
        if (referenceToTargetMaps[translatedEntryId]) {
          try {
            if (isTypeEntry(type)) {
              referenceEntry = await sdk.cma.entry.get({ entryId: translatedEntryId });
            } else {
              referenceEntry = await sdk.cma.asset.get({ assetId: translatedEntryId });
            }
            referenceEntry.fields = fields;
          } catch (Error) {
            // if entry not found
            referenceEntry = null;
          }
        }
        if (!referenceEntry) {
          const contentType = getContentTypeFromEntry(actualEntry);
          // for media asset if we create a new media, we need the file field. So we copy over the URL and upload the file from URL using space.processAsset
          if (isMediaAsset(contentType)) {
            const fileURL = loGet(fields, `file.${defaultLocale}.url`);
            fields['file'][defaultLocale]['upload'] = 'https:' + fileURL;
            delete fields['file'][defaultLocale]['url'];
            delete fields['file'][defaultLocale]['details'];
            referenceEntry = await sdk.cma.asset.create(sdk.cma.environment, fields);
            if (fileURL) {
              referenceEntry = await sdk.cma.asset.processForLocale(
                sdk.cma.environment,
                referenceEntry,
                defaultLocale,
              );
            }
          } else {
            referenceEntry = await sdk.cma.entry.create({ contentTypeId: contentType }, fields);
          }
          referenceToTargetMaps[translatedEntryId] = referenceEntry.sys.id;
        }
      }

      entriesForProcessing = processEntries(
        entriesForProcessing,
        getEntryData(referenceEntry),
        translatedEntryId,
      );
      //  update the uplink field
      for (const info of referenceFieldInfo) {
        // Uplink can only be an entry as assets won't have reference fields
        const infoIdWithType = info['upLinkId'] + (ENTRY_ID_SEPARATOR + TYPE_ENTRY);
        // fetch the uplinked field either it will be in the store or fetch new
        const uplinkedEntry = entriesForProcessing[infoIdWithType]
          ? entriesForProcessing[infoIdWithType]['entry']
          : await getEntryById(actualEntries, info['upLinkId'], sdk);
        // if the field exists in the uplinked entry
        if (uplinkedEntry.fields[info['field']]) {
          let data = {
            sys: {
              type: 'Link',
              linkType: info['linkType'],
              id: referenceEntry.sys.id,
            },
          };
          // the uplinked field can be an array or an simple link
          // if array we need check array if entry already is there, update or push based on that.
          if (info['type'] == FIELD_ARRAY) {
            let fieldData = uplinkedEntry.fields[info['field']][selectedEntry.target] || [];
            let fieldEntryIndex = fieldData.findIndex((fieldEntry: any) => {
              return loGet(fieldEntry, 'sys.id') === data.sys.id;
            });
            if (fieldEntryIndex > -1) {
              fieldData.splice(fieldEntryIndex, 1, data);
            } else {
              fieldData.push(data);
            }
            uplinkedEntry.fields[info['field']][selectedEntry.target] = fieldData;
          } else {
            uplinkedEntry.fields[info['field']][selectedEntry.target] = data;
          }
        }
        entriesForProcessing = processEntries(
          entriesForProcessing,
          getEntryData(uplinkedEntry),
          translatedEntryId,
        );
      }
    } else {
      const entryToProcess = entriesForProcessing[idWithType]
        ? entriesForProcessing[idWithType]['entry']
        : actualEntry;
      entryToProcess.fields = buildFields(entryToProcess, selectedEntry);
      entriesForProcessing = processEntries(
        entriesForProcessing,
        getEntryData(entryToProcess),
        translatedEntryId,
      );
    }
  }
  /**
   * For trackChanges adding these entries to ignore list as these are getting updated by our code not by user
   */
  translationInfo.entriesToIgnore = {
    entries: Object.keys(entriesForProcessing),
    time: new Date().getTime(),
  };

  try {
    let translationProjectEntry: any = await sdk.cma.entry.get({ entryId: entryId });
    let defaultLocale = sdk.locales.default;
    translationProjectEntry.fields.translationInfo[defaultLocale] = translationInfo;
    await sdk.cma.entry.update({ entryId: entryId }, translationProjectEntry);
    let updateStatusForEntries: string[] = [];
    for (const processEntryId in entriesForProcessing) {
      try {
        let entry: any;
        const [id, type] = splitEntryId(processEntryId);
        if (isTypeEntry(type)) {
          entry = await sdk.cma.entry.update(
            { entryId: id },
            entriesForProcessing[processEntryId]['entry'],
          );
          if (publish) {
            entry = await sdk.cma.entry.publish({ entryId: id }, entry);
          }
        } else {
          entry = await sdk.cma.asset.update(
            { assetId: id },
            entriesForProcessing[processEntryId]['entry'],
          );
          if (publish) {
            entry = await sdk.cma.asset.publish({ assetId: id }, entry);
          }
        }
        updateStatusForEntries = [
          ...updateStatusForEntries,
          ...entriesForProcessing[processEntryId]['selected'],
        ];
      } catch (Error: any) {
        const uniqueItems = uniqueArray(entriesForProcessing[processEntryId]['selected'] || []);
        let entriesWithErrors = [];
        for (const entryId of uniqueItems) {
          const [id, target, type] = splitEntryId(entryId);
          let actualEntry: any;
          if (isTypeEntry(type)) {
            actualEntry = await getEntryById(actualEntries, id, sdk);
          } else {
            actualEntry = await getEntryById(actualEntries, id, sdk);
          }
          entriesWithErrors.push(getEntryName(actualEntry, target, allContentTypes, sdk));
        }
        hasError = true;
        let error = JSON.parse(Error.message);
        sdk.notifier.error(
          `Error processing: ${entriesWithErrors.join(', ')} - ` +
            error.message +
            getErrorInfo(error),
        );
      }
    }
    updateStatusForEntries = uniqueArray(updateStatusForEntries);
    for (let entryToUpdate of updateStatusForEntries) {
      const entryIndex = existingTranslatedEntries.findIndex((storedEntry: any) => {
        const [id, target, type] = splitEntryId(entryToUpdate);
        return (
          storedEntry.entryId == id &&
          storedEntry.target == target &&
          (!type || storedEntry.type == type)
        );
      });
      existingTranslatedEntries[entryIndex]['status'] = publish
        ? STATUS_PUBLISHED
        : STATUS_READY_PUBLISH;
      existingTranslatedEntries[entryIndex]['status_updated_at'] = new Date().getTime();
    }
    entryContentStore.translatedEntries = existingTranslatedEntries;
    await DataManager.store(entryId, entryContentStore);
    translationInfo.referenceToTargetMaps = referenceToTargetMaps;
    await sdk.entry.fields.translationInfo.setValue(translationInfo);

    if (!hasError) {
      sdk.notifier.success('Updated selected entries');
    }
  } catch (e) {
    sdk.notifier.error('There was an error. Please try again');
  }
};

export const getErrorInfo = (error: any) => {
  let errors = '';
  if (error.details && error.details.errors && Symbol.iterator in Object(error.details.errors)) {
    for (let item of error.details.errors) {
      errors += ` - ${item.details} : ${item.path}`;
    }
  }
  return errors;
};

/** Local: add a comment inside the file data and remove the translated entry from data
 * Acclaro : post comment to acclaro
 * **/
export const requestChanges = async (entryId: string, sdk: EditorExtensionSDK) => {
  let response = await sdk.dialogs.openPrompt({
    title: 'Provide feedback',
    message: 'Leave a comment',
    intent: 'primary',
    confirmLabel: 'Submit feedback',
    cancelLabel: 'Cancel',
  });
  if (response) {
    const [contentfulId, targetLanguage] = splitEntryId(entryId);
    let entryContentStore = await DataManager.get(sdk.entry.getSys().id);
    const translationInfo = sdk.entry.fields.translationInfo.getValue();
    const translatorType = loGet(translationInfo, `entry.type`);
    const fileData = entryContentStore.fileData || [];
    const fileIndex = fileData.findIndex((storedEntry: any) => {
      return storedEntry.entryId == contentfulId && storedEntry.target == targetLanguage;
    });
    const existingTranslatedEntries = entryContentStore.translatedEntries || [];
    const entryIndex = existingTranslatedEntries.findIndex((storedEntry: any) => {
      return storedEntry.entryId == contentfulId && storedEntry.target == targetLanguage;
    });

    const translatedEntry = existingTranslatedEntries[entryIndex];

    let feedbackSubmitted = true;
    if (translatorType === OPTION_ACCLARO) {
      const feedbackResponse = await localClient.sendFeedbackAcclaro({
        orderId: loGet(translationInfo, 'acclaroStatus.orderId'),
        fileId: translatedEntry.acclaroFileId,
        apiKey: loGet(translationInfo, 'entry.apiKey'),
        sandbox: loGet(translationInfo, 'entry.sandbox'),
        comment: response,
      });
      if (feedbackResponse.success) {
        sdk.notifier.success('Feedback sent to Acclaro.');
      } else {
        feedbackSubmitted = false;
        sdk.notifier.error(feedbackResponse.error);
      }
    } else {
      sdk.notifier.success(
        'Feedback added. Please download the file and send it to the selected translator.',
      );
    }
    if (feedbackSubmitted) {
      existingTranslatedEntries[entryIndex].hasFeedback = true;
      fileData[fileIndex].requestedFeedback = fileData[fileIndex].requestedFeedback || [];
      fileData[fileIndex].requestedFeedback.push({
        comment: response,
        time: dateTimeFormat(),
      });
      entryContentStore.translatedEntries = existingTranslatedEntries;
      entryContentStore.fileData = fileData;
      await DataManager.storeAndRefreshTranslationInfo(sdk, entryContentStore);
    }
  }
};

/**
 * Does the field link to an entry or an asset?
 * @param fieldDef
 */
export const isFieldReference = (fieldDef: any) => {
  return (loGet(fieldDef, 'items.type') || loGet(fieldDef, 'type')) == 'Link';
};

/**
 * check if there is an activated Acclaro translator
 * @param sdk
 */
export const hasAcclaroTranslator = async (sdk: KnownSDK): Promise<boolean> => {
  const locale = sdk.locales.default;
  const entries: any = await sdk.cma.entry?.getMany({
    // @ts-ignore
    query: {
      content_type: sdk.parameters.installation.translatorModelId,
      [`fields.translationService.${locale}`]: OPTION_ACCLARO,
      'sys.publishedAt[exists]': true,
    },
  });
  const items = entries?.items;
  return (
    entries?.total > 0 &&
    items.some((item: any) => {
      return loGet(item, `fields.translatorInfo.${locale}.isAuthenticated`) == true;
    })
  );
};

export const getViewAllLink = (ids: any, translationsModelId: string) => {
  const { space, environment, entry } = ids;
  const contentType = translationsModelId;
  let translatorFilter = '';
  if (entry) {
    translatorFilter = `id=${entry}&filters.0.val=${entry}&filters.0.key=fields.translator.sys.id&`;
  }
  return (
    `https://app.contentful.com/spaces/${space}/environments/` +
    `${environment}/entries?${translatorFilter}order.fieldId=updatedAt&order.direction=descending&` +
    `folderId=DEkHDy9ZckxB5VmF&contentTypeId=${contentType}&displayedFieldIds=contentType&displayedFieldIds=updatedAt` +
    `&displayedFieldIds=author`
  );
};
