import React from 'react';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import { Flex, Button, Menu } from '@contentful/f36-components';
import { ChevronDownIcon } from '@contentful/f36-icons';
import EntryModal from './EntryModal';
import { EntryContentStore } from '../../../../../lambda/src/utils/data-manager/data.manager';

interface Props {
  sdk: EditorExtensionSDK;
  handleSelectedLanguage: Function;
  translationInfo: any;
  selectedEntries: string[];
  entryContentStore: EntryContentStore;
  entries: any[];
}

interface State {
  isOpen: boolean;
  selectedLanguages: string[];
  selectedLanguage: string;
  showReviewModal: boolean;
}

export default class DetailsToolbar extends React.Component<Props, State> {
  state: State = {
    isOpen: false,
    selectedLanguages: [],
    selectedLanguage: '',
    showReviewModal: false,
  };

  async componentDidMount() {
    await this.handleMount();
  }

  handleMount = async () => {
    this.setState({
      selectedLanguages: this.props.sdk.entry.fields.targetLanguages.getValue() || [],
    });
  };

  closeDropdown = () => {
    this.setState({ isOpen: false });
  };

  openDropdown = () => {
    this.setState({ isOpen: true });
  };

  closeModal = () => {
    this.setState({ showReviewModal: false });
  };

  render() {
    return (
      <>
        <Flex className="details-toolbar">
          <div>
            <Menu
              isOpen={this.state.isOpen}
              onClose={() => {
                this.closeDropdown();
              }}
            >
              <Menu.Trigger>
                <Button
                  variant="primary"
                  onClick={() => {
                    this.openDropdown();
                  }}
                  endIcon={<ChevronDownIcon />}
                >
                  {this.state.selectedLanguage || 'Filter by language'}
                </Button>
              </Menu.Trigger>
              <Menu.List>
                {this.state.selectedLanguage != '' && (
                  <Menu.Item
                    onClick={() => {
                      this.setState({
                        selectedLanguage: '',
                      });
                      this.props.handleSelectedLanguage('');
                      this.closeDropdown();
                    }}
                  >
                    Show all
                  </Menu.Item>
                )}

                {this.state.selectedLanguages.map((selectedLanguage: string) => {
                  return (
                    <Menu.Item
                      key={selectedLanguage}
                      onClick={() => {
                        this.setState({
                          selectedLanguage: this.props.sdk.locales.names[selectedLanguage],
                        });
                        this.props.handleSelectedLanguage(selectedLanguage);
                        this.closeDropdown();
                      }}
                    >
                      {this.props.sdk.locales.names[selectedLanguage]}
                    </Menu.Item>
                  );
                })}
              </Menu.List>
            </Menu>
          </div>
          <div>
            <Button
              variant="primary"
              onClick={() => this.setState({ showReviewModal: true })}
              isDisabled={this.props.selectedEntries.length < 1}
            >
              Review and Publish
            </Button>
          </div>
        </Flex>
        {this.state.showReviewModal && (
          <EntryModal
            sdk={this.props.sdk}
            translationInfo={this.props.translationInfo}
            selectedEntries={this.props.selectedEntries}
            entryContentStore={this.props.entryContentStore}
            entries={this.props.entries}
            showModal={this.state.showReviewModal}
            closeModal={this.closeModal}
          />
        )}
      </>
    );
  }
}
