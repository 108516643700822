//@ts-ignore
//@ts-ignore
import namibia from '../../assets/flags/namibia.svg';
//@ts-ignore
import southafrica from '../../assets/flags/south-africa.svg';
//@ts-ignore
import cameroon from '../../assets/flags/cameroon.svg';
//@ts-ignore
import ghana from '../../assets/flags/ghana.svg';
//@ts-ignore
import albania from '../../assets/flags/albania.svg';
//@ts-ignore
import ethiopia from '../../assets/flags/ethiopia.svg';
//@ts-ignore
import algeria from '../../assets/flags/algeria.svg';
//@ts-ignore
import bahrain from '../../assets/flags/bahrain.svg';
//@ts-ignore
import egypt from '../../assets/flags/egypt.svg';
//@ts-ignore
import iraq from '../../assets/flags/iraq.svg';
//@ts-ignore
import jordan from '../../assets/flags/jordan.svg';
//@ts-ignore
import kuwait from '../../assets/flags/kuwait.svg';
//@ts-ignore
import lebanon from '../../assets/flags/lebanon.svg';
//@ts-ignore
import libya from '../../assets/flags/libya.svg';
//@ts-ignore
import morocco from '../../assets/flags/morocco.svg';
//@ts-ignore
import oman from '../../assets/flags/oman.svg';
//@ts-ignore
import qatar from '../../assets/flags/qatar.svg';
//@ts-ignore
import saudiarabia from '../../assets/flags/saudi-arabia.svg';
//@ts-ignore
import sudan from '../../assets/flags/sudan.svg';
//@ts-ignore
import syria from '../../assets/flags/syria.svg';
//@ts-ignore
import tunisia from '../../assets/flags/tunisia.svg';
//@ts-ignore
import unitedarabemirates from '../../assets/flags/united-arab-emirates.svg';
//@ts-ignore
import yemen from '../../assets/flags/yemen.svg';
//@ts-ignore
import armenia from '../../assets/flags/armenia.svg';
//@ts-ignore
import india from '../../assets/flags/india.svg';
//@ts-ignore
import tanzania from '../../assets/flags/tanzania.svg';
//@ts-ignore
import mali from '../../assets/flags/mali.svg';
//@ts-ignore
import spain from '../../assets/flags/spain.svg';
//@ts-ignore
import belarus from '../../assets/flags/belarus.svg';
//@ts-ignore
import zambia from '../../assets/flags/zambia.svg';
//@ts-ignore
import bangladesh from '../../assets/flags/bangladesh.svg';
//@ts-ignore
import bosniaandherzegovina from '../../assets/flags/bosnia-and-herzegovina.svg';
//@ts-ignore
import france from '../../assets/flags/france.svg';
//@ts-ignore
import bulgaria from '../../assets/flags/bulgaria.svg';
//@ts-ignore
import myanmar from '../../assets/flags/myanmar.svg';
//@ts-ignore
import unitedstatesofamerica from '../../assets/flags/united-states-of-america.svg';
//@ts-ignore
import uganda from '../../assets/flags/uganda.svg';
//@ts-ignore
import china from '../../assets/flags/china.svg';
//@ts-ignore
import unitedkingdom from '../../assets/flags/united-kingdom.svg';
//@ts-ignore
import croatia from '../../assets/flags/croatia.svg';
//@ts-ignore
import czechrepublic from '../../assets/flags/czech-republic.svg';
//@ts-ignore
import denmark from '../../assets/flags/denmark.svg';
//@ts-ignore
import aruba from '../../assets/flags/aruba.svg';
//@ts-ignore
import belgium from '../../assets/flags/belgium.svg';
//@ts-ignore
import netherlands from '../../assets/flags/netherlands.svg';
//@ts-ignore
import sintmaarten from '../../assets/flags/sint-maarten.svg';
//@ts-ignore
import kenya from '../../assets/flags/kenya.svg';
//@ts-ignore
import americansamoa from '../../assets/flags/american-samoa.svg';
//@ts-ignore
import australia from '../../assets/flags/australia.svg';
//@ts-ignore
import barbados from '../../assets/flags/barbados.svg';
//@ts-ignore
import belize from '../../assets/flags/belize.svg';
//@ts-ignore
import bermuda from '../../assets/flags/bermuda.svg';
//@ts-ignore
import botswana from '../../assets/flags/botswana.svg';
//@ts-ignore
import canada from '../../assets/flags/canada.svg';
//@ts-ignore
import gambia from '../../assets/flags/gambia.svg';
//@ts-ignore
import guyana from '../../assets/flags/guyana.svg';
//@ts-ignore
import hongkong from '../../assets/flags/hong-kong.svg';
//@ts-ignore
import ireland from '../../assets/flags/ireland.svg';
//@ts-ignore
import jamaica from '../../assets/flags/jamaica.svg';
//@ts-ignore
import malta from '../../assets/flags/malta.svg';
//@ts-ignore
import mauritius from '../../assets/flags/mauritius.svg';
//@ts-ignore
import newzealand from '../../assets/flags/new-zealand.svg';
//@ts-ignore
import northernmarianasislands from '../../assets/flags/northern-marianas-islands.svg';
//@ts-ignore
import pakistan from '../../assets/flags/pakistan.svg';
//@ts-ignore
import philippines from '../../assets/flags/philippines.svg';
//@ts-ignore
import singapore from '../../assets/flags/singapore.svg';
//@ts-ignore
import virginislands from '../../assets/flags/virgin-islands.svg';
//@ts-ignore
import zimbabwe from '../../assets/flags/zimbabwe.svg';
//@ts-ignore
import estonia from '../../assets/flags/estonia.svg';
//@ts-ignore
import togo from '../../assets/flags/togo.svg';
//@ts-ignore
import faroeislands from '../../assets/flags/faroe-islands.svg';
//@ts-ignore
import finland from '../../assets/flags/finland.svg';
//@ts-ignore
import benin from '../../assets/flags/benin.svg';
//@ts-ignore
import burkinafaso from '../../assets/flags/burkina-faso.svg';
//@ts-ignore
import burundi from '../../assets/flags/burundi.svg';
//@ts-ignore
import centralafricanrepublic from '../../assets/flags/central-african-republic.svg';
//@ts-ignore
import chad from '../../assets/flags/chad.svg';
//@ts-ignore
import comoros from '../../assets/flags/comoros.svg';
//@ts-ignore
import republicofthecongo from '../../assets/flags/republic-of-the-congo.svg';
//@ts-ignore
import democraticrepublicofcongo from '../../assets/flags/democratic-republic-of-congo.svg';
//@ts-ignore
import djibouti from '../../assets/flags/djibouti.svg';
//@ts-ignore
import equatorialguinea from '../../assets/flags/equatorial-guinea.svg';
//@ts-ignore
import gabon from '../../assets/flags/gabon.svg';
//@ts-ignore
import guinea from '../../assets/flags/guinea.svg';
//@ts-ignore
import luxembourg from '../../assets/flags/luxembourg.svg';
//@ts-ignore
import madagascar from '../../assets/flags/madagascar.svg';
//@ts-ignore
import martinique from '../../assets/flags/martinique.svg';
//@ts-ignore
import monaco from '../../assets/flags/monaco.svg';
//@ts-ignore
import niger from '../../assets/flags/niger.svg';
//@ts-ignore
import rwanda from '../../assets/flags/rwanda.svg';
//@ts-ignore
import senegal from '../../assets/flags/senegal.svg';
//@ts-ignore
import switzerland from '../../assets/flags/switzerland.svg';
//@ts-ignore
import georgia from '../../assets/flags/georgia.svg';
//@ts-ignore
import austria from '../../assets/flags/austria.svg';
//@ts-ignore
import germany from '../../assets/flags/germany.svg';
//@ts-ignore
import liechtenstein from '../../assets/flags/liechtenstein.svg';
//@ts-ignore
import cyprus from '../../assets/flags/cyprus.svg';
//@ts-ignore
import greece from '../../assets/flags/greece.svg';
//@ts-ignore
import israel from '../../assets/flags/israel.svg';
//@ts-ignore
import hungary from '../../assets/flags/hungary.svg';
//@ts-ignore
import iceland from '../../assets/flags/iceland.svg';
//@ts-ignore
import indonesia from '../../assets/flags/indonesia.svg';
//@ts-ignore
import italy from '../../assets/flags/italy.svg';
//@ts-ignore
import japan from '../../assets/flags/japan.svg';
//@ts-ignore
import capeverde from '../../assets/flags/cape-verde.svg';
//@ts-ignore
import greenland from '../../assets/flags/greenland.svg';
//@ts-ignore
import cambodia from '../../assets/flags/cambodia.svg';
//@ts-ignore
import southkorea from '../../assets/flags/south-korea.svg';
//@ts-ignore
import latvia from '../../assets/flags/latvia.svg';
//@ts-ignore
import lithuania from '../../assets/flags/lithuania.svg';
//@ts-ignore
import mozambique from '../../assets/flags/mozambique.svg';
//@ts-ignore
import malaysia from '../../assets/flags/malaysia.svg';
//@ts-ignore
import nepal from '../../assets/flags/nepal.svg';
//@ts-ignore
import norway from '../../assets/flags/norway.svg';
//@ts-ignore
import afghanistan from '../../assets/flags/afghanistan.svg';
//@ts-ignore
import iran from '../../assets/flags/iran.svg';
//@ts-ignore
import republicofpoland from '../../assets/flags/republic-of-poland.svg';
//@ts-ignore
import angola from '../../assets/flags/angola.svg';
//@ts-ignore
import brazil from '../../assets/flags/brazil.svg';
//@ts-ignore
import guineabissau from '../../assets/flags/guinea-bissau.svg';
//@ts-ignore
import portugal from '../../assets/flags/portugal.svg';
//@ts-ignore
import moldova from '../../assets/flags/moldova.svg';
//@ts-ignore
import romania from '../../assets/flags/romania.svg';
//@ts-ignore
import russia from '../../assets/flags/russia.svg';
//@ts-ignore
import ukraine from '../../assets/flags/ukraine.svg';
//@ts-ignore
import srilanka from '../../assets/flags/sri-lanka.svg';
//@ts-ignore
import slovakia from '../../assets/flags/slovakia.svg';
//@ts-ignore
import slovenia from '../../assets/flags/slovenia.svg';
//@ts-ignore
import somalia from '../../assets/flags/somalia.svg';
//@ts-ignore
import argentina from '../../assets/flags/argentina.svg';
//@ts-ignore
import bolivia from '../../assets/flags/bolivia.svg';
//@ts-ignore
import chile from '../../assets/flags/chile.svg';
//@ts-ignore
import colombia from '../../assets/flags/colombia.svg';
//@ts-ignore
import costarica from '../../assets/flags/costa-rica.svg';
//@ts-ignore
import dominicanrepublic from '../../assets/flags/dominican-republic.svg';
//@ts-ignore
import ecuador from '../../assets/flags/ecuador.svg';
//@ts-ignore
import salvador from '../../assets/flags/salvador.svg';
//@ts-ignore
import guatemala from '../../assets/flags/guatemala.svg';
//@ts-ignore
import honduras from '../../assets/flags/honduras.svg';
//@ts-ignore
import mexico from '../../assets/flags/mexico.svg';
//@ts-ignore
import nicaragua from '../../assets/flags/nicaragua.svg';
//@ts-ignore
import panama from '../../assets/flags/panama.svg';
//@ts-ignore
import paraguay from '../../assets/flags/paraguay.svg';
//@ts-ignore
import peru from '../../assets/flags/peru.svg';
//@ts-ignore
import puertorico from '../../assets/flags/puerto-rico.svg';
//@ts-ignore
import uruguay from '../../assets/flags/uruguay.svg';
//@ts-ignore
import venezuela from '../../assets/flags/venezuela.svg';
//@ts-ignore
import sweden from '../../assets/flags/sweden.svg';
//@ts-ignore
import thailand from '../../assets/flags/thailand.svg';
//@ts-ignore
import eritrea from '../../assets/flags/eritrea.svg';
//@ts-ignore
import tonga from '../../assets/flags/tonga.svg';
//@ts-ignore
import turkey from '../../assets/flags/turkey.svg';
//@ts-ignore
import vietnam from '../../assets/flags/vietnam.svg';
let allFlags: any = {};
allFlags['namibia.svg'] = namibia;
allFlags['south-africa.svg'] = southafrica;
allFlags['cameroon.svg'] = cameroon;
allFlags['ghana.svg'] = ghana;
allFlags['albania.svg'] = albania;
allFlags['ethiopia.svg'] = ethiopia;
allFlags['algeria.svg'] = algeria;
allFlags['bahrain.svg'] = bahrain;
allFlags['egypt.svg'] = egypt;
allFlags['iraq.svg'] = iraq;
allFlags['jordan.svg'] = jordan;
allFlags['kuwait.svg'] = kuwait;
allFlags['lebanon.svg'] = lebanon;
allFlags['libya.svg'] = libya;
allFlags['morocco.svg'] = morocco;
allFlags['oman.svg'] = oman;
allFlags['qatar.svg'] = qatar;
allFlags['saudi-arabia.svg'] = saudiarabia;
allFlags['sudan.svg'] = sudan;
allFlags['syria.svg'] = syria;
allFlags['tunisia.svg'] = tunisia;
allFlags['united-arab-emirates.svg'] = unitedarabemirates;
allFlags['yemen.svg'] = yemen;
allFlags['armenia.svg'] = armenia;
allFlags['india.svg'] = india;
allFlags['tanzania.svg'] = tanzania;
allFlags['mali.svg'] = mali;
allFlags['spain.svg'] = spain;
allFlags['belarus.svg'] = belarus;
allFlags['zambia.svg'] = zambia;
allFlags['bangladesh.svg'] = bangladesh;
allFlags['bosnia-and-herzegovina.svg'] = bosniaandherzegovina;
allFlags['france.svg'] = france;
allFlags['bulgaria.svg'] = bulgaria;
allFlags['myanmar.svg'] = myanmar;
allFlags['united-states-of-america.svg'] = unitedstatesofamerica;
allFlags['uganda.svg'] = uganda;
allFlags['china.svg'] = china;
allFlags['united-kingdom.svg'] = unitedkingdom;
allFlags['croatia.svg'] = croatia;
allFlags['czech-republic.svg'] = czechrepublic;
allFlags['denmark.svg'] = denmark;
allFlags['aruba.svg'] = aruba;
allFlags['belgium.svg'] = belgium;
allFlags['netherlands.svg'] = netherlands;
allFlags['sint-maarten.svg'] = sintmaarten;
allFlags['kenya.svg'] = kenya;
allFlags['american-samoa.svg'] = americansamoa;
allFlags['australia.svg'] = australia;
allFlags['barbados.svg'] = barbados;
allFlags['belize.svg'] = belize;
allFlags['bermuda.svg'] = bermuda;
allFlags['botswana.svg'] = botswana;
allFlags['canada.svg'] = canada;
allFlags['gambia.svg'] = gambia;
allFlags['guyana.svg'] = guyana;
allFlags['hong-kong.svg'] = hongkong;
allFlags['ireland.svg'] = ireland;
allFlags['jamaica.svg'] = jamaica;
allFlags['malta.svg'] = malta;
allFlags['mauritius.svg'] = mauritius;
allFlags['new-zealand.svg'] = newzealand;
allFlags['northern-marianas-islands.svg'] = northernmarianasislands;
allFlags['pakistan.svg'] = pakistan;
allFlags['philippines.svg'] = philippines;
allFlags['singapore.svg'] = singapore;
allFlags['virgin-islands.svg'] = virginislands;
allFlags['zimbabwe.svg'] = zimbabwe;
allFlags['estonia.svg'] = estonia;
allFlags['togo.svg'] = togo;
allFlags['faroe-islands.svg'] = faroeislands;
allFlags['finland.svg'] = finland;
allFlags['benin.svg'] = benin;
allFlags['burkina-faso.svg'] = burkinafaso;
allFlags['burundi.svg'] = burundi;
allFlags['central-african-republic.svg'] = centralafricanrepublic;
allFlags['chad.svg'] = chad;
allFlags['comoros.svg'] = comoros;
allFlags['republic-of-the-congo.svg'] = republicofthecongo;
allFlags['democratic-republic-of-congo.svg'] = democraticrepublicofcongo;
allFlags['djibouti.svg'] = djibouti;
allFlags['equatorial-guinea.svg'] = equatorialguinea;
allFlags['gabon.svg'] = gabon;
allFlags['guinea.svg'] = guinea;
allFlags['luxembourg.svg'] = luxembourg;
allFlags['madagascar.svg'] = madagascar;
allFlags['martinique.svg'] = martinique;
allFlags['monaco.svg'] = monaco;
allFlags['niger.svg'] = niger;
allFlags['rwanda.svg'] = rwanda;
allFlags['senegal.svg'] = senegal;
allFlags['switzerland.svg'] = switzerland;
allFlags['georgia.svg'] = georgia;
allFlags['austria.svg'] = austria;
allFlags['germany.svg'] = germany;
allFlags['liechtenstein.svg'] = liechtenstein;
allFlags['cyprus.svg'] = cyprus;
allFlags['greece.svg'] = greece;
allFlags['israel.svg'] = israel;
allFlags['hungary.svg'] = hungary;
allFlags['iceland.svg'] = iceland;
allFlags['indonesia.svg'] = indonesia;
allFlags['italy.svg'] = italy;
allFlags['japan.svg'] = japan;
allFlags['cape-verde.svg'] = capeverde;
allFlags['greenland.svg'] = greenland;
allFlags['cambodia.svg'] = cambodia;
allFlags['south-korea.svg'] = southkorea;
allFlags['latvia.svg'] = latvia;
allFlags['lithuania.svg'] = lithuania;
allFlags['mozambique.svg'] = mozambique;
allFlags['malaysia.svg'] = malaysia;
allFlags['nepal.svg'] = nepal;
allFlags['norway.svg'] = norway;
allFlags['afghanistan.svg'] = afghanistan;
allFlags['iran.svg'] = iran;
allFlags['republic-of-poland.svg'] = republicofpoland;
allFlags['angola.svg'] = angola;
allFlags['brazil.svg'] = brazil;
allFlags['guinea-bissau.svg'] = guineabissau;
allFlags['portugal.svg'] = portugal;
allFlags['moldova.svg'] = moldova;
allFlags['romania.svg'] = romania;
allFlags['russia.svg'] = russia;
allFlags['ukraine.svg'] = ukraine;
allFlags['sri-lanka.svg'] = srilanka;
allFlags['slovakia.svg'] = slovakia;
allFlags['slovenia.svg'] = slovenia;
allFlags['somalia.svg'] = somalia;
allFlags['argentina.svg'] = argentina;
allFlags['bolivia.svg'] = bolivia;
allFlags['chile.svg'] = chile;
allFlags['colombia.svg'] = colombia;
allFlags['costa-rica.svg'] = costarica;
allFlags['dominican-republic.svg'] = dominicanrepublic;
allFlags['ecuador.svg'] = ecuador;
allFlags['salvador.svg'] = salvador;
allFlags['guatemala.svg'] = guatemala;
allFlags['honduras.svg'] = honduras;
allFlags['mexico.svg'] = mexico;
allFlags['nicaragua.svg'] = nicaragua;
allFlags['panama.svg'] = panama;
allFlags['paraguay.svg'] = paraguay;
allFlags['peru.svg'] = peru;
allFlags['puerto-rico.svg'] = puertorico;
allFlags['uruguay.svg'] = uruguay;
allFlags['venezuela.svg'] = venezuela;
allFlags['sweden.svg'] = sweden;
allFlags['thailand.svg'] = thailand;
allFlags['eritrea.svg'] = eritrea;
allFlags['tonga.svg'] = tonga;
allFlags['turkey.svg'] = turkey;
allFlags['vietnam.svg'] = vietnam;

//@ts-ignore
import globe from '../../assets/flags/globe.svg';
//@ts-ignore
import acclaro from '../../assets/service/acclaro.svg';
//@ts-ignore
import exportImport from '../../assets/service/export-import.svg';
import { OPTION_ACCLARO, OPTION_LOCAL } from '../../config/translatorModel';

const flagMap = [
  {
    code: 'af',
    name: 'Afrikaans (af)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'af-NA',
    name: 'Afrikaans (Namibia) (af-NA)',
    country: 'Namibia',
    code3: 'NAM',
    flag: 'namibia.svg',
  },
  {
    code: 'af-ZA',
    name: 'Afrikaans (South Africa) (af-ZA)',
    country: 'South Africa',
    code3: 'ZAF',
    flag: 'south-africa.svg',
  },
  {
    code: 'agq',
    name: 'Aghem (agq)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'agq-CM',
    name: 'Aghem (Cameroon) (agq-CM)',
    country: 'Cameroon',
    code3: 'CMR',
    flag: 'cameroon.svg',
  },
  {
    code: 'ak',
    name: 'Akan (ak)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ak-GH',
    name: 'Akan (Ghana) (ak-GH)',
    country: 'Ghana',
    code3: 'GHA',
    flag: 'ghana.svg',
  },
  {
    code: 'sq',
    name: 'Albanian (sq)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sq-AL',
    name: 'Albanian (Albania) (sq-AL)',
    country: 'Albania',
    code3: 'ALB',
    flag: 'albania.svg',
  },
  {
    code: 'am',
    name: 'Amharic (am)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'am-ET',
    name: 'Amharic (Ethiopia) (am-ET)',
    country: 'Ethiopia',
    code3: 'ETH',
    flag: 'ethiopia.svg',
  },
  {
    code: 'ar',
    name: 'Arabic (ar)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ar-DZ',
    name: 'Arabic (Algeria) (ar-DZ)',
    country: 'Algeria',
    code3: 'DZA',
    flag: 'algeria.svg',
  },
  {
    code: 'ar-BH',
    name: 'Arabic (Bahrain) (ar-BH)',
    country: 'Bahrain',
    code3: 'BHR',
    flag: 'bahrain.svg',
  },
  {
    code: 'ar-EG',
    name: 'Arabic (Egypt) (ar-EG)',
    country: 'Egypt',
    code3: 'EGY',
    flag: 'egypt.svg',
  },
  {
    code: 'ar-IQ',
    name: 'Arabic (Iraq) (ar-IQ)',
    country: 'Iraq',
    code3: 'IRQ',
    flag: 'iraq.svg',
  },
  {
    code: 'ar-JO',
    name: 'Arabic (Jordan) (ar-JO)',
    country: 'Jordan',
    code3: 'JOR',
    flag: 'jordan.svg',
  },
  {
    code: 'ar-KW',
    name: 'Arabic (Kuwait) (ar-KW)',
    country: 'Kuwait',
    code3: 'KWT',
    flag: 'kuwait.svg',
  },
  {
    code: 'ar-LB',
    name: 'Arabic (Lebanon) (ar-LB)',
    country: 'Lebanon',
    code3: 'LBN',
    flag: 'lebanon.svg',
  },
  {
    code: 'ar-LY',
    name: 'Arabic (Libya) (ar-LY)',
    country: 'Libya',
    code3: 'LBY',
    flag: 'libya.svg',
  },
  {
    code: 'ar-MA',
    name: 'Arabic (Morocco) (ar-MA)',
    country: 'Morocco',
    code3: 'MAR',
    flag: 'morocco.svg',
  },
  {
    code: 'ar-OM',
    name: 'Arabic (Oman) (ar-OM)',
    country: 'Oman',
    code3: 'OMN',
    flag: 'oman.svg',
  },
  {
    code: 'ar-QA',
    name: 'Arabic (Qatar) (ar-QA)',
    country: 'Qatar',
    code3: 'QAT',
    flag: 'qatar.svg',
  },
  {
    code: 'ar-SA',
    name: 'Arabic (Saudi Arabia) (ar-SA)',
    country: 'Saudi Arabia',
    code3: 'SAU',
    flag: 'saudi-arabia.svg',
  },
  {
    code: 'ar-SD',
    name: 'Arabic (Sudan) (ar-SD)',
    country: 'Sudan',
    code3: 'SDN',
    flag: 'sudan.svg',
  },
  {
    code: 'ar-SY',
    name: 'Arabic (Syria) (ar-SY)',
    country: 'Syria',
    code3: '',
    flag: 'syria.svg',
  },
  {
    code: 'ar-TN',
    name: 'Arabic (Tunisia) (ar-TN)',
    country: 'Tunisia',
    code3: 'TUN',
    flag: 'tunisia.svg',
  },
  {
    code: 'ar-AE',
    name: 'Arabic (United Arab Emirates) (ar-AE)',
    country: 'United Arab Emirates',
    code3: 'ARE',
    flag: 'united-arab-emirates.svg',
  },
  {
    code: 'ar-001',
    name: 'Arabic (World) (ar-001)',
    country: 'World',
    code3: '',
    flag: '',
  },
  {
    code: 'ar-YE',
    name: 'Arabic (Yemen) (ar-YE)',
    country: 'Yemen',
    code3: 'YEM',
    flag: 'yemen.svg',
  },
  {
    code: 'hy',
    name: 'Armenian (hy)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'hy-AM',
    name: 'Armenian (Armenia) (hy-AM)',
    country: 'Armenia',
    code3: 'ARM',
    flag: 'armenia.svg',
  },
  {
    code: 'as',
    name: 'Assamese (as)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'as-IN',
    name: 'Assamese (India) (as-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'asa',
    name: 'Asu (asa)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'asa-TZ',
    name: 'Asu (Tanzania) (asa-TZ)',
    country: 'Tanzania',
    code3: 'TZA',
    flag: 'tanzania.svg',
  },
  {
    code: 'az',
    name: 'Azerbaijani (az)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'az-Cyrl',
    name: 'Azerbaijani (Cyrillic) (az-Cyrl)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'az-Cyrl-AZ',
    name: 'Azerbaijani (Cyrillic, Azerbaijan) (az-C',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'az-Latn',
    name: 'Azerbaijani (Latin) (az-Latn)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'az-Latn-AZ',
    name: 'Azerbaijani (Latin, Azerbaijan) (az-Latn-AZ)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ksf',
    name: 'Bafia (ksf)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ksf-CM',
    name: 'Bafia (Cameroon) (ksf-CM)',
    country: 'Cameroon',
    code3: 'CMR',
    flag: 'cameroon.svg',
  },
  {
    code: 'bm',
    name: 'Bambara (bm)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'bm-ML',
    name: 'Bambara (Mali) (bm-ML)',
    country: 'Mali',
    code3: 'MLI',
    flag: 'mali.svg',
  },
  {
    code: 'bas',
    name: 'Basaa (bas)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'bas-CM',
    name: 'Basaa (Cameroon) (bas-CM)',
    country: 'Cameroon',
    code3: 'CMR',
    flag: 'cameroon.svg',
  },
  {
    code: 'eu',
    name: 'Basque (eu)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'eu-ES',
    name: 'Basque (Spain) (eu-ES)',
    country: 'Spain',
    code3: 'ESP',
    flag: 'spain.svg',
  },
  {
    code: 'be',
    name: 'Belarusian (be)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'be-BY',
    name: 'Belarusian (Belarus) (be-BY)',
    country: 'Belarus',
    code3: 'BLR',
    flag: 'belarus.svg',
  },
  {
    code: 'bem',
    name: 'Bemba (bem)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'bem-ZM',
    name: 'Bemba (Zambia) (bem-ZM)',
    country: 'Zambia',
    code3: 'ZMB',
    flag: 'zambia.svg',
  },
  {
    code: 'bez',
    name: 'Bena (bez)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'bez-TZ',
    name: 'Bena (Tanzania) (bez-TZ)',
    country: 'Tanzania',
    code3: 'TZA',
    flag: 'tanzania.svg',
  },
  {
    code: 'bn',
    name: 'Bengali (bn)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'bn-BD',
    name: 'Bengali (Bangladesh) (bn-BD)',
    country: 'Bangladesh',
    code3: 'BGD',
    flag: 'bangladesh.svg',
  },
  {
    code: 'bn-IN',
    name: 'Bengali (India) (bn-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'brx',
    name: 'Bodo (brx)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'brx-IN',
    name: 'Bodo (India) (brx-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'bs',
    name: 'Bosnian (bs)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'bs-BA',
    name: 'Bosnian (Bosnia and Herzegovina) (bs-BA)',
    country: 'Bosnia & Herzegovina',
    code3: 'BIH',
    flag: 'bosnia-and-herzegovina.svg',
  },
  {
    code: 'br',
    name: 'Breton (br)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'br-FR',
    name: 'Breton (France) (br-FR)',
    country: 'France',
    code3: 'FRA',
    flag: 'france.svg',
  },
  {
    code: 'bg',
    name: 'Bulgarian (bg)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'bg-BG',
    name: 'Bulgarian (Bulgaria) (bg-BG)',
    country: 'Bulgaria',
    code3: 'BGR',
    flag: 'bulgaria.svg',
  },
  {
    code: 'my',
    name: 'Burmese (my)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'my-MM',
    name: 'Burmese (Myanmar [Burma]) (my-MM)',
    country: 'Myanmar (Burma)',
    code3: 'MMR',
    flag: 'myanmar.svg',
  },
  {
    code: 'ca',
    name: 'Catalan (ca)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ca-ES',
    name: 'Catalan (Spain) (ca-ES)',
    country: 'Spain',
    code3: 'ESP',
    flag: 'spain.svg',
  },
  {
    code: 'ckb',
    name: 'Central Kurdish (ckb)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'tzm',
    name: 'Central Morocco Tamazight (tzm)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'tzm-Latn',
    name: 'Central Morocco Tamazight (Latin) (tzm-Latn)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'tzm-Latn-MA',
    name: 'Central Morocco Tamazight (L',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'chr',
    name: 'Cherokee (chr)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'chr-US',
    name: 'Cherokee (United States) (chr-US)',
    country: 'United States',
    code3: 'USA',
    flag: 'united-states-of-america.svg',
  },
  {
    code: 'cgg',
    name: 'Chiga (cgg)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'cgg-UG',
    name: 'Chiga (Uganda) (cgg-UG)',
    country: 'Uganda',
    code3: 'UGA',
    flag: 'uganda.svg',
  },
  {
    code: 'zh',
    name: 'Chinese (zh)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'zh-Hans',
    name: 'Chinese (Simplified) (zh-Hans)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'zh-CN',
    name: 'Chinese (Simplified, China) (zh-CN)',
    country: 'China mainland',
    code3: 'CHN',
    flag: 'china.svg',
  },
  {
    code: 'zh-Hans-CN',
    name: 'Chinese (Simplified, China) (zh-Hans-CN)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'zh-Hans-HK',
    name: 'Chinese (Simplified, Hong Kong SAR China) (zh-H',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'zh-Hans-MO',
    name: 'Chinese (Simplified, Macau SAR China) (zh-H',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'zh-Hans-SG',
    name: 'Chinese (Simplified, Singapore) (zh-Hans-SG)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'zh-Hant',
    name: 'Chinese (Traditional) (zh-Hant)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'zh-Hant-HK',
    name: 'Chinese (Traditional, Hong Kong S',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'zh-Hant-MO',
    name: 'Chinese (Traditional, Macau SAR China) (zh-H',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'zh-Hant-TW',
    name: 'Chinese (Traditional, Taiwan) (zh-Hant-TW)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'swc',
    name: 'Congo Swahili (swc)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'swc-CD',
    name: 'Congo Swahili (Congo - Kinshasa) (swc-CD)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'kw',
    name: 'Cornish (kw)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'kw-GB',
    name: 'Cornish (United Kingdom) (kw-GB)',
    country: 'United Kingdom',
    code3: 'GBR',
    flag: 'united-kingdom.svg',
  },
  {
    code: 'hr',
    name: 'Croatian (hr)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'hr-HR',
    name: 'Croatian (Croatia) (hr-HR)',
    country: 'Croatia',
    code3: 'HRV',
    flag: 'croatia.svg',
  },
  {
    code: 'cs',
    name: 'Czech (cs)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'cs-CZ',
    name: 'Czech (Czech Republic) (cs-CZ)',
    country: 'Czechia',
    code3: 'CZE',
    flag: 'czech-republic.svg',
  },
  {
    code: 'da',
    name: 'Danish (da)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'da-DK',
    name: 'Danish (Denmark) (da-DK)',
    country: 'Denmark',
    code3: 'DNK',
    flag: 'denmark.svg',
  },
  {
    code: 'dua',
    name: 'Duala (dua)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'dua-CM',
    name: 'Duala (Cameroon) (dua-CM)',
    country: 'Cameroon',
    code3: 'CMR',
    flag: 'cameroon.svg',
  },
  {
    code: 'nl',
    name: 'Dutch (nl)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'nl-AW',
    name: 'Dutch (Aruba) (nl-AW)',
    country: 'Aruba',
    code3: 'ABW',
    flag: 'aruba.svg',
  },
  {
    code: 'nl-BE',
    name: 'Dutch (Belgium) (nl-BE)',
    country: 'Belgium',
    code3: 'BEL',
    flag: 'belgium.svg',
  },
  {
    code: 'nl-CW',
    name: 'Dutch (Curaçao) (nl-CW)',
    country: 'Curaçao',
    code3: '',
    flag: '',
  },
  {
    code: 'nl-NL',
    name: 'Dutch (Netherlands) (nl-NL)',
    country: 'Netherlands',
    code3: 'NLD',
    flag: 'netherlands.svg',
  },
  {
    code: 'nl-SX',
    name: 'Dutch (Sint Maarten) (nl-SX)',
    country: 'Sint Maarten',
    code3: '',
    flag: 'sint-maarten.svg',
  },
  {
    code: 'ebu',
    name: 'Embu (ebu)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ebu-KE',
    name: 'Embu (Kenya) (ebu-KE)',
    country: 'Kenya',
    code3: 'KEN',
    flag: 'kenya.svg',
  },
  {
    code: 'en',
    name: 'English (en)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'en-AS',
    name: 'English (American Samoa) (en-AS)',
    country: 'American Samoa',
    code3: 'ASM',
    flag: 'american-samoa.svg',
  },
  {
    code: 'en-AU',
    name: 'English (Australia) (en-AU)',
    country: 'Australia',
    code3: 'AUS',
    flag: 'australia.svg',
  },
  {
    code: 'en-BB',
    name: 'English (Barbados) (en-BB)',
    country: 'Barbados',
    code3: 'BRB',
    flag: 'barbados.svg',
  },
  {
    code: 'en-BE',
    name: 'English (Belgium) (en-BE)',
    country: 'Belgium',
    code3: 'BEL',
    flag: 'belgium.svg',
  },
  {
    code: 'en-BZ',
    name: 'English (Belize) (en-BZ)',
    country: 'Belize',
    code3: 'BLZ',
    flag: 'belize.svg',
  },
  {
    code: 'en-BM',
    name: 'English (Bermuda) (en-BM)',
    country: 'Bermuda',
    code3: 'BMU',
    flag: 'bermuda.svg',
  },
  {
    code: 'en-BW',
    name: 'English (Botswana) (en-BW)',
    country: 'Botswana',
    code3: 'BWA',
    flag: 'botswana.svg',
  },
  {
    code: 'en-CA',
    name: 'English (Canada) (en-CA)',
    country: 'Canada',
    code3: 'CAN',
    flag: 'canada.svg',
  },
  {
    code: 'en-EG',
    name: 'English (Egypt) (en-EG)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'en-EU',
    name: 'English (Europe) (en-EU)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'en-GU',
    name: 'English (Guam) (en-GU)',
    country: 'Guam',
    code3: 'GUM',
    flag: 'gambia.svg',
  },
  {
    code: 'en-GY',
    name: 'English (Guyana) (en-GY)',
    country: 'Guyana',
    code3: 'GUY',
    flag: 'guyana.svg',
  },
  {
    code: 'en-HK',
    name: 'English (Hong Kong SAR China) (en-HK)',
    country: 'Hong Kong',
    code3: 'HKG',
    flag: 'hong-kong.svg',
  },
  {
    code: 'en-IN',
    name: 'English (India) (en-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'en-IE',
    name: 'English (Ireland) (en-IE)',
    country: 'Ireland',
    code3: 'IRL',
    flag: 'ireland.svg',
  },
  {
    code: 'en-JM',
    name: 'English (Jamaica) (en-JM)',
    country: 'Jamaica',
    code3: 'JAM',
    flag: 'jamaica.svg',
  },
  {
    code: 'en-MT',
    name: 'English (Malta) (en-MT)',
    country: 'Malta',
    code3: 'MLT',
    flag: 'malta.svg',
  },
  {
    code: 'en-MH',
    name: 'English (Marshall Islands) (en-MH)',
    country: 'Marshall Islands',
    code3: 'MHL',
    flag: '',
  },
  {
    code: 'en-MU',
    name: 'English (Mauritius) (en-MU)',
    country: 'Mauritius',
    code3: 'MUS',
    flag: 'mauritius.svg',
  },
  {
    code: 'en-NA',
    name: 'English (Namibia) (en-NA)',
    country: 'Namibia',
    code3: 'NAM',
    flag: 'namibia.svg',
  },
  {
    code: 'en-NZ',
    name: 'English (New Zealand) (en-NZ)',
    country: 'New Zealand',
    code3: 'NZL',
    flag: 'new-zealand.svg',
  },
  {
    code: 'en-MP',
    name: 'English (Northern Mariana Islands) (en-MP)',
    country: 'Northern Mariana Islands',
    code3: 'MNP',
    flag: 'northern-marianas-islands.svg',
  },
  {
    code: 'en-PK',
    name: 'English (Pakistan) (en-PK)',
    country: 'Pakistan',
    code3: 'PAK',
    flag: 'pakistan.svg',
  },
  {
    code: 'en-PH',
    name: 'English (Philippines) (en-PH)',
    country: 'Philippines',
    code3: 'PHL',
    flag: 'philippines.svg',
  },
  {
    code: 'en-SA',
    name: 'English (Saudi Arabia) (en-SA)',
    country: 'Saudi Arabia',
    code3: 'SAU',
    flag: 'saudi-arabia.svg',
  },
  {
    code: 'en-SG',
    name: 'English (Singapore) (en-SG)',
    country: 'Singapore',
    code3: 'SGP',
    flag: 'singapore.svg',
  },
  {
    code: 'en-ZA',
    name: 'English (South Africa) (en-ZA)',
    country: 'South Africa',
    code3: 'ZAF',
    flag: 'south-africa.svg',
  },
  {
    code: 'en-TT',
    name: 'English (Trinidad and Tobago) (en-TT)',
    country: 'Trinidad & Tobago',
    code3: 'TTO',
    flag: '',
  },
  {
    code: 'en-AE',
    name: 'English (U.A.E.) (en-AE)',
    country: 'United Arab Emirates',
    code3: 'ARE',
    flag: 'united-arab-emirates.svg',
  },
  {
    code: 'en-UM',
    name: 'English (U.S. Minor Outlying Islands) (en-UM)',
    country: 'U.S. Outlying Islands',
    code3: 'UMI',
    flag: 'united-states-of-america.svg',
  },
  {
    code: 'en-VI',
    name: 'English (U.S. Virgin Islands) (en-VI)',
    country: 'U.S. Virgin Islands',
    code3: 'VIR',
    flag: 'virgin-islands.svg',
  },
  {
    code: 'en-US-POSIX',
    name: 'English (U.S., Computer) (en-US-POSIX)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'en-GB',
    name: 'English (United Kingdom) (en-GB)',
    country: 'United Kingdom',
    code3: 'GBR',
    flag: 'united-kingdom.svg',
  },
  {
    code: 'en-US',
    name: 'English (United States) (en-US)',
    country: 'United States',
    code3: 'USA',
    flag: 'united-states-of-america.svg',
  },
  {
    code: 'en-ZW',
    name: 'English (Zimbabwe) (en-ZW)',
    country: 'Zimbabwe',
    code3: 'ZWE',
    flag: 'zimbabwe.svg',
  },
  {
    code: 'eo',
    name: 'Esperanto (eo)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'et',
    name: 'Estonian (et)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'et-EE',
    name: 'Estonian (Estonia) (et-EE)',
    country: 'Estonia',
    code3: 'EST',
    flag: 'estonia.svg',
  },
  {
    code: 'ee',
    name: 'Ewe (ee)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ee-GH',
    name: 'Ewe (Ghana) (ee-GH)',
    country: 'Ghana',
    code3: 'GHA',
    flag: 'ghana.svg',
  },
  {
    code: 'ee-TG',
    name: 'Ewe (Togo) (ee-TG)',
    country: 'Togo',
    code3: 'TGO',
    flag: 'togo.svg',
  },
  {
    code: 'ewo',
    name: 'Ewondo (ewo)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ewo-CM',
    name: 'Ewondo (Cameroon) (ewo-CM)',
    country: 'Cameroon',
    code3: 'CMR',
    flag: 'cameroon.svg',
  },
  {
    code: 'fo',
    name: 'Faroese (fo)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'fo-FO',
    name: 'Faroese (Faroe Islands) (fo-FO)',
    country: 'Faroe Islands',
    code3: 'FRO',
    flag: 'faroe-islands.svg',
  },
  {
    code: 'fil',
    name: 'Filipino (fil)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'fil-PH',
    name: 'Filipino (Philippines) (fil-PH)',
    country: 'Philippines',
    code3: 'PHL',
    flag: 'philippines.svg',
  },
  {
    code: 'fi',
    name: 'Finnish (fi)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'fi-FI',
    name: 'Finnish (Finland) (fi-FI)',
    country: 'Finland',
    code3: 'FIN',
    flag: 'finland.svg',
  },
  {
    code: 'fr',
    name: 'French (fr)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'fr-BE',
    name: 'French (Belgium) (fr-BE)',
    country: 'Belgium',
    code3: 'BEL',
    flag: 'belgium.svg',
  },
  {
    code: 'fr-BJ',
    name: 'French (Benin) (fr-BJ)',
    country: 'Benin',
    code3: 'BEN',
    flag: 'benin.svg',
  },
  {
    code: 'fr-BF',
    name: 'French (Burkina Faso) (fr-BF)',
    country: 'Burkina Faso',
    code3: 'BFA',
    flag: 'burkina-faso.svg',
  },
  {
    code: 'fr-BI',
    name: 'French (Burundi) (fr-BI)',
    country: 'Burundi',
    code3: 'BDI',
    flag: 'burundi.svg',
  },
  {
    code: 'fr-CM',
    name: 'French (Cameroon) (fr-CM)',
    country: 'Cameroon',
    code3: 'CMR',
    flag: 'cameroon.svg',
  },
  {
    code: 'fr-CA',
    name: 'French (Canada) (fr-CA)',
    country: 'Canada',
    code3: 'CAN',
    flag: 'canada.svg',
  },
  {
    code: 'fr-CF',
    name: 'French (Central African Republic) (fr-CF)',
    country: 'Central African Republic',
    code3: 'CAF',
    flag: 'central-african-republic.svg',
  },
  {
    code: 'fr-TD',
    name: 'French (Chad) (fr-TD)',
    country: 'Chad',
    code3: 'TCD',
    flag: 'chad.svg',
  },
  {
    code: 'fr-KM',
    name: 'French (Comoros) (fr-KM)',
    country: 'Comoros',
    code3: 'COM',
    flag: 'comoros.svg',
  },
  {
    code: 'fr-CG',
    name: 'French (Congo - Brazzaville) (fr-CG)',
    country: 'Congo - Brazzaville',
    code3: 'COG',
    flag: 'republic-of-the-congo.svg',
  },
  {
    code: 'fr-CD',
    name: 'French (Congo - Kinshasa) (fr-CD)',
    country: 'Congo - Kinshasa',
    code3: 'COD',
    flag: 'democratic-republic-of-congo.svg',
  },
  {
    code: 'fr-CI',
    name: 'French (Côte d’Ivoire) (fr-CI)',
    country: 'Côte d’Ivoire',
    code3: 'CIV',
    flag: '',
  },
  {
    code: 'fr-DJ',
    name: 'French (Djibouti) (fr-DJ)',
    country: 'Djibouti',
    code3: 'DJI',
    flag: 'djibouti.svg',
  },
  {
    code: 'fr-GQ',
    name: 'French (Equatorial Guinea) (fr-GQ)',
    country: 'Equatorial Guinea',
    code3: 'GNQ',
    flag: 'equatorial-guinea.svg',
  },
  {
    code: 'fr-FR',
    name: 'French (France) (fr-FR)',
    country: 'France',
    code3: 'FRA',
    flag: 'france.svg',
  },
  {
    code: 'fr-GF',
    name: 'French (French Guiana) (fr-GF)',
    country: 'French Guiana',
    code3: 'GUF',
    flag: '',
  },
  {
    code: 'fr-GA',
    name: 'French (Gabon) (fr-GA)',
    country: 'Gabon',
    code3: 'GAB',
    flag: 'gabon.svg',
  },
  {
    code: 'fr-GP',
    name: 'French (Guadeloupe) (fr-GP)',
    country: 'Guadeloupe',
    code3: 'GLP',
    flag: 'france.svg',
  },
  {
    code: 'fr-GN',
    name: 'French (Guinea) (fr-GN)',
    country: 'Guinea',
    code3: 'GIN',
    flag: 'guinea.svg',
  },
  {
    code: 'fr-LU',
    name: 'French (Luxembourg) (fr-LU)',
    country: 'Luxembourg',
    code3: 'LUX',
    flag: 'luxembourg.svg',
  },
  {
    code: 'fr-MG',
    name: 'French (Madagascar) (fr-MG)',
    country: 'Madagascar',
    code3: 'MDG',
    flag: 'madagascar.svg',
  },
  {
    code: 'fr-ML',
    name: 'French (Mali) (fr-ML)',
    country: 'Mali',
    code3: 'MLI',
    flag: 'mali.svg',
  },
  {
    code: 'fr-MQ',
    name: 'French (Martinique) (fr-MQ)',
    country: 'Martinique',
    code3: 'MTQ',
    flag: 'martinique.svg',
  },
  {
    code: 'fr-YT',
    name: 'French (Mayotte) (fr-YT)',
    country: 'Mayotte',
    code3: 'MYT',
    flag: '',
  },
  {
    code: 'fr-MC',
    name: 'French (Monaco) (fr-MC)',
    country: 'Monaco',
    code3: 'MCO',
    flag: 'monaco.svg',
  },
  {
    code: 'fr-NE',
    name: 'French (Niger) (fr-NE)',
    country: 'Niger',
    code3: 'NER',
    flag: 'niger.svg',
  },
  {
    code: 'fr-RW',
    name: 'French (Rwanda) (fr-RW)',
    country: 'Rwanda',
    code3: 'RWA',
    flag: 'rwanda.svg',
  },
  {
    code: 'fr-RE',
    name: 'French (Réunion) (fr-RE)',
    country: 'Réunion',
    code3: 'REU',
    flag: '',
  },
  {
    code: 'fr-BL',
    name: 'French (Saint Barthélemy) (fr-BL)',
    country: 'St. Barthélemy',
    code3: '',
    flag: '',
  },
  {
    code: 'fr-MF',
    name: 'French (Saint Martin) (fr-MF)',
    country: 'St. Martin',
    code3: '',
    flag: '',
  },
  {
    code: 'fr-SN',
    name: 'French (Senegal) (fr-SN)',
    country: 'Senegal',
    code3: 'SEN',
    flag: 'senegal.svg',
  },
  {
    code: 'fr-CH',
    name: 'French (Switzerland) (fr-CH)',
    country: 'Switzerland',
    code3: 'CHE',
    flag: 'switzerland.svg',
  },
  {
    code: 'fr-TG',
    name: 'French (Togo) (fr-TG)',
    country: 'Togo',
    code3: 'TGO',
    flag: 'togo.svg',
  },
  {
    code: 'ff',
    name: 'Fulah (ff)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ff-SN',
    name: 'Fulah (Senegal) (ff-SN)',
    country: 'Senegal',
    code3: 'SEN',
    flag: 'senegal.svg',
  },
  {
    code: 'gl',
    name: 'Galician (gl)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'gl-ES',
    name: 'Galician (Spain) (gl-ES)',
    country: 'Spain',
    code3: 'ESP',
    flag: 'spain.svg',
  },
  {
    code: 'lg',
    name: 'Ganda (lg)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'lg-UG',
    name: 'Ganda (Uganda) (lg-UG)',
    country: 'Uganda',
    code3: 'UGA',
    flag: 'uganda.svg',
  },
  {
    code: 'ka',
    name: 'Georgian (ka)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ka-GE',
    name: 'Georgian (Georgia) (ka-GE)',
    country: 'Georgia',
    code3: 'GEO',
    flag: 'georgia.svg',
  },
  {
    code: 'de',
    name: 'German (de)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'de-AT',
    name: 'German (Austria) (de-AT)',
    country: 'Austria',
    code3: 'AUT',
    flag: 'austria.svg',
  },
  {
    code: 'de-BE',
    name: 'German (Belgium) (de-BE)',
    country: 'Belgium',
    code3: 'BEL',
    flag: 'belgium.svg',
  },
  {
    code: 'de-DE',
    name: 'German (Germany) (de-DE)',
    country: 'Germany',
    code3: 'DEU',
    flag: 'germany.svg',
  },
  {
    code: 'de-LI',
    name: 'German (Liechtenstein) (de-LI)',
    country: 'Liechtenstein',
    code3: 'LIE',
    flag: 'liechtenstein.svg',
  },
  {
    code: 'de-LU',
    name: 'German (Luxembourg) (de-LU)',
    country: 'Luxembourg',
    code3: 'LUX',
    flag: 'luxembourg.svg',
  },
  {
    code: 'de-CH',
    name: 'German (Switzerland) (de-CH)',
    country: 'Switzerland',
    code3: 'CHE',
    flag: 'switzerland.svg',
  },
  {
    code: 'el',
    name: 'Greek (el)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'el-CY',
    name: 'Greek (Cyprus) (el-CY)',
    country: 'Cyprus',
    code3: 'CYP',
    flag: 'cyprus.svg',
  },
  {
    code: 'el-GR',
    name: 'Greek (Greece) (el-GR)',
    country: 'Greece',
    code3: 'GRC',
    flag: 'greece.svg',
  },
  {
    code: 'gu',
    name: 'Gujarati (gu)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'gu-IN',
    name: 'Gujarati (India) (gu-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'guz',
    name: 'Gusii (guz)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'guz-KE',
    name: 'Gusii (Kenya) (guz-KE)',
    country: 'Kenya',
    code3: 'KEN',
    flag: 'kenya.svg',
  },
  {
    code: 'ha',
    name: 'Hausa (ha)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ha-Latn',
    name: 'Hausa (Latin) (ha-Latn)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ha-Latn-GH',
    name: 'Hausa (Latin, Ghana) (ha-Latn-GH)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ha-Latn-NE',
    name: 'Hausa (Latin, Niger) (ha-Latn-NE)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ha-Latn-NG',
    name: 'Hausa (Latin, Nigeria) (ha-Latn-NG)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'haw',
    name: 'Hawaiian (haw)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'haw-US',
    name: 'Hawaiian (United States) (haw-US)',
    country: 'United States',
    code3: 'USA',
    flag: 'united-states-of-america.svg',
  },
  {
    code: 'he',
    name: 'Hebrew (he)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'he-IL',
    name: 'Hebrew (Israel) (he-IL)',
    country: 'Israel',
    code3: 'ISR',
    flag: 'israel.svg',
  },
  {
    code: 'hi',
    name: 'Hindi (hi)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'hi-IN',
    name: 'Hindi (India) (hi-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'hu',
    name: 'Hungarian (hu)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'hu-HU',
    name: 'Hungarian (Hungary) (hu-HU)',
    country: 'Hungary',
    code3: 'HUN',
    flag: 'hungary.svg',
  },
  {
    code: 'is',
    name: 'Icelandic (is)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'is-IS',
    name: 'Icelandic (Iceland) (is-IS)',
    country: 'Iceland',
    code3: 'ISL',
    flag: 'iceland.svg',
  },
  {
    code: 'ig',
    name: 'Igbo (ig)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ig-NG',
    name: 'Igbo (Nigeria) (ig-NG)',
    country: 'Nigeria',
    code3: 'NGA',
    flag: 'niger.svg',
  },
  {
    code: 'id',
    name: 'Indonesian (id)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'id-ID',
    name: 'Indonesian (Indonesia) (id-ID)',
    country: 'Indonesia',
    code3: 'IDN',
    flag: 'indonesia.svg',
  },
  {
    code: 'ga',
    name: 'Irish (ga)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ga-IE',
    name: 'Irish (Ireland) (ga-IE)',
    country: 'Ireland',
    code3: 'IRL',
    flag: 'ireland.svg',
  },
  {
    code: 'it',
    name: 'Italian (it)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'it-IT',
    name: 'Italian (Italy) (it-IT)',
    country: 'Italy',
    code3: 'ITA',
    flag: 'italy.svg',
  },
  {
    code: 'it-CH',
    name: 'Italian (Switzerland) (it-CH)',
    country: 'Switzerland',
    code3: 'CHE',
    flag: 'switzerland.svg',
  },
  {
    code: 'ja',
    name: 'Japanese (ja)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ja-JP',
    name: 'Japanese (Japan) (ja-JP)',
    country: 'Japan',
    code3: 'JPN',
    flag: 'japan.svg',
  },
  {
    code: 'dyo',
    name: 'Jola-Fonyi (dyo)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'dyo-SN',
    name: 'Jola-Fonyi (Senegal) (dyo-SN)',
    country: 'Senegal',
    code3: 'SEN',
    flag: 'senegal.svg',
  },
  {
    code: 'kea',
    name: 'Kabuverdianu (kea)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'kea-CV',
    name: 'Kabuverdianu (Cape Verde) (kea-CV)',
    country: 'Cape Verde',
    code3: 'CPV',
    flag: 'cape-verde.svg',
  },
  {
    code: 'kab',
    name: 'Kabyle (kab)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'kab-DZ',
    name: 'Kabyle (Algeria) (kab-DZ)',
    country: 'Algeria',
    code3: 'DZA',
    flag: 'algeria.svg',
  },
  {
    code: 'kl',
    name: 'Kalaallisut (kl)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'kl-GL',
    name: 'Kalaallisut (Greenland) (kl-GL)',
    country: 'Greenland',
    code3: 'GRL',
    flag: 'greenland.svg',
  },
  {
    code: 'kln',
    name: 'Kalenjin (kln)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'kln-KE',
    name: 'Kalenjin (Kenya) (kln-KE)',
    country: 'Kenya',
    code3: 'KEN',
    flag: 'kenya.svg',
  },
  {
    code: 'kam',
    name: 'Kamba (kam)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'kam-KE',
    name: 'Kamba (Kenya) (kam-KE)',
    country: 'Kenya',
    code3: 'KEN',
    flag: 'kenya.svg',
  },
  {
    code: 'kn',
    name: 'Kannada (kn)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'kn-IN',
    name: 'Kannada (India) (kn-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'kk',
    name: 'Kazakh (kk)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'kk-Cyrl',
    name: 'Kazakh (Cyrillic) (kk-Cyrl)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'kk-Cyrl-KZ',
    name: 'Kazakh (Cyrillic, Kazakhstan) (kk-Cyrl-KZ)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'km',
    name: 'Khmer (km)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'km-KH',
    name: 'Khmer (Cambodia) (km-KH)',
    country: 'Cambodia',
    code3: 'KHM',
    flag: 'cambodia.svg',
  },
  {
    code: 'ki',
    name: 'Kikuyu (ki)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ki-KE',
    name: 'Kikuyu (Kenya) (ki-KE)',
    country: 'Kenya',
    code3: 'KEN',
    flag: 'kenya.svg',
  },
  {
    code: 'rw',
    name: 'Kinyarwanda (rw)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'rw-RW',
    name: 'Kinyarwanda (Rwanda) (rw-RW)',
    country: 'Rwanda',
    code3: 'RWA',
    flag: 'rwanda.svg',
  },
  {
    code: 'kok',
    name: 'Konkani (kok)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'kok-IN',
    name: 'Konkani (India) (kok-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'ko',
    name: 'Korean (ko)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ko-KR',
    name: 'Korean (South Korea) (ko-KR)',
    country: 'South Korea',
    code3: 'KOR',
    flag: 'south-korea.svg',
  },
  {
    code: 'khq',
    name: 'Koyra Chiini (khq)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'khq-ML',
    name: 'Koyra Chiini (Mali) (khq-ML)',
    country: 'Mali',
    code3: 'MLI',
    flag: 'mali.svg',
  },
  {
    code: 'ses',
    name: 'Koyraboro Senni (ses)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ses-ML',
    name: 'Koyraboro Senni (Mali) (ses-ML)',
    country: 'Mali',
    code3: 'MLI',
    flag: 'mali.svg',
  },
  {
    code: 'nmg',
    name: 'Kwasio (nmg)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'nmg-CM',
    name: 'Kwasio (Cameroon) (nmg-CM)',
    country: 'Cameroon',
    code3: 'CMR',
    flag: 'cameroon.svg',
  },
  {
    code: 'lag',
    name: 'Langi (lag)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'lag-TZ',
    name: 'Langi (Tanzania) (lag-TZ)',
    country: 'Tanzania',
    code3: 'TZA',
    flag: 'tanzania.svg',
  },
  {
    code: 'lv',
    name: 'Latvian (lv)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'lv-LV',
    name: 'Latvian (Latvia) (lv-LV)',
    country: 'Latvia',
    code3: 'LVA',
    flag: 'latvia.svg',
  },
  {
    code: 'ln',
    name: 'Lingala (ln)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ln-CG',
    name: 'Lingala (Congo - Brazzaville) (ln-CG)',
    country: 'Congo - Brazzaville',
    code3: 'COG',
    flag: 'republic-of-the-congo.svg',
  },
  {
    code: 'ln-CD',
    name: 'Lingala (Congo - Kinshasa) (ln-CD)',
    country: 'Congo - Kinshasa',
    code3: 'COD',
    flag: 'democratic-republic-of-congo.svg',
  },
  {
    code: 'lt',
    name: 'Lithuanian (lt)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'lt-LT',
    name: 'Lithuanian (Lithuania) (lt-LT)',
    country: 'Lithuania',
    code3: 'LTU',
    flag: 'lithuania.svg',
  },
  {
    code: 'lu',
    name: 'Luba-Katanga (lu)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'lu-CD',
    name: 'Luba-Katanga (Congo - Kinshasa) (lu-CD)',
    country: 'Congo - Kinshasa',
    code3: 'COD',
    flag: 'democratic-republic-of-congo.svg',
  },
  {
    code: 'luo',
    name: 'Luo (luo)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'luo-KE',
    name: 'Luo (Kenya) (luo-KE)',
    country: 'Kenya',
    code3: 'KEN',
    flag: 'kenya.svg',
  },
  {
    code: 'luy',
    name: 'Luyia (luy)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'luy-KE',
    name: 'Luyia (Kenya) (luy-KE)',
    country: 'Kenya',
    code3: 'KEN',
    flag: 'kenya.svg',
  },
  {
    code: 'mk',
    name: 'Macedonian (mk)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'mk-MK',
    name: 'Macedonian (Macedonia) (mk-MK)',
    country: 'North Macedonia',
    code3: '',
    flag: '',
  },
  {
    code: 'jmc',
    name: 'Machame (jmc)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'jmc-TZ',
    name: 'Machame (Tanzania) (jmc-TZ)',
    country: 'Tanzania',
    code3: 'TZA',
    flag: 'tanzania.svg',
  },
  {
    code: 'mgh',
    name: 'Makhuwa-Meetto (mgh)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'mgh-MZ',
    name: 'Makhuwa-Meetto (Mozambique) (mgh-MZ)',
    country: 'Mozambique',
    code3: 'MOZ',
    flag: 'mozambique.svg',
  },
  {
    code: 'kde',
    name: 'Makonde (kde)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'kde-TZ',
    name: 'Makonde (Tanzania) (kde-TZ)',
    country: 'Tanzania',
    code3: 'TZA',
    flag: 'tanzania.svg',
  },
  {
    code: 'mg',
    name: 'Malagasy (mg)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'mg-MG',
    name: 'Malagasy (Madagascar) (mg-MG)',
    country: 'Madagascar',
    code3: 'MDG',
    flag: 'madagascar.svg',
  },
  {
    code: 'ms',
    name: 'Malay (ms)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ms-BN',
    name: 'Malay (Brunei) (ms-BN)',
    country: 'Brunei',
    code3: 'BRN',
    flag: 'bahrain.svg',
  },
  {
    code: 'ms-MY',
    name: 'Malay (Malaysia) (ms-MY)',
    country: 'Malaysia',
    code3: 'MYS',
    flag: 'malaysia.svg',
  },
  {
    code: 'ml',
    name: 'Malayalam (ml)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ml-IN',
    name: 'Malayalam (India) (ml-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'mt',
    name: 'Maltese (mt)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'mt-MT',
    name: 'Maltese (Malta) (mt-MT)',
    country: 'Malta',
    code3: 'MLT',
    flag: 'malta.svg',
  },
  {
    code: 'gv',
    name: 'Manx (gv)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'gv-GB',
    name: 'Manx (United Kingdom) (gv-GB)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'mr',
    name: 'Marathi (mr)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'mr-IN',
    name: 'Marathi (India) (mr-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'mas',
    name: 'Masai (mas)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'mas-KE',
    name: 'Masai (Kenya) (mas-KE)',
    country: 'Kenya',
    code3: 'KEN',
    flag: 'kenya.svg',
  },
  {
    code: 'mas-TZ',
    name: 'Masai (Tanzania) (mas-TZ)',
    country: 'Tanzania',
    code3: 'TZA',
    flag: 'tanzania.svg',
  },
  {
    code: 'mer',
    name: 'Meru (mer)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'mer-KE',
    name: 'Meru (Kenya) (mer-KE)',
    country: 'Kenya',
    code3: 'KEN',
    flag: 'kenya.svg',
  },
  {
    code: 'mfe',
    name: 'Morisyen (mfe)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'mfe-MU',
    name: 'Morisyen (Mauritius) (mfe-MU)',
    country: 'Mauritius',
    code3: 'MUS',
    flag: 'mauritius.svg',
  },
  {
    code: 'mua',
    name: 'Mundang (mua)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'mua-CM',
    name: 'Mundang (Cameroon) (mua-CM)',
    country: 'Cameroon',
    code3: 'CMR',
    flag: 'cameroon.svg',
  },
  {
    code: 'naq',
    name: 'Nama (naq)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'naq-NA',
    name: 'Nama (Namibia) (naq-NA)',
    country: 'Namibia',
    code3: 'NAM',
    flag: 'namibia.svg',
  },
  {
    code: 'ne',
    name: 'Nepali (ne)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ne-IN',
    name: 'Nepali (India) (ne-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'ne-NP',
    name: 'Nepali (Nepal) (ne-NP)',
    country: 'Nepal',
    code3: 'NPL',
    flag: 'nepal.svg',
  },
  {
    code: 'nd',
    name: 'North Ndebele (nd)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'nd-ZW',
    name: 'North Ndebele (Zimbabwe) (nd-ZW)',
    country: 'Zimbabwe',
    code3: 'ZWE',
    flag: 'zimbabwe.svg',
  },
  {
    code: 'nb',
    name: 'Norwegian Bokmål (nb)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'nb-NO',
    name: 'Norwegian Bokmål (Norway) (nb-NO)',
    country: 'Norway',
    code3: 'NOR',
    flag: 'norway.svg',
  },
  {
    code: 'nn',
    name: 'Norwegian Nynorsk (nn)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'nn-NO',
    name: 'Norwegian Nynorsk (Norway) (nn-NO)',
    country: 'Norway',
    code3: 'NOR',
    flag: 'norway.svg',
  },
  {
    code: 'nus',
    name: 'Nuer (nus)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'nus-SD',
    name: 'Nuer (Sudan) (nus-SD)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'nyn',
    name: 'Nyankole (nyn)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'nyn-UG',
    name: 'Nyankole (Uganda) (nyn-UG)',
    country: 'Uganda',
    code3: 'UGA',
    flag: 'uganda.svg',
  },
  {
    code: 'or',
    name: 'Oriya (or)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'or-IN',
    name: 'Oriya (India) (or-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'om',
    name: 'Oromo (om)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'om-ET',
    name: 'Oromo (Ethiopia) (om-ET)',
    country: 'Ethiopia',
    code3: 'ETH',
    flag: 'ethiopia.svg',
  },
  {
    code: 'om-KE',
    name: 'Oromo (Kenya) (om-KE)',
    country: 'Kenya',
    code3: 'KEN',
    flag: 'kenya.svg',
  },
  {
    code: 'ps',
    name: 'Pashto (ps)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ps-AF',
    name: 'Pashto (Afghanistan) (ps-AF)',
    country: 'Afghanistan',
    code3: 'AFG',
    flag: 'afghanistan.svg',
  },
  {
    code: 'fa',
    name: 'Persian (fa)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'fa-AF',
    name: 'Persian (Afghanistan) (fa-AF)',
    country: 'Afghanistan',
    code3: 'AFG',
    flag: 'afghanistan.svg',
  },
  {
    code: 'fa-IR',
    name: 'Persian (Iran) (fa-IR)',
    country: 'Iran',
    code3: '',
    flag: 'iran.svg',
  },
  {
    code: 'pl',
    name: 'Polish (pl)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'pl-PL',
    name: 'Polish (Poland) (pl-PL)',
    country: 'Poland',
    code3: 'POL',
    flag: 'republic-of-poland.svg',
  },
  {
    code: 'pt',
    name: 'Portuguese (pt)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'pt-AO',
    name: 'Portuguese (Angola) (pt-AO)',
    country: 'Angola',
    code3: 'AGO',
    flag: 'angola.svg',
  },
  {
    code: 'pt-BR',
    name: 'Portuguese (Brazil) (pt-BR)',
    country: 'Brazil',
    code3: 'BRA',
    flag: 'brazil.svg',
  },
  {
    code: 'pt-GW',
    name: 'Portuguese (Guinea-Bissau) (pt-GW)',
    country: 'Guinea-Bissau',
    code3: 'GNB',
    flag: 'guinea-bissau.svg',
  },
  {
    code: 'pt-MZ',
    name: 'Portuguese (Mozambique) (pt-MZ)',
    country: 'Mozambique',
    code3: 'MOZ',
    flag: 'mozambique.svg',
  },
  {
    code: 'pt-PT',
    name: 'Portuguese (Portugal) (pt-PT)',
    country: 'Portugal',
    code3: 'PRT',
    flag: 'portugal.svg',
  },
  {
    code: 'pt-ST',
    name: 'Portuguese (São Tomé and Príncipe) (pt-ST)',
    country: 'São Tomé & Príncipe',
    code3: '',
    flag: '',
  },
  {
    code: 'pa',
    name: 'Punjabi (pa)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'pa-Arab',
    name: 'Punjabi (Arabic) (pa-Arab)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'pa-Arab-PK',
    name: 'Punjabi (Arabic, Pakistan) (pa-Arab-PK)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'pa-Guru',
    name: 'Punjabi (Gurmukhi) (pa-Guru)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'pa-Guru-IN',
    name: 'Punjabi (Gurmukhi, India) (pa-Guru-IN)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ro',
    name: 'Romanian (ro)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ro-MD',
    name: 'Romanian (Moldova) (ro-MD)',
    country: 'Moldova',
    code3: 'MDA',
    flag: 'moldova.svg',
  },
  {
    code: 'ro-RO',
    name: 'Romanian (Romania) (ro-RO)',
    country: 'Romania',
    code3: 'ROU',
    flag: 'romania.svg',
  },
  {
    code: 'rm',
    name: 'Romansh (rm)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'rm-CH',
    name: 'Romansh (Switzerland) (rm-CH)',
    country: 'Switzerland',
    code3: 'CHE',
    flag: 'switzerland.svg',
  },
  {
    code: 'rof',
    name: 'Rombo (rof)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'rof-TZ',
    name: 'Rombo (Tanzania) (rof-TZ)',
    country: 'Tanzania',
    code3: '',
    flag: 'tanzania.svg',
  },
  {
    code: 'rn',
    name: 'Rundi (rn)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'rn-BI',
    name: 'Rundi (Burundi) (rn-BI)',
    country: 'Burundi',
    code3: 'BDI',
    flag: 'burundi.svg',
  },
  {
    code: 'ru',
    name: 'Russian (ru)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ru-MD',
    name: 'Russian (Moldova) (ru-MD)',
    country: 'Moldova',
    code3: 'MDA',
    flag: 'moldova.svg',
  },
  {
    code: 'ru-RU',
    name: 'Russian (Russia) (ru-RU)',
    country: 'Russia',
    code3: 'RUS',
    flag: 'russia.svg',
  },
  {
    code: 'ru-UA',
    name: 'Russian (Ukraine) (ru-UA)',
    country: 'Ukraine',
    code3: 'UKR',
    flag: 'ukraine.svg',
  },
  {
    code: 'rwk',
    name: 'Rwa (rwk)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'rwk-TZ',
    name: 'Rwa (Tanzania) (rwk-TZ)',
    country: 'Tanzania',
    code3: 'TZA',
    flag: 'tanzania.svg',
  },
  {
    code: 'saq',
    name: 'Samburu (saq)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'saq-KE',
    name: 'Samburu (Kenya) (saq-KE)',
    country: 'Kenya',
    code3: 'KEN',
    flag: 'kenya.svg',
  },
  {
    code: 'sg',
    name: 'Sango (sg)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sg-CF',
    name: 'Sango (Central African Republic) (sg-CF)',
    country: 'Central African Republic',
    code3: 'CAF',
    flag: 'central-african-republic.svg',
  },
  {
    code: 'sbp',
    name: 'Sangu (sbp)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sbp-TZ',
    name: 'Sangu (Tanzania) (sbp-TZ)',
    country: 'Tanzania',
    code3: '',
    flag: 'tanzania.svg',
  },
  {
    code: 'seh',
    name: 'Sena (seh)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'seh-MZ',
    name: 'Sena (Mozambique) (seh-MZ)',
    country: 'Mozambique',
    code3: 'MOZ',
    flag: 'mozambique.svg',
  },
  {
    code: 'sr',
    name: 'Serbian (sr)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sr-Cyrl',
    name: 'Serbian (Cyrillic) (sr-Cyrl)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sr-Cyrl-BA',
    name: 'Serbian (Cyrillic, Bosnia a',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sr-Cyrl-ME',
    name: 'Serbian (Cyrillic, Montenegro) (sr-Cyrl-ME)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sr-Cyrl-RS',
    name: 'Serbian (Cyrillic, Serbia) (sr-Cyrl-RS)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sr-Latn',
    name: 'Serbian (Latin) (sr-Latn)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sr-Latn-BA',
    name: 'Serbian (Latin, Bosnia and Herzegovina) (sr-L',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sr-Latn-ME',
    name: 'Serbian (Latin, Montenegro) (sr-Latn-ME)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sr-Latn-RS',
    name: 'Serbian (Latin, Serbia) (sr-Latn-RS)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ksb',
    name: 'Shambala (ksb)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ksb-TZ',
    name: 'Shambala (Tanzania) (ksb-TZ)',
    country: 'Tanzania',
    code3: 'TZA',
    flag: 'tanzania.svg',
  },
  {
    code: 'sn',
    name: 'Shona (sn)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sn-ZW',
    name: 'Shona (Zimbabwe) (sn-ZW)',
    country: 'Zimbabwe',
    code3: 'ZWE',
    flag: 'zimbabwe.svg',
  },
  {
    code: 'ii',
    name: 'Sichuan Yi (ii)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ii-CN',
    name: 'Sichuan Yi (China) (ii-CN)',
    country: 'China mainland',
    code3: 'CHN',
    flag: 'china.svg',
  },
  {
    code: 'si',
    name: 'Sinhala (si)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'si-LK',
    name: 'Sinhala (Sri Lanka) (si-LK)',
    country: 'Sri Lanka',
    code3: 'LKA',
    flag: 'sri-lanka.svg',
  },
  {
    code: 'sk',
    name: 'Slovak (sk)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sk-SK',
    name: 'Slovak (Slovakia) (sk-SK)',
    country: 'Slovakia',
    code3: 'SVK',
    flag: 'slovakia.svg',
  },
  {
    code: 'sl',
    name: 'Slovenian (sl)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sl-SI',
    name: 'Slovenian (Slovenia) (sl-SI)',
    country: 'Slovenia',
    code3: 'SVN',
    flag: 'slovenia.svg',
  },
  {
    code: 'xog',
    name: 'Soga (xog)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'xog-UG',
    name: 'Soga (Uganda) (xog-UG)',
    country: 'Uganda',
    code3: 'UGA',
    flag: 'uganda.svg',
  },
  {
    code: 'so',
    name: 'Somali (so)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'so-DJ',
    name: 'Somali (Djibouti) (so-DJ)',
    country: 'Djibouti',
    code3: 'DJI',
    flag: 'djibouti.svg',
  },
  {
    code: 'so-ET',
    name: 'Somali (Ethiopia) (so-ET)',
    country: 'Ethiopia',
    code3: 'ETH',
    flag: 'ethiopia.svg',
  },
  {
    code: 'so-KE',
    name: 'Somali (Kenya) (so-KE)',
    country: 'Kenya',
    code3: 'KEN',
    flag: 'kenya.svg',
  },
  {
    code: 'so-SO',
    name: 'Somali (Somalia) (so-SO)',
    country: 'Somalia',
    code3: 'SOM',
    flag: 'somalia.svg',
  },
  {
    code: 'es',
    name: 'Spanish (es)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'es-AR',
    name: 'Spanish (Argentina) (es-AR)',
    country: 'Argentina',
    code3: 'ARG',
    flag: 'argentina.svg',
  },
  {
    code: 'es-BO',
    name: 'Spanish (Bolivia) (es-BO)',
    country: 'Bolivia',
    code3: 'BOL',
    flag: 'bolivia.svg',
  },
  {
    code: 'es-CL',
    name: 'Spanish (Chile) (es-CL)',
    country: 'Chile',
    code3: 'CHL',
    flag: 'chile.svg',
  },
  {
    code: 'es-CO',
    name: 'Spanish (Colombia) (es-CO)',
    country: 'Colombia',
    code3: 'COL',
    flag: 'colombia.svg',
  },
  {
    code: 'es-CR',
    name: 'Spanish (Costa Rica) (es-CR)',
    country: 'Costa Rica',
    code3: 'CRI',
    flag: 'costa-rica.svg',
  },
  {
    code: 'es-DO',
    name: 'Spanish (Dominican Republic) (es-DO)',
    country: 'Dominican Republic',
    code3: 'DOM',
    flag: 'dominican-republic.svg',
  },
  {
    code: 'es-EC',
    name: 'Spanish (Ecuador) (es-EC)',
    country: 'Ecuador',
    code3: 'ECU',
    flag: 'ecuador.svg',
  },
  {
    code: 'es-SV',
    name: 'Spanish (El Salvador) (es-SV)',
    country: 'El Salvador',
    code3: 'SLV',
    flag: 'salvador.svg',
  },
  {
    code: 'es-GQ',
    name: 'Spanish (Equatorial Guinea) (es-GQ)',
    country: 'Equatorial Guinea',
    code3: 'GNQ',
    flag: 'equatorial-guinea.svg',
  },
  {
    code: 'es-GT',
    name: 'Spanish (Guatemala) (es-GT)',
    country: 'Guatemala',
    code3: 'GTM',
    flag: 'guatemala.svg',
  },
  {
    code: 'es-HN',
    name: 'Spanish (Honduras) (es-HN)',
    country: 'Honduras',
    code3: 'HND',
    flag: 'honduras.svg',
  },
  {
    code: 'es-419',
    name: 'Spanish (Latin America) (es-419)',
    country: 'Latin America',
    code3: '',
    flag: '',
  },
  {
    code: 'es-MX',
    name: 'Spanish (Mexico) (es-MX)',
    country: 'Mexico',
    code3: 'MEX',
    flag: 'mexico.svg',
  },
  {
    code: 'es-NI',
    name: 'Spanish (Nicaragua) (es-NI)',
    country: 'Nicaragua',
    code3: 'NIC',
    flag: 'nicaragua.svg',
  },
  {
    code: 'es-PA',
    name: 'Spanish (Panama) (es-PA)',
    country: 'Panama',
    code3: 'PAN',
    flag: 'panama.svg',
  },
  {
    code: 'es-PY',
    name: 'Spanish (Paraguay) (es-PY)',
    country: 'Paraguay',
    code3: 'PRY',
    flag: 'paraguay.svg',
  },
  {
    code: 'es-PE',
    name: 'Spanish (Peru) (es-PE)',
    country: 'Peru',
    code3: 'PER',
    flag: 'peru.svg',
  },
  {
    code: 'es-PR',
    name: 'Spanish (Puerto Rico) (es-PR)',
    country: 'Puerto Rico',
    code3: 'PRI',
    flag: 'puerto-rico.svg',
  },
  {
    code: 'es-ES',
    name: 'Spanish (Spain) (es-ES)',
    country: 'Spain',
    code3: 'ESP',
    flag: 'spain.svg',
  },
  {
    code: 'es-US',
    name: 'Spanish (United States) (es-US)',
    country: 'United States',
    code3: 'USA',
    flag: 'united-states-of-america.svg',
  },
  {
    code: 'es-UY',
    name: 'Spanish (Uruguay) (es-UY)',
    country: 'Uruguay',
    code3: 'URY',
    flag: 'uruguay.svg',
  },
  {
    code: 'es-VE',
    name: 'Spanish (Venezuela) (es-VE)',
    country: 'Venezuela',
    code3: 'VEN',
    flag: 'venezuela.svg',
  },
  {
    code: 'sw',
    name: 'Swahili (sw)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sw-KE',
    name: 'Swahili (Kenya) (sw-KE)',
    country: 'Kenya',
    code3: 'KEN',
    flag: 'kenya.svg',
  },
  {
    code: 'sw-TZ',
    name: 'Swahili (Tanzania) (sw-TZ)',
    country: 'Tanzania',
    code3: 'TZA',
    flag: 'tanzania.svg',
  },
  {
    code: 'sv',
    name: 'Swedish (sv)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'sv-FI',
    name: 'Swedish (Finland) (sv-FI)',
    country: 'Finland',
    code3: 'FIN',
    flag: 'finland.svg',
  },
  {
    code: 'sv-SE',
    name: 'Swedish (Sweden) (sv-SE)',
    country: 'Sweden',
    code3: 'SWE',
    flag: 'sweden.svg',
  },
  {
    code: 'gsw',
    name: 'Swiss German (gsw)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'gsw-CH',
    name: 'Swiss German (Switzerland) (gsw-CH)',
    country: 'Switzerland',
    code3: 'CHE',
    flag: 'switzerland.svg',
  },
  {
    code: 'shi',
    name: 'Tachelhit (shi)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'shi-Latn',
    name: 'Tachelhit (Latin) (shi-Latn)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'shi-Latn-MA',
    name: 'Tachelhit (Latin, Morocco) (shi-Latn-MA)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'shi-Tfng',
    name: 'Tachelhit (Tifinagh) (shi-Tfng)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'shi-Tfng-MA',
    name: 'Tachelhit (Tifinagh, Morocco) (shi-Tfng-MA)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'dav',
    name: 'Taita (dav)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'dav-KE',
    name: 'Taita (Kenya) (dav-KE)',
    country: 'Kenya',
    code3: 'KEN',
    flag: 'kenya.svg',
  },
  {
    code: 'ta',
    name: 'Tamil (ta)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ta-IN',
    name: 'Tamil (India) (ta-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'ta-LK',
    name: 'Tamil (Sri Lanka) (ta-LK)',
    country: 'Sri Lanka',
    code3: 'LKA',
    flag: 'sri-lanka.svg',
  },
  {
    code: 'twq',
    name: 'Tasawaq (twq)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'twq-NE',
    name: 'Tasawaq (Niger) (twq-NE)',
    country: 'Niger',
    code3: 'NER',
    flag: 'niger.svg',
  },
  {
    code: 'te',
    name: 'Telugu (te)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'te-IN',
    name: 'Telugu (India) (te-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'teo',
    name: 'Teso (teo)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'teo-KE',
    name: 'Teso (Kenya) (teo-KE)',
    country: 'Kenya',
    code3: 'KEN',
    flag: 'kenya.svg',
  },
  {
    code: 'teo-UG',
    name: 'Teso (Uganda) (teo-UG)',
    country: 'Uganda',
    code3: 'UGA',
    flag: 'uganda.svg',
  },
  {
    code: 'th',
    name: 'Thai (th)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'th-TH',
    name: 'Thai (Thailand) (th-TH)',
    country: 'Thailand',
    code3: 'THA',
    flag: 'thailand.svg',
  },
  {
    code: 'bo',
    name: 'Tibetan (bo)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'bo-CN',
    name: 'Tibetan (China) (bo-CN)',
    country: 'China mainland',
    code3: 'CHN',
    flag: 'china.svg',
  },
  {
    code: 'bo-IN',
    name: 'Tibetan (India) (bo-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'ti',
    name: 'Tigrinya (ti)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ti-ER',
    name: 'Tigrinya (Eritrea) (ti-ER)',
    country: 'Eritrea',
    code3: 'ERI',
    flag: 'eritrea.svg',
  },
  {
    code: 'ti-ET',
    name: 'Tigrinya (Ethiopia) (ti-ET)',
    country: 'Ethiopia',
    code3: 'ETH',
    flag: 'ethiopia.svg',
  },
  {
    code: 'to',
    name: 'Tongan (to)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'to-TO',
    name: 'Tongan (Tonga) (to-TO)',
    country: 'Tonga',
    code3: 'TON',
    flag: 'tonga.svg',
  },
  {
    code: 'tr',
    name: 'Turkish (tr)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'tr-TR',
    name: 'Turkish (Turkey) (tr-TR)',
    country: 'Turkey',
    code3: 'TUR',
    flag: 'turkey.svg',
  },
  {
    code: 'uk',
    name: 'Ukrainian (uk)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'uk-UA',
    name: 'Ukrainian (Ukraine) (uk-UA)',
    country: 'Ukraine',
    code3: 'UKR',
    flag: 'ukraine.svg',
  },
  {
    code: 'ur',
    name: 'Urdu (ur)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'ur-IN',
    name: 'Urdu (India) (ur-IN)',
    country: 'India',
    code3: 'IND',
    flag: 'india.svg',
  },
  {
    code: 'ur-PK',
    name: 'Urdu (Pakistan) (ur-PK)',
    country: 'Pakistan',
    code3: 'PAK',
    flag: 'pakistan.svg',
  },
  {
    code: 'uz',
    name: 'Uzbek (uz)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'uz-Arab',
    name: 'Uzbek (Arabic) (uz-Arab)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'uz-Arab-AF',
    name: 'Uzbek (Arabic, Afghanistan) (uz-Arab-AF)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'uz-Cyrl',
    name: 'Uzbek (Cyrillic) (uz-Cyrl)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'uz-Cyrl-UZ',
    name: 'Uzbek (Cyrillic, Uzbekistan) (uz-Cyrl-UZ)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'uz-Latn',
    name: 'Uzbek (Latin) (uz-Latn)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'uz-Latn-UZ',
    name: 'Uzbek (Latin, Uzbekistan) (uz-Latn-UZ)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'vai',
    name: 'Vai (vai)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'vai-Latn',
    name: 'Vai (Latin) (vai-Latn)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'vai-Latn-LR',
    name: 'Vai (Latin, Liberia) (vai-Latn-LR)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'vai-Vaii',
    name: 'Vai (Vai) (vai-Vaii)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'vai-Vaii-LR',
    name: 'Vai (Vai, Liberia) (vai-Vaii-LR)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'vi',
    name: 'Vietnamese (vi)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'vi-VN',
    name: 'Vietnamese (Vietnam) (vi-VN)',
    country: 'Vietnam',
    code3: 'VNM',
    flag: 'vietnam.svg',
  },
  {
    code: 'vun',
    name: 'Vunjo (vun)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'vun-TZ',
    name: 'Vunjo (Tanzania) (vun-TZ)',
    country: 'Tanzania',
    code3: 'TZA',
    flag: 'tanzania.svg',
  },
  {
    code: 'cy',
    name: 'Welsh (cy)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'cy-GB',
    name: 'Welsh (United Kingdom) (cy-GB)',
    country: 'United Kingdom',
    code3: 'GBR',
    flag: 'united-kingdom.svg',
  },
  {
    code: 'yav',
    name: 'Yangben (yav)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'yav-CM',
    name: 'Yangben (Cameroon) (yav-CM)',
    country: 'Cameroon',
    code3: 'CMR',
    flag: 'cameroon.svg',
  },
  {
    code: 'yo',
    name: 'Yoruba (yo)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'yo-NG',
    name: 'Yoruba (Nigeria) (yo-NG)',
    country: 'Nigeria',
    code3: 'NGA',
    flag: 'niger.svg',
  },
  {
    code: 'dje',
    name: 'Zarma (dje)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'dje-NE',
    name: 'Zarma (Niger) (dje-NE)',
    country: 'Niger',
    code3: 'NER',
    flag: 'niger.svg',
  },
  {
    code: 'zu',
    name: 'Zulu (zu)',
    country: '',
    code3: '',
    flag: '',
  },
  {
    code: 'zu-ZA',
    name: 'Zulu (South Africa) (zu-ZA)',
    country: 'South Africa',
    code3: 'ZAF',
    flag: 'south-africa.svg',
  },
];

export const getLangCodeInfo = (code: string) => {
  const data = flagMap.find((flag) => {
    return flag.code === code;
  });

  if (data) {
    return data;
  }
  return {};
};

export const getFlagImage = (image: string) => {
  if (image == '' || image == null) {
    return globe;
  }
  return allFlags[image] ? allFlags[image] : globe;
};

export const getServiceImage = (service: string) => {
  if (service == OPTION_ACCLARO) {
    return acclaro;
  }
  if (service == OPTION_LOCAL) {
    return exportImport;
  }
};
