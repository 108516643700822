import React from 'react';
import './style.scss';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import {
  getPerStatusProjectInfo,
  getStatusColor,
  STATUS_PENDING,
} from '../../../config/translationModel';
import { EntryContentStore } from '../../../../../lambda/src/utils/data-manager/data.manager';

interface Props {
  sdk: EditorExtensionSDK;
  entryContentStore: EntryContentStore;
  language: string;
}

interface State {
  translationInfo: any;
}

export default class ProgressBar extends React.Component<Props, State> {
  state: State = {
    translationInfo: null,
  };
  constructor(props: Readonly<Props>) {
    super(props);
    this.state.translationInfo = this.props.sdk.entry.fields.translationInfo.getValue();
  }

  async componentDidMount() {
    this.props.sdk.entry.fields['translationInfo'].onValueChanged(this.updateTranslationInfo);
  }

  updateTranslationInfo = () => {
    const translationInfo = this.props.sdk.entry.fields.translationInfo.getValue();
    if (translationInfo) {
      this.setState({ translationInfo });
    }
  };

  render() {
    if (!this.state.translationInfo) {
      return '';
    }
    let progressBar = [];
    let totalPercentage = 0;
    let perStatusInfo = getPerStatusProjectInfo(
      this.state.translationInfo,
      this.props.entryContentStore,
      this.props.language,
    );
    for (let status in perStatusInfo) {
      let statusPercent = perStatusInfo[status]['percentage'];
      if (statusPercent > 0) {
        let color = perStatusInfo[status]['color'];
        totalPercentage += statusPercent;
        progressBar.push(
          <div
            className="bar"
            key={status}
            style={{ width: statusPercent + '%', background: color }}
          >
            <span className="tooltiptext">{statusPercent}%</span>
          </div>,
        );
      }
    }
    if (totalPercentage > 0) {
      const incomplete = 100 - totalPercentage;

      if (incomplete > 0) {
        progressBar.push(
          <div
            className="bar"
            key="incomplete"
            style={{ width: incomplete + '%', background: getStatusColor(STATUS_PENDING) }}
          >
            <span className="tooltiptext">{incomplete}%</span>
          </div>,
        );
      }
    } else {
      return '';
    }
    return (
      <>
        <span className={'language-completion'}>{totalPercentage}% complete</span>
        <div className="bars">{progressBar}</div>
      </>
    );
  }
}
