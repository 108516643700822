import React from 'react';
import { SidebarExtensionSDK } from '../extensions-sdk';
import TranslationSidebar from '../components/translation/TranslationSidebar';
import TranslatorSidebar from '../components/translator/TranslatorSidebar';

interface Props {
  sdk: SidebarExtensionSDK;
}

interface State {
  isTranslationContentEntry: boolean | null;
}

export default class Sidebar extends React.Component<Props, State> {
  state: State = {
    isTranslationContentEntry: null,
  };

  async componentDidMount() {
    await this.handleMount();
  }

  handleMount = async () => {
    this.props.sdk.window.startAutoResizer();
    let isTranslationContentEntry =
      this.props.sdk.entry?.getSys().contentType.sys.id ===
      //@ts-ignore
      this.props.sdk.parameters.installation.translationsModelId;
    this.setState({
      isTranslationContentEntry,
    });
  };

  render() {
    const isTranslationContentEntry = this.state.isTranslationContentEntry;
    return (
      <div>
        {isTranslationContentEntry && <TranslationSidebar sdk={this.props.sdk} />}
        {isTranslationContentEntry === false && <TranslatorSidebar sdk={this.props.sdk} />}
      </div>
    );
  }
}
