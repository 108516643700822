import React from 'react';
import { PageExtensionSDK } from '../../../extensions-sdk';
import InsightBox from './InsightBox';
import loGet from 'lodash.get';
import { getAllEntries, getContentTypes } from '../../../utils/helpers';
import { getAllAssets, isMediaAsset } from '../../../utils/assetHelpers';

interface Props {
  sdk: PageExtensionSDK;
  divider: boolean;
}

interface State {
  coverage: number;
  loading: boolean;
}

export default class EntriesTranslated extends React.Component<Props, State> {
  state: State = {
    coverage: 0,
    loading: true,
  };

  async componentDidMount() {
    const { sdk } = this.props;
    //@ts-ignore
    const translationProjectContentType = sdk.parameters.installation.translationsModelId;
    //@ts-ignore
    const translatorContentType = sdk.parameters.installation.translatorModelId;
    const locales = sdk.locales.available.filter((lang) => {
      return lang != sdk.locales.default;
    });
    const contentTypes: any[] = await getContentTypes(sdk);
    let totalTranslatableFields = 0;
    let totalFieldsWithData = 0;
    for (let contentType of contentTypes) {
      let localizedFields = contentType.fields.filter((field: { localized: number }) => {
        return field.localized;
      });
      if (
        [translationProjectContentType, translatorContentType].includes(contentType.sys.id) ||
        localizedFields.length < 1
      ) {
        continue;
      }

      let entryData: any;
			const contentTypeSysId = contentType.sys.id;
			if (isMediaAsset(contentTypeSysId)) {
				entryData = await getAllAssets(sdk);
			} else {
				const subQuery = {
					content_type: contentTypeSysId
				};
				entryData = await getAllEntries(sdk, 0, subQuery);
			}

			for (let entry of entryData) {
				for (let field of localizedFields) {
					for (let locale of locales) {
						let fieldData = loGet(entry, `fields.${field.id}.${locale}`);
						if (fieldData != undefined && fieldData != '') {
							totalFieldsWithData += 1;
						}
						totalTranslatableFields += 1;
					}
				}
			}
    }
    let coverage = Math.round((totalFieldsWithData / totalTranslatableFields) * 100);
    this.setState({
      coverage,
      loading: false,
    });
  }

  render() {
    return (
      <InsightBox
        stat={(Number.isNaN(this.state.coverage) ? 0 : this.state.coverage) + '%'}
        text={'Total Coverage'}
        divider={this.props.divider}
        loading={this.state.loading}
      />
    );
  }
}
