import React from 'react';
import './style.scss';
import { Activity } from '../../../../../lambda/src/utils/ActivityManager';
import loOrderBy from 'lodash.orderby';
import { dateTimeFormat } from '../../../../../lambda/src/utils/helpers';
interface Props {
  items: Activity[];
  locale: string;
}

export default class Timeline extends React.Component<Props> {
  render() {
    const items = loOrderBy(this.props.items, 'time', 'desc');

    return (
      <div className="time-line-ctnr">
        {items.map((item: Activity, index: number) => (
          <ul className="time-line" key={index}>
            <li>
              <i className={'fa ' + (index == 0 ? 'first' : '')} />
              <div className="time-line-item">
                <div className="time-line-header">{item.activity}</div>
                {item.time && (
                  <div className="time-line-time">
                    {dateTimeFormat(item.time, this.props.locale)}
                  </div>
                )}
              </div>
            </li>
          </ul>
        ))}
      </div>
    );
  }
}
