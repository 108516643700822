export default class SessionStore {
  validity = 1800000; // 30 minutes

  constructor(validity: number | null = null) {
    this.validity = validity || this.validity;
  }

  set(key: string, object: any) {
    const time = new Date().getTime();
    const value = JSON.stringify({
      time,
      object,
    });
    sessionStorage.setItem(key, value);
  }

  get(key: string) {
    let data: any = sessionStorage.getItem(key);
    if (data) {
      data = JSON.parse(data);
      if (new Date().getTime() - data.time > this.validity) {
        return null;
      } else {
        return data.object;
      }
    }
    return null;
  }
}

export const sessionStore = new SessionStore();
