import Field from "./field";

export default class LinkField extends Field {
  /**
   * Not handling links so just returning null
   */
  out() {
    return "";
  }

  async in() {
    return this.config.source;
  }
}
