import React from 'react';
import { EditorExtensionSDK } from '../../extensions-sdk';
//@ts-ignore
import ad from './ad.svg';
import { TextLink } from '@contentful/f36-components';
import { hasAcclaroTranslator } from '../../utils/contentful';

interface Props {
  sdk: EditorExtensionSDK;
}

interface State {
  showAd: boolean;
}

export default class Ad extends React.Component<Props, State> {
  state: State = {
    showAd: false,
  };

  componentDidMount = async () => {
    const hasActiveAcclaroTranslator: boolean = await hasAcclaroTranslator(this.props.sdk);
    if (!hasActiveAcclaroTranslator) {
      this.setState({
        showAd: true,
      });
    }
  };

  render() {
    if (!this.state.showAd) {
      return '';
    }
    return (
      <div className={'acclaro-ad'}>
        <TextLink href={'https://bit.ly/31TVmLj'} target={'_blank'}>
          <img src={ad} title="Acclaro" alt="Acclaro" />
        </TextLink>
      </div>
    );
  }
}
