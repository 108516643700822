import React from 'react';
import { getTotalWords, File } from '../../../config/translationModel';

interface Props {
  fileData: File[];
}

export default class WordCount extends React.Component<Props> {
  render() {
    if (!this.props.fileData.length) {
      return '';
    }

    return (
      <>
        <div className={'translation-sidebar-info'} style={{ textAlign: 'right' }}>
          {getTotalWords(this.props.fileData)} total words
        </div>
      </>
    );
  }
}
