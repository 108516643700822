import Field from "./field";

export default class DateField extends Field {
  /**
   * Assuming dates can be treated as a single word
   */
  getWordCount(): Number {
    return 1;
  }
}
